import animationData from 'lotties/threeDotsLoader.json';
import moment from 'moment';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import history from '../../../../history';

export const ALL_FILTER = 'ALL';

export const getAirmeetFilterLabel = (status) => {
    switch (status) {
        case ALL_FILTER:
            return 'all';
        case AIRMEET_STATUS.CREATED:
            return 'upcoming';
        case AIRMEET_STATUS.ONGOING:
            return 'ongoing';
        case AIRMEET_STATUS.PAUSED:
            return 'paused';
        case AIRMEET_STATUS.FINISHED:
            return 'completed';
        default:
            return 'upcoming';
    }
};

export const getDateStr = ({
    start_time = '',
    end_time = '',
    timezone = null,
    getZoneDetails = null,
    format = '',
    appendTzToStartDate = false,
}) => {
    let dateStr = '';
    const startTime =
        start_time &&
        (timezone ? moment(start_time).tz(timezone) : moment(start_time));
    const endTime =
        end_time &&
        (timezone ? moment(end_time).tz(timezone) : moment(end_time));
    let timeZone = null;
    if (getZoneDetails && timezone) {
        timeZone = getZoneDetails(timezone, start_time);
    }
    if (startTime && startTime.isValid() && start_time !== '') {
        dateStr = format ? startTime.format(format) : startTime;
        if (appendTzToStartDate && timeZone && timeZone?.zoneName) {
            dateStr += ` ${timeZone?.zoneName}`;
        }
        if (end_time) {
            const endDateStr = endTime.format(format);
            if (
                endTime.isValid() &&
                startTime.format(format) !== endTime.format(format)
            ) {
                dateStr += ` - ${endDateStr}`;
            }
        }
        if (timeZone && timeZone?.zoneName) {
            dateStr += ` ${timeZone?.zoneName}`;
        }
    } else {
        if (end_time && endTime && endTime.isValid()) {
            dateStr = format ? endTime.format(format) : endTime;
        }
    }
    return dateStr;
};

export const newViewDetails = (
    airmeetId,
    isEventSeries = false,
    isSeriesEventListing
) => {
    if (isSeriesEventListing) {
        window.open(
            `${window.location.origin}/airmeets/${airmeetId}/summary`,
            '_blank'
        );
    } else {
        history.push(
            `${
                isEventSeries ? '/event-series/' : '/airmeets/'
            }${airmeetId}/summary`
        );
    }
};

export const loaderDefaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
    },
};
