import Box from 'atoms/Box';
import Btn from 'atoms/Button/btn';
import Drawer from 'atoms/Drawer/drawer';
import SessionVideoPanel from 'components/community/editPublicAirmeet/Sessions/SessionForm/SessionActivity/SessionVideos/SessionVideoPanel';
import useSelectFromVideos from 'components/community/mediaLibrary/hooks/useSelectFromVideos';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { MODAL_TYPE_ADD_VIDEO } from 'components/modals';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import { getVideoDuration } from 'hooks/community/usePreRecordedSessionVideoUploader';
import useToasts from 'hooks/useToasts';
import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchAirmeetInfo } from 'store/actions/cmDashboard/airmeet';
import { updateSession } from 'store/actions/cmDashboard/session';
import { closeFixedModal, showFixedModal } from 'store/actions/layout';
import styled from 'styled-components';
import { TOAST_VERSION_2 } from 'utils/constants/toasts';
import { cdnImage } from 'utils/core';
import { logger } from 'utils/logger';
import { validateFormData } from '../validations';

const PreRecordVideoField = ({
    communityId,
    setFormData,
    setErrors,
    formData,
    airmeet,
    isSummaryPage = false,
    sessionVideos,
    airmeetFormat,
    videoChangeDisabled,
}) => {
    const { errorToast } = useToasts();
    const dispatch = useDispatch();
    const { openSelectFromVideosModal } = useSelectFromVideos({
        hideOverlay: false,
    });

    const [videoUploadDrawerOpen, setVideoUploadDrawerOpen] = useState(false);
    const { thumbnail } =
        Array.isArray(sessionVideos) && sessionVideos.length > 0
            ? sessionVideos?.[0]
            : {};
    const mapVideoToSession = useCallback(
        async ({
            videoInfo = Object.create(null),
            clear = Boolean,
            duration = Number,
        }) => {
            if (!clear && !videoInfo?.url) {
                return;
            }
            const updatedFormData = {
                ...formData,
                sessionMeta: {
                    ...formData.sessionMeta,
                    videos: clear ? [] : [{ ...videoInfo, duration }],
                },
                duration: clear ? '' : duration,
            };
            setFormData(updatedFormData);
            await validateFormData(
                updatedFormData,
                {
                    abortEarly: false,
                    context: { timeZone: updatedFormData.timeZone },
                },
                setErrors && setErrors
            );
            if (isSummaryPage) {
                try {
                    const response = await dispatch(
                        updateSession(formData.airmeetId, formData.uid, {
                            ...updatedFormData,
                            duration,
                        })
                    );
                    if (!response.error) {
                        dispatch(fetchAirmeetInfo(formData.airmeetId));
                    } else {
                        errorToast({
                            title:
                                response?.payload?.json?.message ||
                                'Failed to add video',
                            version: TOAST_VERSION_2,
                        });
                    }
                } catch (err) {
                    logger.error(err);
                }
            }
        },
        [setFormData, formData, setErrors, isSummaryPage]
    );

    const handleSelectFromVideosClick = () => {
        openSelectFromVideosModal({
            onAddMediaSuccess: async ({ selectedItem }) => {
                const duration = await getVideoDuration(selectedItem.url, true);
                await mapVideoToSession({
                    videoInfo: {
                        ...selectedItem,
                        duration,
                    },
                    clear: false,
                    duration,
                });
                dispatch(closeFixedModal(MODAL_TYPE_ADD_VIDEO));
            },
        });
    };

    const ChangeVideo = async () => {
        dispatch(
            showFixedModal(MODAL_TYPE_ADD_VIDEO, {
                communityId,
                mapVideoToSession,
                airmeetId: airmeet?.airmeetId,
                formData,
                isSummaryPage,
                onClose: () => {
                    dispatch(closeFixedModal(MODAL_TYPE_ADD_VIDEO));
                },
                handleSelectFromVideosClick,
            })
        );
    };

    return (
        <>
            {Array.isArray(sessionVideos) &&
            sessionVideos.length > 0 &&
            sessionVideos[0]?.url ? (
                <Box
                    display='flex'
                    alignItems='flex-end'
                    mt='6px'
                    mb={isSummaryPage ? '0px' : '23px'}
                    maxWidth={'360px'}
                >
                    <VideoPreviewThumb>
                        <img
                            src={
                                thumbnail ||
                                cdnImage(
                                    '/images/dashboard/default-video-thumbnail.png'
                                )
                            }
                            alt={'video preview'}
                        />
                    </VideoPreviewThumb>
                    {isSummaryPage ? (
                        <>
                            <Box p={'10px'}>
                                <Typography
                                    variant='caption'
                                    style={{ fontSize: '12px' }}
                                    color='text.default.primary'
                                >
                                    {sessionVideos[0].title}.mp4
                                </Typography>
                            </Box>
                            <RemoveBox isSummaryPage={isSummaryPage} ml='x5'>
                                <StyledBtn
                                    isSummaryPage={isSummaryPage}
                                    variant='link'
                                    onClick={ChangeVideo}
                                    type={'button'}
                                    disabled={videoChangeDisabled}
                                >
                                    Change
                                </StyledBtn>
                            </RemoveBox>
                        </>
                    ) : (
                        <FlexDiv
                            flexDirection='column'
                            justifyContent='space-between'
                            alignItems='flex-start'
                            pl='x3'
                        >
                            <Text
                                variant='body2'
                                color='text.default.primary'
                                pb='x1'
                            >
                                {sessionVideos[0].title}.mp4
                            </Text>
                            <Btn
                                variant='link'
                                onClick={ChangeVideo}
                                type='button'
                                disabled={videoChangeDisabled}
                            >
                                Change
                            </Btn>
                        </FlexDiv>
                    )}
                </Box>
            ) : (
                <FlexDiv alignItems='center' minHeight='40px'>
                    <Btn
                        variant='link'
                        onClick={() => setVideoUploadDrawerOpen('uploadVideo')}
                        type='button'
                    >
                        <StyledSvgSprite
                            icon='arrow-upload-up'
                            height='1.25rem'
                            width='1.25rem'
                            mr='x1'
                        />
                        Upload video
                    </Btn>
                    <Btn
                        variant='link'
                        type='button'
                        ml='x4'
                        onClick={handleSelectFromVideosClick}
                    >
                        <StyledSvgSprite
                            icon='add'
                            height='1.25rem'
                            width='1.25rem'
                            mr='x1'
                        />
                        Select from videos
                    </Btn>
                </FlexDiv>
            )}
            {videoUploadDrawerOpen && (
                <Drawer
                    showHeader={false}
                    isThemeV2={true}
                    onClose={() => setVideoUploadDrawerOpen(false)}
                    wrapperClosable={true}
                >
                    <FlexDiv
                        flexDirection='column'
                        alignItems='stretch'
                        flex='1'
                        position='relative'
                        maxWidth='100%'
                    >
                        <SessionVideoPanel
                            screenType={videoUploadDrawerOpen}
                            onClose={() => setVideoUploadDrawerOpen(false)}
                            communityId={communityId}
                            airmeetId={airmeet.airmeetId}
                            mapVideoToSession={mapVideoToSession}
                            sessionObjDetails={formData}
                            isSummaryPage={isSummaryPage}
                            isNOPreviewUpload={true}
                            airmeetFormat={airmeetFormat}
                            formData={formData}
                        />
                    </FlexDiv>
                </Drawer>
            )}
        </>
    );
};
export default PreRecordVideoField;

const VideoPreviewThumb = styled.div`
    width: 4.5rem;
    height: 2.5rem;
    border-radius: ${({ theme }) => theme.radii.default};
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
const StyledBtn = styled(Btn)`
    ${({ isSummaryPage }) => isSummaryPage && `text-decoration:none;`}
`;
const RemoveBox = styled(Box)`
    ${({ isSummaryPage }) =>
        isSummaryPage &&
        `  right: 30px;
    position: absolute;
    `}
`;
