import {
    FlexDiv,
    UnstyledButton,
} from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text/';
import React from 'react';
import styled from 'styled-components';
import { border, color } from 'styled-system';
import { onKeyPress } from 'utils/accessibilityOnKeyPress';

const Tag = ({
    size,
    value,
    multiselect,
    isSelected,
    disabled,
    onClick,
    onTagClick,
    children,
    bg = 'grey.fill2',
    showIcon = true,
    icon = '',
    ariaLabel,
    showHoverState = false,
    role,
    isThemeV2 = false,
    className = '',
    ...rest
}) => {
    return (
        <TagItem
            className={`TagItem ${
                disabled ? 'disabled' : isSelected ? 'selected' : ''
            } ${className}`}
            size={size}
            bg={bg}
            onClick={onTagClick}
            isThemeV2={isThemeV2}
            cursor={onTagClick ? 'pointer' : 'default'}
            onKeyPress={(e) =>
                onKeyPress(e, onTagClick ? onTagClick : onClick, {
                    customKeys: ['Enter', ' '],
                })
            }
            tabIndex={onTagClick ? 0 : -1}
            hasValue={!!value}
            aria-label={ariaLabel}
            showHoverState={showHoverState}
            role={role || (onTagClick ? 'button' : '')}
            {...rest}
        >
            {value ? (
                <Text
                    color={`text.default.${
                        disabled && isSelected
                            ? 'secondary'
                            : disabled
                            ? 'disabled'
                            : isSelected
                            ? 'primary'
                            : 'secondary'
                    }`}
                    variant={
                        size === 'mini'
                            ? 'caption10-bold'
                            : size === 'small'
                            ? isSelected
                                ? 'caption-bold'
                                : 'caption'
                            : isSelected
                            ? 'h5p'
                            : 'body2'
                    }
                    flex='1'
                    aria-hidden={multiselect}
                >
                    {value}
                </Text>
            ) : (
                children
            )}
            {(icon || (showIcon && (multiselect || isSelected))) && (
                <CancelButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onClick && onClick(e);
                    }}
                    onKeyPress={onKeyPress}
                    aria-label={`${isSelected ? 'Dismiss' : 'Add'} ${value}`}
                    tabIndex={!onTagClick && onClick ? 0 : -1}
                    aria-hidden={!onTagClick && onClick ? 'false' : 'true'}
                >
                    <StyledIcon
                        width={size === 'small' ? 12 : 15}
                        height={size === 'small' ? 12 : 15}
                        size={size}
                        icon={icon || (isSelected ? 'dismiss' : 'add')}
                    />
                </CancelButton>
            )}
        </TagItem>
    );
};

export default Tag;

const TagItem = styled(FlexDiv)`
    background-color: ${({ theme }) => theme.colors.grey.fill2};
    border: solid 1px ${({ theme }) => theme.colors.border.default1};
    padding: ${({ theme, size }) =>
        size === 'mini'
            ? theme.space.x1
            : size === 'small'
            ? `${theme.space.x1} ${theme.space.x3}`
            : size === 'small'
            ? `${theme.space.x1} ${theme.space.x3}`
            : `0.375rem ${theme.space.x3}`};
    border-radius: ${({ theme, isThemeV2 }) =>
        isThemeV2 ? '1rem' : theme.radii.default};
    align-items: center;

    &.selected {
        background-color: ${({ theme }) => theme.colors.fill.default1};
        border: solid 1px ${({ theme }) => theme.colors.border.other1};
        p {
            color: ${({ theme }) => theme.colors.text.default.primary};
        }
        svg {
            fill: ${({ theme }) => theme.colors.text.default.primary};
        }
    }

    ${({ showHoverState, theme }) =>
        showHoverState &&
        `
        &:hover {
            background-color: ${theme.colors.fill.default1};
            border: solid 1px ${theme.colors.border.other1};
            p {
                color: ${theme.colors.text.default.primary};
            }
            svg {
                fill: ${theme.colors.text.default.primary};
            }
        }
    `}

    &.disabled {
        background-color: ${({ theme }) => theme.colors.grey.fill2};
        border-color: ${({ theme }) => theme.colors.border.default2};
    }

    &:focus-visible {
        outline: 1px solid ${({ theme }) => theme.colors.border.other1};
    }

    ${({ hasValue, theme }) =>
        hasValue
            ? `
        color: ${theme.colors.text.default.secondary};
        p {
            color: ${theme.colors.text.default.secondary};
        }
        svg {
            fill: ${theme.colors.text.default.secondary};
        }

        &.selected {
            color: ${theme.colors.text.default.primary};
            p {
                color: ${theme.colors.text.default.primary};
            }
            svg {
                fill: ${theme.colors.text.default.primary};
            }
        }

        &.disabled {
            color: ${theme.colors.text.default.disabled};
            p {
                color: ${theme.colors.text.default.disabled};
            }
            svg {
                fill: ${theme.colors.text.default.disabled};
            }
        }

        
    `
            : ''}

    ${color}
    ${border}
`;

const StyledIcon = styled(Icon)`
    fill: ${({ theme }) => theme.colors.text.default.secondary};
`;

const CancelButton = styled(UnstyledButton)`
    display: flex;
    margin-left: ${({ theme }) => theme.space.x2};
`;
