import IncidentObserver from 'components/errors/IncidentObserver';
import { TooltipStyles } from 'components/tooltip/Tooltip';
import { FullScreenProvider } from 'context/FullScreen';
import { ProfilingProvider } from 'context/Profiling';
import { ReportingProvider } from 'context/ReportingContext';
import useCustomDomainManager from 'hooks/useCustomDomainManager';
import { AirmeetTranslationProvider } from 'hooks/useCustomTranslation';
import useLogWorkerManager from 'hooks/useLogWorkerManager';
import useTapfiliateClickScript from 'hooks/useTapfiliateClickScript';
import useVWO from 'hooks/useVWO';
import React from 'react';
import { Router } from 'react-router-dom';
import 'styles/App.scss';
import 'styles/base.scss';
import AllInfoBanners from '../components/allInfoBanners';
import BestExperienceBrowser from '../components/general/BestExperienceBrowser';
import Page from '../components/general/Page';
import Toasts from '../components/general/Toasts';
import AppHeader from '../components/header';
import Footer from '../components/home/footer';
import AppModals from '../components/modals';
import SideMenu from '../components/sideMenu';
import history from '../history';
import useAuthManager from '../hooks/useAuthManager';
import useBeamerWidget from '../hooks/useBeamerWidget';
import useChatWidget from '../hooks/chatWidget/useChatWidget';
import Routes from '../route';

export default function App() {
    // Manage auth at app level
    useAuthManager();
    useLogWorkerManager();
    useBeamerWidget();
    useVWO();
    useTapfiliateClickScript();
    useCustomDomainManager();
    useChatWidget();

    return (
        <ReportingProvider>
            <ProfilingProvider
                options={{
                    log: true,
                }}
            >
                <AirmeetTranslationProvider>
                    <Router history={history}>
                        <BestExperienceBrowser />
                        <FullScreenProvider>
                            <Toasts />
                            <AllInfoBanners />
                            <AppHeader />
                            <div id='main-navportal'></div>
                            <div className='app-content'>
                                <SideMenu />

                                <Page>
                                    <Routes history={history} />
                                </Page>
                            </div>

                            <Footer />
                            <AppModals />
                        </FullScreenProvider>
                    </Router>
                </AirmeetTranslationProvider>
            </ProfilingProvider>
            <IncidentObserver />
            <TooltipStyles />
        </ReportingProvider>
    );
}
