import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { EVENT_MODERATOR, getHash } from 'utils/constants/users';
import UserRoles from 'utils/userRoles';
import { populateNameFromFirstLastName } from 'utils/users';
import { STATUS_BLOCKED } from '../../components/report/constants';
import createReducer from '../../utils/createReducer';
import {
    ADD_CHAT,
    ADD_REPORT_MESSAGE,
    ADD_SESSION_SPEAKER_RESPONSE,
    CLEAR_BLOCKED_USERS,
    CLEAR_CHAT,
    CLEAR_REPORT_MESSAGES,
    CREATE_SESSION_RESPONSE,
    CREAT_AIRMEET_RESPONSE,
    FETCH_AIRMEET_COMBINED_RESPONSE,
    FETCH_AIRMEET_RESPONSE,
    FETCH_AIRMEET_USER_RESPONSE,
    INTRO_VIDEO_RESPONSE,
    RESET_LOUNGE_AIRMEET_DATA,
    SET_AIRMEET_STATE,
    SET_ALL_TABLE,
    SET_BLOCKED_USERS,
    SET_CAN_ATTENDEE_CREATE_TABLE,
    SET_CHATS,
    SET_CURRENT_SESSION_ID,
    SET_IS_BACKSTAGE,
    SET_IS_WATCH_REPLAY_CLICKED,
    SET_LOUNGE_TABLES_CONFIG,
    SET_REQUEST_PRODUCT_TOUR,
    SET_SUBSCRIBE_TRACK,
    SET_TABLES_LIST,
    SET_USERS_INFO,
    SET_USER_REGISTRATION_COMPLETE,
    UPDATE_RSVP_RESPONSE,
    UPDATE_SESSION_STATUS,
} from '../actions/Lounge';

const initialState = {
    airmeet: {},
    subscribedTrackId: null,
    chats: {},
    assocChats: {},
    isBackStage: false,
    isRequestProductTour: false,
    reports: { blockedMessages: [], messages: [], blockedUsers: null },
    selectedOrder: 'Upvotes',
    selectedTab: 'Open Questions',
    hostDelegation: {
        delegatedHostId: '',
        status: 'initial',
    },
    isWatchReplayClicked: false,
    tablesList: [],
    loungeConfig: {},
    canAttendeeCreateTable: false,
    isUserRegistrationComplete: null,
    introVideoDetails: null,
};
const handleAirmeetInfo = (state = initialState, action) => {
    let { payload, error } = action;
    // API error or no response
    if (error || !payload) {
        // Nothing to do
        return state;
    }

    if (Object.keys(payload).length === 0) {
        return {
            ...state,
            airmeet: {
                error: 'not-found',
                errorId: action.extra.airmeetId,
            },
        };
    }

    if (typeof payload.networking_enabled === 'undefined') {
        payload.networking_enabled = true;
    }
    if (typeof payload.session_enabled === 'undefined') {
        payload.session_enabled = true;
    }

    const preAirmeetData =
        action.extra.airmeetId &&
        state.airmeet &&
        state.airmeet.airmeetId === action.extra.airmeetId
            ? state.airmeet
            : {};

    if (preAirmeetData.table_count && !payload.table_count) {
        payload.table_count = preAirmeetData.table_count;
    }

    const responseUsers = payload.users || [];
    if (responseUsers.length === 0 && action.extra?.options?.authUser) {
        responseUsers.push(action.extra.options.authUser);
    }
    const addPrevUsers = preAirmeetData && preAirmeetData.assocUsers;

    payload.invitedSpeakerList = action.extra.invitedSpeakerList
        ? action.extra.invitedSpeakerList
        : null;

    const parallelTrackData =
        state.airmeet &&
        action.extra.airmeetId === state.airmeet.airmeetId &&
        state.airmeet.parallelTrackData
            ? state.airmeet.parallelTrackData
            : null;
    if (payload.is_session_level_stage_enabled && parallelTrackData) {
        payload.parallelTrackData = {
            ...parallelTrackData,
            ...payload.parallelTrackData,
        };
    }
    const airmeet = payload;

    if (action.extra.airmeetId) {
        airmeet.airmeetId = action.extra.airmeetId;
    }

    const assocUsers = addPrevUsers ? preAirmeetData.assocUsers : {};
    const assocIntUsers = addPrevUsers ? preAirmeetData.assocIntUsers : {};
    const organizers = addPrevUsers ? preAirmeetData.organizers : {};

    responseUsers.forEach((user) => {
        user.role = 'Attendee';
        user.hash = user.hash ? user.hash : getHash(user);
        assocUsers[user.id] = user;
        assocIntUsers[user.id_seq] = user;
        if ((user.airmeetRoles || []).includes(EVENT_MODERATOR)) {
            organizers[user.id] = user;
        }
    });

    const roleIds = {};
    const updateRoleForUser = (userId, role) => {
        const user = assocUsers[userId];
        if (!user) {
            return;
        }
        user.role = role;
    };

    if (airmeet.host) {
        roleIds[airmeet.host] = 'Host';
        updateRoleForUser(airmeet.host, 'Host');
    }
    if (airmeet.sessions) {
        airmeet.sessions.forEach((session, idx) => {
            if (session.host_id) {
                session.host_id.forEach((host) => {
                    roleIds[host] = 'Host';
                    updateRoleForUser(host, 'Host');
                });
            }

            if (action.extra.invitedSpeakerList) {
                let tempList =
                    action.extra.invitedSpeakerList[session.sessionid];

                if (tempList)
                    tempList = Object.entries(tempList)
                        // Sort speakers by timestamp of invitation
                        .sort(([, ts1], [, ts2]) => ts1 - ts2)
                        .map(([key]) => key);
                else tempList = [];

                airmeet.sessions[idx].speakerList = airmeet.sessions[
                    idx
                ].speakerList.concat(
                    tempList
                        .map((invitedSpeakerId) => {
                            // prevent duplicate entry of speakers
                            if (
                                airmeet.sessions[idx].speaker_id.includes(
                                    invitedSpeakerId
                                )
                            ) {
                                return null;
                            }
                            const user = assocUsers[invitedSpeakerId];
                            return user
                                ? {
                                      speaker_intro: '',
                                      speaker_web_url: '',
                                      isModerator: false,
                                      isInvited: true,
                                      ...user,
                                      speaker_img: user.profile_img,
                                  }
                                : {
                                      speaker_intro: '',
                                      speaker_web_url: '',
                                      isModerator: false,
                                      isInvited: true,
                                      id: invitedSpeakerId,
                                  };
                        })
                        .filter((u) => !!u)
                );

                airmeet.sessions[idx].speaker_id = airmeet.sessions[
                    idx
                ].speakerList.map(({ id }) => id);
            }

            if (session.speaker_id) {
                // Merge speaker, and corresponding user data
                let speakers = (Array.isArray(session.speaker_id)
                    ? session.speaker_id
                    : [session.speaker_id]
                ).map((sid) => ({
                    ...(assocUsers[sid] || {}),
                    ...session.speakerList.find((item) => item.id === sid),
                }));
                // remove duplicates if exist;

                speakers.forEach((speaker) => {
                    roleIds[speaker.id] = 'Speaker';
                    if (
                        typeof speaker.is_moderator !== 'undefined' &&
                        speaker.is_moderator
                    ) {
                        roleIds[speaker.id] = 'Moderator';
                    }
                    updateRoleForUser(speaker.id, roleIds[speaker.id]);
                });
            }
        });
    }

    if (
        airmeet?.parallelTrackData?.isParallelTrackEvent ||
        !!process.env.REACT_APP_NEW_TEMPLATE_FOR_MEETUP ||
        !!airmeet.is_parallel_track_enabled
    ) {
        airmeet.organizers = organizers;
    }
    airmeet.roleIds = roleIds;
    delete airmeet.users;
    airmeet.assocUsers = assocUsers;
    airmeet.assocIntUsers = assocIntUsers;

    // Avoid resetting customFormInfo if CR is enabled
    if (
        airmeet?.is_custom_tables_enabled &&
        !isEmpty(preAirmeetData?.customFormInfo) &&
        isEmpty(airmeet?.customFormInfo)
    ) {
        airmeet.customFormInfo = preAirmeetData?.customFormInfo;
    }

    if (
        Array.isArray(airmeet?.session_hosts) &&
        airmeet.session_hosts.length > 0 &&
        airmeet.session_hosts.some((h) => !h?.name)
    ) {
        // Derive name from first/last name if name is not present
        airmeet.session_hosts = airmeet.session_hosts.map((user) =>
            populateNameFromFirstLastName(user)
        );
    }

    if (
        Array.isArray(airmeet?.session_cohosts) &&
        airmeet.session_cohosts.length > 0 &&
        airmeet.session_cohosts.some((h) => !h?.name)
    ) {
        // Derive name from first/last name if name is not present
        airmeet.session_cohosts = airmeet.session_cohosts.map((user) =>
            populateNameFromFirstLastName(user)
        );
    }

    action.payload = airmeet;
    return { ...state, airmeet };
};

const handlers = {};

handlers[FETCH_AIRMEET_RESPONSE] = handleAirmeetInfo;
handlers[FETCH_AIRMEET_COMBINED_RESPONSE] = handleAirmeetInfo;

handlers[SET_USERS_INFO] = (state = initialState, action) => {
    if (action.error || !action.payload) {
        return state;
    }
    let usersInfo = action.payload || [];

    const assocUsers = {};
    const assocIntUsers = {};
    usersInfo.forEach((user) => {
        user.hash = user.hash ? user.hash : getHash(user);
        if (!user.role) {
            if (
                state.airmeet.roleIds &&
                typeof state.airmeet.roleIds[user.id] !== 'undefined'
            ) {
                user.role = state.airmeet.roleIds[user.id];
            } else {
                user.role = 'Attendee';
            }
        }

        if (state.airmeet.assocUsers && state.airmeet.assocUsers[user.id]) {
            user = { ...state.airmeet.assocUsers[user.id], ...user };
        }
        assocUsers[user.id] = user;
        assocIntUsers[user.id_seq] = user;
    });

    const airmeet = state.airmeet;
    airmeet.assocUsers = airmeet.assocUsers
        ? {
              ...state.airmeet.assocUsers,
              ...assocUsers,
          }
        : assocUsers;

    airmeet.assocIntUsers = state.airmeet.assocIntUsers
        ? {
              ...state.airmeet.assocIntUsers,
              ...assocIntUsers,
          }
        : assocIntUsers;

    UserRoles.setUsers(airmeet.assocUsers);

    return {
        ...state,
        airmeet,
    };
};
handlers[RESET_LOUNGE_AIRMEET_DATA] = (state = initialState) => {
    return {
        ...state,
        airmeet: initialState.airmeet,
    };
};
handlers[FETCH_AIRMEET_USER_RESPONSE] = (state = initialState, action) => {
    if (action.error || !action.payload) {
        return state;
    }

    let user = action.payload;
    user.hash = user.hash ? user.hash : getHash(user);
    if (!user.role) {
        if (
            state.airmeet.roleIds &&
            typeof state.airmeet.roleIds[user.id] !== 'undefined'
        ) {
            user.role = state.airmeet.roleIds[user.id];
        } else {
            user.role = 'Attendee';
        }
    }

    if (state.airmeet.assocUsers && state.airmeet.assocUsers[user.id]) {
        user = { ...state.airmeet.assocUsers[user.id], ...user };
    }

    const airmeet = state.airmeet;
    airmeet.assocUsers = state.airmeet.assocUsers || {};
    airmeet.assocUsers = {
        ...state.airmeet.assocUsers,
        [user.id]: user,
    };

    airmeet.assocIntUsers = state.airmeet.assocIntUsers || {};
    airmeet.assocIntUsers = {
        ...state.airmeet.assocIntUsers,
        [user.id_seq]: user,
    };
    return {
        ...state,
        airmeet,
    };
};

handlers[CREAT_AIRMEET_RESPONSE] = (state = initialState, action) => {
    const airmeet = action.payload;
    let responseState = {};
    if (airmeet.id) {
        responseState = {
            createdAirmeetId: airmeet.id,
            erorrs: false,
        };
    } else {
        responseState = {
            erorrs: true,
        };
    }

    return { ...state, ...responseState };
};

handlers[CREATE_SESSION_RESPONSE] = (state = initialState, action) => {
    const airmeet = action.payload;
    let responseState = {};
    if (airmeet.sessionid) {
        responseState = {
            sessionId: airmeet.sessionid,
            erorrs: false,
        };
    } else {
        responseState = {
            erorrs: true,
        };
    }

    return { ...state, ...responseState };
};

handlers[ADD_SESSION_SPEAKER_RESPONSE] = (state = initialState, action) => {
    const airmeet = action.payload;
    let responseState = {};
    if (airmeet.speaker_id) {
        responseState = {
            airmeetSessionSpeakerId: airmeet.speaker_id,
            errors: false,
        };
    } else {
        responseState = {
            errors: true,
        };
    }

    return { ...state, ...responseState };
};

handlers[UPDATE_RSVP_RESPONSE] = (state = initialState, { payload }) => {
    return { state, lounge: {} };
};

handlers[ADD_CHAT] = (state = initialState, { payload }) => {
    const { chats, assocChats } = state;
    const {
        content: { room, sessionId },
        chatKey,
    } = payload;

    if (!assocChats[room]) {
        assocChats[room] = {};
    }
    if (!chats[room]) {
        chats[room] = {};
    }

    if (!chats[room][sessionId || 'SOCIAL_LOUNGE']) {
        chats[room][sessionId || 'SOCIAL_LOUNGE'] = [];
    }

    if (!assocChats[room][chatKey]) {
        assocChats[room][chatKey] = { ...payload.content, chatKey };
        chats[room][sessionId || 'SOCIAL_LOUNGE'] = [
            ...chats[room][sessionId || 'SOCIAL_LOUNGE'],
            { ...payload.content, chatKey },
        ];
    }
    /** this contains the logic to update the chat and redux store when question gets upvoted/downvoted */
    chats[room][sessionId || 'SOCIAL_LOUNGE'].forEach(
        (item, foundChatIndex) => {
            if (item.chatKey === chatKey) {
                // const foundChatIndex = chats[room][sessionId].indexOf(item);
                chats[room][sessionId || 'SOCIAL_LOUNGE'][foundChatIndex] = {
                    ...payload.content,
                    chatKey,
                };
                assocChats[room][chatKey] = { ...payload.content, chatKey };
            }
        }
    );
    /** question upvote/downvote ends */

    chats[room] = { ...chats[room] };
    assocChats[room] = { ...assocChats[room] };

    return { ...state, chats, assocChats };
};

handlers[SET_CHATS] = (state = initialState, { payload }) => {
    const { chats, assocChats } = state;
    const { room, chats: loadedChats } = payload;
    const sessions = {};
    loadedChats.forEach((message) => {
        const { sessionId } = message;
        if (!sessions[sessionId || 'SOCIAL_LOUNGE']) {
            sessions[sessionId || 'SOCIAL_LOUNGE'] = [];
        }
        sessions[sessionId || 'SOCIAL_LOUNGE'].push(message);
    });
    chats[room] = sessions;

    assocChats[room] = loadedChats.reduce((messages, message) => {
        messages[message.chatKey] = message;
        return messages;
    }, {});

    return { ...state, chats, assocChats };
};

handlers[CLEAR_CHAT] = (state = initialState, { payload }) => {
    const { chats, assocChats } = state;
    chats[payload.channel] = [];
    assocChats[payload.channel] = {};
    return { ...state, chats, assocChats };
};

handlers[ADD_REPORT_MESSAGE] = (state = initialState, { payload }) => {
    const { reports } = state;
    const { blockedMessages, messages } = reports;
    const { key, content } = payload;

    const index = messages.findIndex((report) => report._nodeId === key);

    if (index > -1) {
        messages[index] = { ...content, _nodeId: key };
    } else {
        messages.push({ ...content, _nodeId: key });
    }

    const blockedMessageIndex = blockedMessages.indexOf(content.chatKey);
    if (blockedMessageIndex === -1) {
        // Adding to blocked list
        if (content.status === STATUS_BLOCKED) {
            blockedMessages.push(content.chatKey);
        }
    } else {
        // Removing from blocked list
        if (content.status !== STATUS_BLOCKED) {
            blockedMessages.splice(blockedMessageIndex, 1);
        }
    }

    const updatedReports = {
        ...reports,
        messages: [...messages],
        blockedMessages: [...blockedMessages],
    };

    return { ...state, reports: updatedReports };
};

handlers[CLEAR_REPORT_MESSAGES] = (state = initialState) => {
    const { reports } = state;

    return {
        ...state,
        reports: {
            ...initialState.reports,
            blockedUsers: reports.blockedUsers,
        },
    };
};

handlers[CLEAR_BLOCKED_USERS] = (state = initialState, { payload }) => {
    return {
        ...state,
        reports: {
            ...state.reports,
            blockedUsers: {},
        },
    };
};

handlers[SET_BLOCKED_USERS] = (state = initialState, { payload }) => {
    const { reports } = state;
    const { content } = payload;

    const updatedReports = {
        ...reports,
        blockedUsers: content,
    };
    return { ...state, reports: updatedReports };
};

handlers[SET_IS_BACKSTAGE] = (state = initialState, { payload }) => {
    return { ...state, isBackStage: payload.status };
};

handlers[SET_REQUEST_PRODUCT_TOUR] = (state = initialState, { payload }) => {
    return { ...state, isRequestProductTour: payload || '' };
};

handlers[SET_CURRENT_SESSION_ID] = (state = initialState, { payload }) => {
    const { currentSessionId } = payload;
    return {
        ...state,
        airmeet: {
            ...state.airmeet,
            parallelTrackData: {
                ...state.airmeet.parallelTrackData,
                currentSessionId,
            },
        },
    };
};

handlers[SET_SUBSCRIBE_TRACK] = (state = initialState, { payload }) => {
    const { subscribedTrackId } = payload;
    return {
        ...state,
        subscribedTrackId,
    };
};
handlers[INTRO_VIDEO_RESPONSE] = (state = initialState, { payload }) => {
    return {
        ...state,
        introVideoDetails: payload?.body,
    };
};
handlers[UPDATE_SESSION_STATUS] = (state = initialState, { payload }) => {
    const sessions = [...(state.airmeet.sessions || [])];
    const safeSessions = [...(state.airmeet.safeSessions || [])];

    const sessionIndex = sessions.findIndex(
        (s) => s.sessionid === payload.sessionId
    );
    if (sessionIndex > -1) {
        sessions[sessionIndex].status = payload.status;
    }

    const safeSessionIndex = safeSessions.findIndex(
        (s) => s.sessionid === payload.sessionId
    );
    if (safeSessionIndex > -1) {
        safeSessions[safeSessionIndex].status = payload.status;
    }

    const airmeet = { ...state.airmeet, sessions, safeSessions };
    return { ...state, airmeet };
};

handlers[SET_AIRMEET_STATE] = (state = initialState, { payload }) => {
    const airmeet = cloneDeep({ ...(state.airmeet || {}), ...(payload || {}) });
    return { ...state, airmeet };
};

handlers[SET_IS_WATCH_REPLAY_CLICKED] = (state = initialState, { payload }) => {
    return { ...state, isWatchReplayClicked: payload.status };
};

handlers[SET_TABLES_LIST] = (state = initialState, { payload }) => {
    return { ...state, tablesList: payload.tablesList };
};

handlers[SET_ALL_TABLE] = (state = initialState, { payload }) => {
    return { ...state, allTable: payload.allTable };
};

handlers[SET_LOUNGE_TABLES_CONFIG] = (state = initialState, { payload }) => {
    return { ...state, loungeConfig: payload.loungeConfig };
};

handlers[SET_CAN_ATTENDEE_CREATE_TABLE] = (
    state = initialState,
    { payload }
) => {
    return { ...state, canAttendeeCreateTable: payload.canAttendeeCreateTable };
};

handlers[SET_USER_REGISTRATION_COMPLETE] = (
    state = initialState,
    { payload }
) => {
    return { ...state, isUserRegistrationComplete: payload.status };
};

export default createReducer(initialState, handlers);
