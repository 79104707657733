import { CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION } from 'utils/closed-captions';

export const CC_PRIMARY_LANGUAGE_OPTIONS_GCP = [
    { label: 'English (United States)', value: 'en-US' },
    { label: 'English (United Kingdom)', value: 'en-GB' },
    { label: 'English (Australia)', value: 'en-AU' },
    { label: 'French (Canada)', value: 'fr-CA' },
    { label: 'French (France)', value: 'fr-FR' },
    { label: 'German (Germany)', value: 'de-DE' },
    { label: 'Italian (Italy)', value: 'it-IT' },
    { label: 'Japanese (Japan)', value: 'ja-JP' },
    { label: 'Portuguese (Brazil)', value: 'pt-BR' },
    // { label: 'Spanish (Spain)', value: 'es-ES' },
    { label: 'Spanish (United States)', value: 'es-US' },
    // { label: 'Russian (Russia)', value: 'ru-RU' },
    { label: 'Hindi (India)', value: 'hi-IN' },
    { label: 'Korean (South Korea)', value: 'ko-KR' },
];

export const CC_PRIMARY_LANGUAGE_OPTIONS_AWS = [
    // { label: 'Chinese (Simplified)', value: 'zh-CN' },
    { label: 'English (US)', value: 'en-US' },
    { label: 'English (British)', value: 'en-GB' },
    { label: 'English (Australian)', value: 'en-AU' },
    { label: 'French', value: 'fr-FR' },
    { label: 'French (Canadian)', value: 'fr-CA' },
    { label: 'German', value: 'de-DE' },
    // { label: 'Hindi (Indian)', value: 'hi-IN' },
    { label: 'Italian', value: 'it-IT' },
    { label: 'Japanese', value: 'ja-JP' },
    // { label: 'Korean', value: 'ko-KR' },
    { label: 'Portuguese (Brazilian)', value: 'pt-BR' },
    { label: 'Spanish (US)', value: 'es-US' },
    // { label: 'Thai', value: 'th-TH' },
];

// https://developers.deepgram.com/docs/models-languages-overview
export const CC_PRIMARY_LANGUAGE_OPTIONS_DEEPGRAM = [
    { label: 'English (United States)', value: 'en-US' },
    { label: 'English (United Kingdom)', value: 'en-GB' },
    { label: 'English (Australia)', value: 'en-AU' },
    { label: 'French (Canada)', value: 'fr-CA' },
    { label: 'French (France)', value: 'fr' },
    { label: 'German (Germany)', value: 'de' },
    { label: 'Italian (Italy)', value: 'it' },
    { label: 'Japanese (Japan)', value: 'ja' },
    { label: 'Portuguese (Brazil)', value: 'pt-BR' },
    { label: 'Spanish (United States)', value: 'es-419' },
    { label: 'Spanish (Spain)', value: 'es' },
    { label: 'Russian', value: 'ru' },
    { label: 'Hindi (India)', value: 'hi' },
    { label: 'Korean (South Korea)', value: 'ko-KR' },
];

export const CC_PRIMARY_LANGUAGE_OPTIONS = {
    [CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.google]: CC_PRIMARY_LANGUAGE_OPTIONS_GCP,
    [CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.aws]: CC_PRIMARY_LANGUAGE_OPTIONS_AWS,
    [CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.deepgram]: CC_PRIMARY_LANGUAGE_OPTIONS_DEEPGRAM,
};

export const CC_LANGUAGE_OPTIONS_GCP = [
    { label: 'Afrikaans', value: 'af' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Amharic', value: 'am' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Armenian', value: 'hy' },
    { label: 'Assamese', value: 'as' },
    { label: 'Aymara', value: 'ay' },
    { label: 'Azerbaijani', value: 'az' },
    { label: 'Bambara', value: 'bm' },
    { label: 'Basque', value: 'eu' },
    { label: 'Belarusian', value: 'be' },
    { label: 'Bengali', value: 'bn' },
    { label: 'Bhojpuri', value: 'bho' },
    { label: 'Bosnian', value: 'bs' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Catalan', value: 'ca' },
    { label: 'Cebuano', value: 'ceb' },
    { label: 'Chinese (Simplified)', value: 'zh-CN' },
    { label: 'Chinese (Traditional)', value: 'zh-TW' },
    { label: 'Corsican', value: 'co' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Czech', value: 'cs' },
    { label: 'Danish', value: 'da' },
    { label: 'Dhivehi', value: 'dv' },
    { label: 'Dogri', value: 'doi' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Esperanto', value: 'eo' },
    { label: 'Estonian', value: 'et' },
    { label: 'Ewe', value: 'ee' },
    { label: 'Filipino (Tagalog)', value: 'fil' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'Frisian', value: 'fy' },
    { label: 'Galician', value: 'gl' },
    { label: 'Georgian', value: 'ka' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'Guarani', value: 'gn' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Haitian Creole', value: 'ht' },
    { label: 'Hausa', value: 'ha' },
    { label: 'Hawaiian', value: 'haw' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Hmong', value: 'hmn' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Igbo', value: 'ig' },
    { label: 'Ilocano', value: 'ilo' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Irish', value: 'ga' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Javanese', value: 'jv' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Kazakh', value: 'kk' },
    { label: 'Khmer', value: 'km' },
    { label: 'Kinyarwanda', value: 'rw' },
    { label: 'Konkani', value: 'gom' },
    { label: 'Korean', value: 'ko' },
    { label: 'Krio', value: 'kri' },
    { label: 'Kurdish', value: 'ku' },
    { label: 'Kurdish (Sorani)', value: 'ckb' },
    { label: 'Kyrgyz', value: 'ky' },
    { label: 'Lao', value: 'lo' },
    { label: 'Latin', value: 'la' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Lingala', value: 'ln' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Luganda', value: 'lg' },
    { label: 'Luxembourgish', value: 'lb' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Maithili', value: 'mai' },
    { label: 'Malagasy', value: 'mg' },
    { label: 'Malay', value: 'ms' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Maltese', value: 'mt' },
    { label: 'Maori', value: 'mi' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Meiteilon (Manipuri)', value: 'mni-Mtei' },
    { label: 'Mizo', value: 'lus' },
    { label: 'Mongolian', value: 'mn' },
    { label: 'Myanmar (Burmese)', value: 'my' },
    { label: 'Nepali', value: 'ne' },
    { label: 'Norwegian', value: 'no' },
    { label: 'Nyanja (Chichewa)', value: 'ny' },
    { label: 'Odia (Oriya)', value: 'or' },
    { label: 'Oromo', value: 'om' },
    { label: 'Pashto', value: 'ps' },
    { label: 'Persian', value: 'fa' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese (Portugal Brazil)', value: 'pt' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Quechua', value: 'qu' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Russian', value: 'ru' },
    { label: 'Samoan', value: 'sm' },
    { label: 'Sanskrit', value: 'sa' },
    { label: 'Scots Gaelic', value: 'gd' },
    { label: 'Sepedi', value: 'nso' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Sesotho', value: 'st' },
    { label: 'Shona', value: 'sn' },
    { label: 'Sindhi', value: 'sd' },
    { label: 'Sinhala (Sinhalese)', value: 'si' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Somali', value: 'so' },
    { label: 'Spanish', value: 'es' },
    { label: 'Sundanese', value: 'su' },
    { label: 'Swahili', value: 'sw' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Tagalog (Filipino)', value: 'tl' },
    { label: 'Tajik', value: 'tg' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Tatar', value: 'tt' },
    { label: 'Telugu', value: 'te' },
    { label: 'Thai', value: 'th' },
    { label: 'Tigrinya', value: 'ti' },
    { label: 'Tsonga', value: 'ts' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Turkmen', value: 'tk' },
    { label: 'Twi (Akan)', value: 'ak' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Uyghur', value: 'ug' },
    { label: 'Uzbek', value: 'uz' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Welsh', value: 'cy' },
    { label: 'Xhosa', value: 'xh' },
    { label: 'Yiddish', value: 'yi' },
    { label: 'Yoruba', value: 'yo' },
    { label: 'Zulu', value: 'zu' },
];

export const CC_LANGUAGE_OPTIONS_AWS = [
    { label: 'Afrikaans', value: 'af' },
    { label: 'Albanian', value: 'sq' },
    { label: 'Amharic', value: 'am' },
    { label: 'Arabic', value: 'ar' },
    { label: 'Armenian', value: 'hy' },
    { label: 'Azerbaijani', value: 'az' },
    { label: 'Bengali', value: 'bn' },
    { label: 'Bosnian', value: 'bs' },
    { label: 'Bulgarian', value: 'bg' },
    { label: 'Catalan', value: 'ca' },
    { label: 'Chinese (Simplified)', value: 'zh' },
    { label: 'Chinese (Traditional)', value: 'zh-TW' },
    { label: 'Croatian', value: 'hr' },
    { label: 'Czech', value: 'cs' },
    { label: 'Danish', value: 'da' },
    { label: 'Dari', value: 'fa-AF' },
    { label: 'Dutch', value: 'nl' },
    { label: 'English', value: 'en' },
    { label: 'Estonian', value: 'et' },
    { label: 'Farsi (Persian)', value: 'fa' },
    { label: 'Filipino (Tagalog)', value: 'tl' },
    { label: 'Finnish', value: 'fi' },
    { label: 'French', value: 'fr' },
    { label: 'French (Canada)', value: 'fr-CA' },
    { label: 'Georgian', value: 'ka' },
    { label: 'German', value: 'de' },
    { label: 'Greek', value: 'el' },
    { label: 'Gujarati', value: 'gu' },
    { label: 'Haitian Creole', value: 'ht' },
    { label: 'Hausa', value: 'ha' },
    { label: 'Hebrew', value: 'he' },
    { label: 'Hindi', value: 'hi' },
    { label: 'Hungarian', value: 'hu' },
    { label: 'Icelandic', value: 'is' },
    { label: 'Indonesian', value: 'id' },
    { label: 'Irish', value: 'ga' },
    { label: 'Italian', value: 'it' },
    { label: 'Japanese', value: 'ja' },
    { label: 'Kannada', value: 'kn' },
    { label: 'Kazakh', value: 'kk' },
    { label: 'Korean', value: 'ko' },
    { label: 'Latvian', value: 'lv' },
    { label: 'Lithuanian', value: 'lt' },
    { label: 'Macedonian', value: 'mk' },
    { label: 'Malay', value: 'ms' },
    { label: 'Malayalam', value: 'ml' },
    { label: 'Maltese', value: 'mt' },
    { label: 'Marathi', value: 'mr' },
    { label: 'Mongolian', value: 'mn' },
    { label: 'Norwegian (Bokmål)', value: 'no' },
    { label: 'Pashto', value: 'ps' },
    { label: 'Polish', value: 'pl' },
    { label: 'Portuguese (Brazil)', value: 'pt' },
    { label: 'Portuguese (Portugal)', value: 'pt-PT' },
    { label: 'Punjabi', value: 'pa' },
    { label: 'Romanian', value: 'ro' },
    { label: 'Russian', value: 'ru' },
    { label: 'Serbian', value: 'sr' },
    { label: 'Sinhala', value: 'si' },
    { label: 'Slovak', value: 'sk' },
    { label: 'Slovenian', value: 'sl' },
    { label: 'Somali', value: 'so' },
    { label: 'Spanish', value: 'es' },
    { label: 'Spanish (Mexico)', value: 'es-MX' },
    { label: 'Swahili', value: 'sw' },
    { label: 'Swedish', value: 'sv' },
    { label: 'Tamil', value: 'ta' },
    { label: 'Telugu', value: 'te' },
    { label: 'Thai', value: 'th' },
    { label: 'Turkish', value: 'tr' },
    { label: 'Ukrainian', value: 'uk' },
    { label: 'Urdu', value: 'ur' },
    { label: 'Uzbek', value: 'uz' },
    { label: 'Vietnamese', value: 'vi' },
    { label: 'Welsh', value: 'cy' },
];

export const CC_LANGUAGE_OPTIONS_MAP = {
    [CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.google]: CC_LANGUAGE_OPTIONS_GCP,
    [CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.aws]: CC_LANGUAGE_OPTIONS_AWS,
    [CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.deepgram]: CC_LANGUAGE_OPTIONS_GCP,
};

export const getCCLanguageOptions = (serviceProvider) => ({
    supportedLangs: CC_LANGUAGE_OPTIONS_MAP[serviceProvider],
    primaryLangs: CC_PRIMARY_LANGUAGE_OPTIONS[serviceProvider],
});

export const getCCPrimaryLangVendorValue = (languageKey, serviceProvider) => {
    let primaryLangs = CC_PRIMARY_LANGUAGE_OPTIONS[serviceProvider];
    const lang = primaryLangs?.find((primaryLang) => {
        return primaryLang.label == languageKey;
    });
    return lang?.value;
};
