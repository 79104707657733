import i18n from 'i18next';
import HttpBackend from 'i18next-http-backend';
// eslint-disable-next-line no-restricted-imports
import { initReactI18next } from 'react-i18next';
import { Whitelist } from './locale/Languages';

const TranslationsVersion = 85;

const cdnUrl = process.env.CDN_ASSETS_URL;
const LOCALES_BASE_PATH = `${
    cdnUrl === '/' ? process.env.PUBLIC_URL + '/' : cdnUrl
}`;

i18n.use(HttpBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: process.env.REACT_APP_ENV === 'dev',
        whitelist: Whitelist,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: (lng, ns) => {
                if (lng[0] === 'en') {
                    return `${LOCALES_BASE_PATH}locales/en/translation.json?v=${TranslationsVersion}`;
                } else {
                    return `${process.env.REACT_APP_AWS_S3_LOCALIZATION_BUCKET_PATH}/localization/{{lng}}/{{ns}}.json?v=${TranslationsVersion}`;
                }
            },
        },
    });
export default i18n;
