import Button from 'atoms/Button/button';
import classNames from 'classnames';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';

function Prompt({
    show,
    heading,
    message,
    resolveText,
    rejectText,
    onYes,
    onNo,
    resolveEnable = true,
    rejectEnable = true,
    classType = 'for-warning',
    setHTMLDangerously = false,
    ariaLabel = {
        yesButtonLabel: 'Yes',
        noButtonLabel: 'No',
    },
}) {
    return (
        <>
            <Modal
                show={show}
                lightTheme
                className={classNames(
                    'modal fade light-theme gModal POPUP_MODAL_COMMON_CLASS',
                    classType,
                    {
                        show,
                    }
                )}
            >
                <div
                    className='modal-dialog POPUP_MODAL_COMMON_CLASS'
                    role='document'
                >
                    <div className='modal-content POPUP_MODAL_COMMON_CLASS'>
                        <div className='modal-body POPUP_MODAL_COMMON_CLASS'>
                            <div className='steps-one POPUP_MODAL_COMMON_CLASS'>
                                {!heading && message && <h2>{message}</h2>}
                                {heading && (
                                    <>
                                        <h2>{heading}</h2>
                                        {setHTMLDangerously ? (
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: message,
                                                }}
                                            ></p>
                                        ) : (
                                            <p>{message}</p>
                                        )}
                                    </>
                                )}
                            </div>
                            <div
                                className={`action-btns POPUP_MODAL_COMMON_CLASS ${
                                    !resolveEnable || !rejectEnable
                                        ? 'justify-content-end custom-btn-style'
                                        : ''
                                }`}
                            >
                                {rejectEnable && (
                                    <PromptNoButton
                                        onClick={onNo}
                                        className='btn btn-light-danger w-100 POPUP_MODAL_COMMON_CLASS'
                                        aria-label={ariaLabel.noButtonLabel}
                                    >
                                        {rejectText || 'No'}
                                    </PromptNoButton>
                                )}
                                {resolveEnable && (
                                    <PromptYesButton
                                        variant='primary'
                                        onClick={onYes}
                                        aria-label={ariaLabel.yesButtonLabel}
                                    >
                                        {resolveText || 'Yes'}
                                    </PromptYesButton>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                show={show}
                className={classNames('modal-backdrop d-none fade', classType, {
                    show,
                })}
            ></Modal>
        </>
    );
}

export default Prompt;

const sharedStyles = css`
    &:focus-visible {
        outline: solid black !important;
    }
`;

const PromptNoButton = styled.button`
    ${sharedStyles}
`;

const PromptYesButton = styled(Button)`
    ${sharedStyles}
    height: auto;
    padding: 6px 12px;
    border-radius: 4px;
    > p {
        margin-bottom: 0 !important;
    }
`;

const modalAnimation = keyframes`
    from {
        top: -300px;
    }
    to {
        top: 0;
    }
`;

const Modal = styled.div`
    ${({ show }) =>
        show &&
        `
          display: block !important;
   `}

    ${({ lightTheme }) =>
        lightTheme &&
        `
     .modal-body {
        background: #fff;
        color: #21212d;
    }
    .modal-content {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.32);
        border-radius: 0px 0px 8px 8px;
        overflow: hidden;
    }
   
   `}


   &.gModal {
        z-index: 9999;
        h2 {
            font-size: 16px;
            // these are by default but the bootstrap ones are overwritings these
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
        p {
            font-size: $font-size-sm;
            // this is by default but the bootstrap ones are overwritings these
            margin-bottom: 1rem;
        }
        .action-btns {
            display: flex;
            justify-content: space-between;

            .btn {
                font-size: $font-size-base;
                font-weight: bold;
                &:focus {
                    outline: 0;
                    box-shadow: none;
                }
            }
        }

        .btn-light-danger {
            color: #f34444;
            background-color: rgba(243, 68, 68, 0.04);
            max-width: 150px;
            margin-right: 1em;
            &:hover {
                background: #e9e9ed;
            }
        }
        .custom-btn-style {
            .btn {
                background-color: #21212d;
                color: #ffd603;
                padding: 0px 12px;
                min-width: 180px;
            }
        }
        &.for-warning {
            .modal-body {
                border-top: 12px solid #ffc34c;
            }
        }
        &.danger {
            .modal-body {
                border-top: 12px solid #ff5b4c;
            }
        }
        &.warning {
            .modal-body {
                border-top: 12px solid #ffc34c;
            }
        }
        &.success {
            .modal-body {
                border-top: 12px solid #4cff97;
            }
        }
        &.custom-shift {
            .action-btns {
                justify-content: flex-end;
            }
        }
        &.primary {
            .modal-body {
                border-top: 12px solid #30abe8;
            }
        }
        &.show {
            animation: ${modalAnimation} 0.3s linear;
        }
        .modal-content {
            border: 0;
        }

        @media only screen and (min-width: 576px) {
            .modal-dialog {
                max-width: 425px;
                margin: 0 auto;
            }
        }
    }
`;
