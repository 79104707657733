import { UnstyledButton } from 'components/molecules/utils/basicComponents';
import SVGIcon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text';
import React from 'react';
import styled from 'styled-components';

export const CharLimit = ({ value, maxLength }) => {
    return (
        <Count variant='caption' color='text.default.secondary'>
            {value !== null ? value?.length || 0 : 0}/{maxLength}
        </Count>
    );
};

export const InputIcon = ({ onClear, value, rightIcon, size }) => {
    const search = <RightIcon icon={rightIcon} sz={size} />;
    if (rightIcon && (!onClear || value === null || value?.length === 0)) {
        return search;
    }
    return null;
};

export const ClearIcon = ({ onClear, value, size }) => {
    return onClear && value?.length > 0 ? (
        <ClearWrapper onClick={onClear} sz={size} aria-label='clear input'>
            <Clear icon='dismiss' sz={size} />
        </ClearWrapper>
    ) : null;
};

export const rightPadding = (rightIcon, onClear, value) => {
    if (
        (rightIcon && onClear && (value === null || value?.length === 0)) ||
        (rightIcon && !onClear) ||
        (!rightIcon && onClear && value?.length > 0)
    ) {
        return true;
    }
};

const Count = styled(Text)`
    margin-top: ${({ theme }) => theme.space.x1};
    text-align: right;
    margin-left: auto;
`;

const Icon = styled(SVGIcon)`
    fill: ${({ theme }) => theme.colors.text.default.secondary};
    position: absolute;
    right: 16px;
    top: 11px;
    ${({ sz }) =>
        sz === 'small' &&
        `
        right: 10px;
        top: 6px;
    `}
`;

const RightIcon = styled(Icon)`
    height: 18px;
    width: 18px;
    ${({ sz }) =>
        sz === 'small' &&
        `
        height: 16px;
        width: 16px;
        top: 8px;
    `}
`;
const Clear = styled(Icon)`
    top: 13px;
    height: 15px;
    width: 15px;
    cursor: pointer;
    ${({ sz }) =>
        sz === 'small' &&
        `
        right: 10px;
        top: 8px;
        height: 14px;
        width: 14px;
    `}
`;

const ClearWrapper = styled(UnstyledButton)`
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    width: 40px;
    ${({ sz }) =>
        sz === 'small' &&
        `
        height: 28px;
    `}
`;
