import Prompt from 'components/general/Prompt';
import React, { useEffect } from 'react';
import PermissionUtils from 'utils/permission-utils';

function LiveEventPrompt({
    heading,
    message,
    resolveText,
    rejectText,
    onClose,
    onClick,
    rejectEnable = true,
    resolveEnable = true,
    classType = 'for-warning',
    timer = null,
}) {
    useEffect(() => {
        if (timer) {
            let timeout = setTimeout(() => {
                onClose();
            }, timer);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, []);
    return PermissionUtils.isEventCloudHost() ? null : (
        <Prompt
            show={true}
            heading={heading}
            message={message}
            onYes={onClick}
            onNo={onClose}
            classType={classType}
            rejectEnable={rejectEnable}
            resolveEnable={resolveEnable}
            ariaLabel={{
                yesButtonLabel: resolveText,
                noButtonLabel: rejectText,
            }}
        />
    );
}

export default LiveEventPrompt;
