import CcPrimaryLangSelect from 'components/community/editPublicAirmeet/ClosedCaptions/CcPrimaryLangSelect';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text';
import useClosedCaptionsSettings from 'hooks/closed-captions/useClosedCaptionsSettings';
import useTheme from 'hooks/useTheme';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { rgbaAlpha } from 'utils/colors';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';
import { getClosedCaptionsSelectStyles } from 'components/community/editPublicAirmeet/ClosedCaptions';
import { getCCLanguageOptions } from 'components/community/editPublicAirmeet/ClosedCaptions/constants';

export default function PrimaryLangSelect({
    liveConfig,
    onChange,
    setPromptData,
}) {
    const { t } = useCustomTranslation();
    const { theme } = useTheme();

    const {
        serviceProvider,
        defaultPrimaryLanguage,
    } = useClosedCaptionsSettings();

    const CC_LANGUAGE_OPTIONS = useMemo(() => {
        return getCCLanguageOptions(serviceProvider);
    }, [serviceProvider]);

    const selectStyles = getClosedCaptionsSelectStyles(theme);

    const handleConfirm = (option) => {
        const newLiveConfig = {
            ...(liveConfig || {}),
            ccPrimaryLang: option,
        };
        onChange('live_config', newLiveConfig);
        setPromptData(null);
    };

    const handleChange = (option) => {
        setPromptData({
            title: t(keys.CLOSED_CAPTION_LANG_CHANGE_MODAL_TITLE),
            description: t(keys.CLOSED_CAPTION_LANG_CHANGE_MODAL_MESSAGE),
            resolveText: t(keys.GENERAL_CONFIRM),
            onResolve: () => handleConfirm(option),
            body: (
                <WarningBox>
                    <StyledSvgSprite
                        icon='ic-info-circle-o'
                        fill='sem.warning'
                        height='1.25rem'
                        width='1.25rem'
                    />
                    <Text
                        variant='caption'
                        color='text.default.secondary'
                        pl='x1'
                    >
                        {t(keys.CLOSED_CAPTION_LANG_CHANGE_MODAL_DESCRIPTION)}
                    </Text>
                </WarningBox>
            ),
        });
    };

    return (
        <CcPrimaryLangSelect
            options={CC_LANGUAGE_OPTIONS}
            selectStyles={selectStyles}
            isLoading={false}
            style={style}
            onChange={handleChange}
            ccPrimaryLang={defaultPrimaryLanguage}
        />
    );
}

const style = { paddingTop: '0px', paddingBottom: '1rem' };

const WarningBox = styled(FlexDiv)`
    margin-top: ${({ theme }) => theme.space.x5};
    padding: ${({ theme }) => theme.space.x3};
    border-radius: ${({ theme }) => theme.radii.x2};
    border: solid 1px ${({ theme }) => theme.colors.border.default1};
    background-color: ${({ theme }) =>
        rgbaAlpha(theme.colors.sem.warning, 0.2)};
`;
