import * as ScrollArea from '@radix-ui/react-scroll-area';
import React from 'react';
import styled from 'styled-components';

interface ScrollProps {
    type?: string;
    scrollWidth?: string;
    orientation?: string;
    scrollXY?: boolean;
    hideScrollCorner?: boolean;
    pr?: boolean;
    pb?: boolean;
    viewPort?: boolean;
}

const ScrollSection: React.FC<ScrollProps> = ({
    type = 'auto',
    scrollWidth = '8px',
    orientation = 'vertical',
    scrollXY = false,
    pr = false,
    pb = false,
    hideScrollCorner = true,
    viewPort = true,
    children,
}) => (
    <ScrollWrapper
        type={type}
        pr={pr}
        pb={pb}
        scrollWidth={scrollWidth}
        className='ScrollWrapper'
    >
        {viewPort ? (
            <ScrollViewport className='ScrollViewport'>
                {children}
            </ScrollViewport>
        ) : (
            <>{children}</>
        )}

        {(orientation === 'horizontal' || scrollXY) && (
            <ScrollScrollbar
                className='ScrollScrollbar'
                scrollWidth={scrollWidth}
                orientation={'horizontal'}
            >
                <ScrollThumb className='ScrollThumb' />
            </ScrollScrollbar>
        )}
        {(orientation === 'vertical' || scrollXY) && (
            <ScrollScrollbar
                className='ScrollScrollbar'
                scrollWidth={scrollWidth}
                orientation={'vertical'}
            >
                <ScrollThumb className='ScrollThumb' />
            </ScrollScrollbar>
        )}
        <ScrollCorner
            className='ScrollCorner'
            hideScrollCorner={hideScrollCorner}
        />
    </ScrollWrapper>
);

export default ScrollSection;

const ScrollWrapper = styled(ScrollArea.Root)`
    width: 100%;
    height: 100%;
    overflow: hidden;
    --scrollbar-size: ${({ theme }) => theme.space.x3};
    ${({ pr, scrollWidth }) =>
        pr ? `padding-right: calc(${scrollWidth} + 4px);` : ''}
    ${({ pb, scrollWidth }) =>
        pb ? `padding-bottom: calc(${scrollWidth} + 4px);` : ''}
`;

export const ScrollViewport = styled(ScrollArea.Viewport)`
    width: 100%;
    height: 100%;
    border-radius: inherit;
`;

const ScrollScrollbar = styled(ScrollArea.Scrollbar)`
    display: flex;
    /* ensures no selection */
    user-select: none;
    /* disable browser handling of all panning and zooming gestures on touch devices */
    touch-action: none;
    padding: 0;
    background-color: transparent;
    transition: background-color 160ms ease-out, width 160ms ease-out;

    &[data-orientation='vertical'] {
        width: ${({ scrollWidth }) => scrollWidth};
        &:hover {
            width: ${({ scrollWidth }) => `calc(${scrollWidth} + 4px)`};
        }
    }
    &[data-orientation='horizontal'] {
        flex-direction: column;
        height: ${({ scrollWidth }) => scrollWidth};
        &:hover {
            height: ${({ scrollWidth }) => `calc(${scrollWidth} + 4px)`};
        }
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.fill.default3};
        border-left: 1px solid ${({ theme }) => theme.colors.border.default1};
        &[data-orientation='vertical'] {
            width: ${({ theme }) => `calc(${theme.space.x3} - 1px)`};
        }
    }
`;

const ScrollThumb = styled(ScrollArea.Thumb)`
    flex: 1;
    background: ${({ theme }) => theme.colors.fill.other2};
    border-radius: ${({ theme }) => theme.radii.default};
    position: relative;
`;

const ScrollCorner = styled(ScrollArea.Corner)`
    background: ${({ theme, hideScrollCorner }) =>
        hideScrollCorner ? 'transparent' : theme.colors.fill.other2};
`;
