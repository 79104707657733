export const MEETUP_MODE = 'MEETUP_MODE';
export const CONFERENCE_MODE = 'CONFERENCE_MODE';
export const MEETUP = 'MEETUP';
export const CONFERENCE = 'CONFERENCE';

export const ADD_SPEAKER_MODE = 'ADD_SPEAKER_MODE';
export const SPEAKER_LIST_MODE = 'SPEAKER_LIST_MODE';

export const ADD_SESSION_MODE = 'ADD_SESSION_MODE';
export const SESSION_LIST_MODE = 'SESSION_LIST_MODE';

export const UPDATE_HOST_MODE = 'UPDATE_HOST_MODE';
export const VIEW_HOST_MODE = 'VIEW_HOST_MODE';

export const TYPE_VIDEO = 'video';
export const TYPE_IMAGE = 'image';
export const TYPE_TEXT = 'text';

// TAB NAME CONSTANTS
export const BASIC_INFO_TAB = 'basic-info';
export const TIME_DATE_TAB = 'time-date';
export const HOST_DETAILS_TAB = 'host-detail';
export const ADD_SPEAKER_TAB = 'add-speaker';
export const ADD_SESSION_TAB = 'add-session';

// Create airmeet form initiation location
export const DASHBOARD_INITIATION = 'DASHBOARD_INITIATION';
export const NEW_ONBOARDING_INITIATION = 'NEW_ONBOARDING_INITIATION';

export const ATTENDANCE_TYPE = [
    {
        key: 'ONLINE',
        title: 'Virtually',
        tooltip: 'This is suited for completely online events.',
    },
    {
        key: 'HYBRID',
        title: 'Hybrid',
        tooltip:
            'This is suited for events with online & physical venues with QR scanning to allow attendees.',
    },
    {
        key: 'IN_PERSON',
        title: 'In-person',
        tooltip:
            'This is suited for creating online presence for physical events. ',
        disabled: true,
        info: 'Coming Soon',
    },
];

export const EVENT_CREATION_FORM_STEPS = {
    EVENT_TYPE_SELECTION: 'EVENT_TYPE_SELECTION',
    EVENT_TEMPLATE_SELECTION: 'EVENT_TEMPLATE_SELECTION',
    EVENT_FORM: 'EVENT_FORM',
};
