import Axios from 'axios';
import { Typography } from 'foundations/typography';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAirmeet } from 'store/selectors';
import styled, { createGlobalStyle } from 'styled-components';
import UAParser from 'ua-parser-js';
import { AIRMEET_STATUS } from 'utils/constants/airmeet';
import history from '../../history';
import { isSafari } from '../../utils/browserCheck';
import { cdnImage } from '../../utils/core';

const NEWLY_SUPPORTED_CHROME_VERSION = 79;
const parser = new UAParser();
const SAFARI_BROWSER_ISSUES =
    'https://help.airmeet.com/support/solutions/articles/82000515558-safari-browser-compatibility-and-known-technical-issue';

const isBrowserSupport = async () => {
    const res = await Axios.get(process.env.REACT_APP_SUPPORTED_BROWSER_LIST);
    const { supportedBrowserList = [] } = res.data;
    const browserName = parser.getBrowser();
    const major = +browserName.major;

    if (supportedBrowserList.length > 0) {
        for (const browser of supportedBrowserList) {
            if (
                browser.browser === browserName &&
                major > Number(browser.minVersion)
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
    return false;
};

const isDesktop = () => {
    const deviceType = parser.getDevice().type;
    return deviceType ? false : true; // deviceType is undefined for desktop (https://github.com/faisalman/ua-parser-js/issues/308)
};

export const getHasBrowserSupportMessage = () =>
    // getAirmeetUtilInstance() &&
    isDesktop() &&
    ['/event/', '/e/'].some(
        (pathSubstring) =>
            history.location.pathname.indexOf(pathSubstring) !== -1
    ) &&
    // !isBrowserSupport() && // commented this out since we are supporting application for all the browsers
    isSafari();

const messages = {
    safari: (
        <>
            Some features may not work or cause technical issues on Safari. We
            recommend using{' '}
            <img
                src={cdnImage(
                    '/images/Browser-not-supported/chrome-icon-on-banner.png'
                )}
                width={'10px'}
                height={'10px'}
                alt='Chrome'
            />{' '}
            Chrome browser v{NEWLY_SUPPORTED_CHROME_VERSION} or higher for a
            smoother experience{' '}
            <a
                target='_blank'
                rel='noopener noreferrer'
                href={SAFARI_BROWSER_ISSUES}
            >
                Learn more
            </a>
        </>
    ),
};

function BestExperienceBrowser() {
    const airmeet = useSelector(getAirmeet) || {};
    const status = airmeet?.status;

    const showAirmeetStatusBar = useMemo(
        () =>
            [
                AIRMEET_STATUS.CREATED,
                AIRMEET_STATUS.PAUSED,
                AIRMEET_STATUS.FINISHED,
            ].includes(status),
        [status]
    );

    return getHasBrowserSupportMessage() ? (
        <Container className='best-exp-info-web'>
            <Typography variant={'span'} color='ambience.0'>
                {messages.safari}
            </Typography>
            <BestExperienceOverride
                showAirmeetStatusBar={showAirmeetStatusBar}
            />
        </Container>
    ) : null;
}

export default BestExperienceBrowser;

const Container = styled.div`
    top: 0;
    width: 100%;
    a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: underline;
        font-family: ${({ theme }) => theme.fonts.bold};
    }
`;
const BestExperienceOverride = createGlobalStyle`
    .app-header{
        top: 24px !important;
    }
    .all-online .panel-video{
        height: calc(100vh - 104px) !important;
    }

    ${({ showAirmeetStatusBar }) =>
        showAirmeetStatusBar &&
        `.event-type-meetup.enabled_best_exp_info #root .app-header{
            top: 0px !important;
        }
        .event-type-meetup .all-online .panel-video{
            height: calc(100vh - 168px) !important;
        }`}

    #event-status-bar{
        top: 24px !important;
    }
`;
