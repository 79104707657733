export const RESOLUTIONS_HD_PLUS = 'HD+';
export const RESOLUTIONS_SD = 'SD';
export const RESOLUTIONS_1080P = '1080P';
export const DEFAULT_RESOLUTION = RESOLUTIONS_SD;
export const SUPPORTED_RESOLUTION_LIST = [
    RESOLUTIONS_SD,
    RESOLUTIONS_HD_PLUS,
    RESOLUTIONS_1080P,
];
export const SUPPORTED_RESOLUTION_ENUM = {
    [RESOLUTIONS_SD]: 0,
    [RESOLUTIONS_HD_PLUS]: 1,
    [RESOLUTIONS_1080P]: 2,
};
export const VIDEO_MUTE_PROFILE = { maxBitrate: 50, height: 90, width: 213 };
const list = {};
list[RESOLUTIONS_SD] = {
    videoProfile: '480p_8',
    screenProfile: '720p_2',
    screenConfig: {
        minBitrate: 2000,
        maxBitrate: 2000,
        height: 1080,
        width: 1920,
        frameRate: 30,
    },
    customMediaProfile: '720p_3',
    presentationProfile: '720p_1',
    customMediaConfig: {
        height: 720,
        minBitRate: 800,
        maxBitRate: 2000,
        minFrameRate: 15,
        maxFrameRate: 30,
    },
    layouts: {
        split: {
            1: { maxBitrate: 610, height: 480, width: 853 },
            2: { maxBitrate: 610, height: 480, width: 853 },
            3: { maxBitrate: 610, height: 480, width: 853 },
            4: { maxBitrate: 250, height: 240, width: 427 },
            5: { maxBitrate: 250, height: 240, width: 427 },
            6: { maxBitrate: 250, height: 240, width: 427 },
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        dominant: {
            // mainStream: '480p_8',
            mainStream: { maxBitrate: 610, height: 480, width: 853 },
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        screen: {
            mainStream: '720p_2',
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        lowDefinitionProfile: {
            mainStream: { maxBitrate: 250, height: 240, width: 427 },
            smallStream: { maxBitrate: 65, height: 120, width: 213 },
        },
        backstagePanelLowProfile: { maxBitrate: 65, height: 90, width: 160 },
    },
};

list[RESOLUTIONS_HD_PLUS] = {
    videoProfile: '720p_1',
    screenProfile: '720p_2',
    screenConfig: {
        minBitrate: 2000,
        maxBitrate: 2000,
        height: 1080,
        width: 1920,
        frameRate: 30,
    },
    customMediaProfile: '720p_3',
    customMediaConfig: {
        height: 720,
        minBitRate: 1000,
        maxBitRate: 3000,
        minFrameRate: 15,
        maxFrameRate: 30,
    },
    presentationProfile: '720p_1',
    layouts: {
        split: {
            1: { maxBitrate: 1130, height: 720, width: 1280 },
            2: { maxBitrate: 610, height: 480, width: 853 },
            3: { maxBitrate: 610, height: 480, width: 853 },
            4: { maxBitrate: 400, height: 360, width: 640 },
            5: { maxBitrate: 400, height: 360, width: 640 },
            6: { maxBitrate: 400, height: 360, width: 640 },
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        dominant: {
            mainStream: { maxBitrate: 1130, height: 720, width: 1280 }, //'480p_8',
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        screen: {
            mainStream: '720p_2',
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        lowDefinitionProfile: {
            mainStream: { maxBitrate: 250, height: 240, width: 427 },
            smallStream: { maxBitrate: 100, height: 120, width: 213 },
        },
        backstagePanelLowProfile: { maxBitrate: 100, height: 90, width: 160 },
    },
};

list[RESOLUTIONS_1080P] = {
    videoProfile: '1080p_1',
    screenProfile: '1080p_2',
    screenConfig: {
        minBitrate: 1000,
        maxBitrate: 3000,
        height: 1080,
        width: 1920,
        frameRate: 30,
    },
    customMediaProfile: '1080p_3',
    customMediaConfig: {
        height: 1080,
        minBitRate: 1000,
        maxBitRate: 4500,
        minFrameRate: 15,
        maxFrameRate: 30,
    },
    presentationProfile: '1080p_1',
    layouts: {
        split: {
            1: { maxBitrate: 1695, height: 1080, width: 1920 },
            2: { maxBitrate: 1130, height: 720, width: 1280 },
            3: { maxBitrate: 915, height: 540, width: 960 },
            4: { maxBitrate: 610, height: 480, width: 853 },
            5: { maxBitrate: 610, height: 480, width: 853 },
            6: { maxBitrate: 610, height: 480, width: 853 },
            7: { maxBitrate: 400, height: 360, width: 640 },
            8: { maxBitrate: 400, height: 360, width: 640 },
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        dominant: {
            mainStream: { maxBitrate: 1695, height: 1080, width: 1920 }, //'480p_8',
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        screen: {
            mainStream: '1080p_2',
            smallStream: { maxBitrate: 250, height: 240, width: 427 },
        },
        lowDefinitionProfile: {
            mainStream: { maxBitrate: 500, height: 480, width: 848 },
            smallStream: { maxBitrate: 100, height: 120, width: 213 },
        },
        backstagePanelLowProfile: { maxBitrate: 100, height: 90, width: 160 },
    },
};

export default list;
