import Filter from '@airmeet/filter';
import { AirmeetRTCClient } from '@airmeet/rtc-sdk';
import FirebaseProxyHelper from 'containers/FirebaseProxyHelper';
import resolutionsList, {
    RESOLUTIONS_1080P,
    RESOLUTIONS_HD_PLUS,
    RESOLUTIONS_SD,
} from 'containers/StageResolutionsList';
import { STREAM_PLAYER_PREFIX } from 'context/LiveStreams';
import { STAGE_VIEWS } from 'context/StageLayout';
import {
    isMusicModeEnabled,
    isNoiseSuppressionEnabled,
    onAudioModeSettingChanged,
} from 'hooks/useAudioModes';
import UAParser from 'ua-parser-js';
import {
    FILTER_TYPE,
    getBackgroundImage,
    isFilterSupported,
    printFilterSupportDetails,
} from 'utils/FilterManager';
import FilterStorageManager from 'utils/FilterStorageManager';
import Stage from 'utils/Stage';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import AuthService from 'utils/authService';
import { isSafari } from 'utils/browserCheck';
import { getChannelLiveStreamingUrl } from 'utils/common';
import { getCCPrimaryLangVendorValue } from 'components/community/editPublicAirmeet/ClosedCaptions/constants';

import {
    RTC_ROLES,
    RTC_SOURCE_MODULES,
    SDK_CODES,
    SDK_EVENTS,
    STAGE_STREAM_MODES,
    VENDOR_AGORA,
    conferenceModes,
    emptyObject,
    mediaTypes,
    pinTypes,
    streamOptimizationMode,
    ccModules,
    videoCodecs,
} from 'utils/constants/common';
import { Events, RtmClientMessages } from 'utils/constants/containers/airmeet';
import {
    DEVICE_TYPE_CAMERA,
    DEVICE_TYPE_MIC,
    PREF_AUDIO_IN,
    PREF_AUDIO_OUT,
    PREF_VIDEO_IN,
} from 'utils/constants/deviceSettings';
import {
    PDF_SHARE_USER_ID_START_FROM,
    createCustomMediaUserId,
    createScreenShareUserId,
    getCustomMediaUserId,
    getScreenShareUserId,
    isCustomMediaStream,
    isScreenShareStream,
} from 'utils/constants/live-airmeet';
import {
    QOELogger,
    broadcastLogConstants,
} from 'utils/constants/qoeLogConstants';
import { logger } from 'utils/logger';
import { rtcSDKConfig } from 'utils/rtcSdkEnv.ts';
import { v4 as uuid } from 'uuid';

const STAGE_PREFIX = 'V2';

const VIDEO_SHARE_MAX_FRAMERATE = 15;
const TEXT_CONTENT_SHARE_FRAMERATE = 5;
const MIN_BITRATE_COEFFECIENT = 0.6;
// User device information
const parser = new UAParser();
const {
    browser: { name: browserName } = {},
    device: { type: deviceType } = {},
} = parser.getResult();

const isSafariBrowser =
    deviceType === 'tablet' ? browserName === 'Safari' : isSafari();
export default class StageSdkService extends Stage {
    constructor(params) {
        super(params);
        this.streams = {};
        this.remoteStreamAudioMute = false;
        this.activeSpeaker = 0;
        this.activeSpeakers = [];
        this.isChannelOnNetwork = false;
        this.initStageRTCBroadcast(params.authUser.id);
        this.maxActiveSpeakers = 4;
        this.watchMode = 'highResolution';
        this.setDefaultAudioMute = params.setDefaultAudioMute;
        this.setDefaultVideoMute = params.setDefaultVideoMute;
        this.getDefaultAudioMute = params.getDefaultAudioMute;
        this.getDefaultVideoMute = params.getDefaultVideoMute;
    }

    setStreamProps(streamId, assigningProps) {
        if (this.streams[streamId] === undefined) {
            this.streams[streamId] = assigningProps;
        } else {
            Object.assign(this.streams[streamId], assigningProps);
        }
    }

    setDefaultAVState(props = {}) {
        const {
            defaultAudioMute = this.getDefaultAudioMute(),
            defaultVideoMute = this.getDefaultVideoMute(),
        } = props;
        this.log(`${STAGE_PREFIX} - setting up default audio/video states`, {
            to: props,
            from: { defaultAudioMute, defaultVideoMute },
        });
        this.setDefaultAudioMute(defaultAudioMute);
        this.setDefaultVideoMute(defaultVideoMute);
    }

    createPDFShareUserId(uid) {
        return parseInt(uid, 10) + PDF_SHARE_USER_ID_START_FROM;
    }

    initNewFilterClient(loggerInstance) {
        const filterClient = new Filter({
            logger: loggerInstance,
        });
        try {
            filterClient.init(
                rtcSDKConfig.rtcSdkAssetsBaseUrl,
                'stagesdkservice.js'
            );
        } catch (err) {}

        return filterClient;
    }

    getFilterParams() {
        const filterStorage = FilterStorageManager.getInstance();
        const isSupported = isFilterSupported();
        if (!isSupported) return null;

        const cachedFilterParams = filterStorage.getFilterInfo();
        try {
            if (cachedFilterParams) {
                const newFilterParams = JSON.parse(cachedFilterParams);
                if (newFilterParams.details?.pathToImage) {
                    newFilterParams.pathToImage = getBackgroundImage(
                        newFilterParams.details.pathToImage
                    );
                }
                delete newFilterParams.details;
                return newFilterParams;
            }
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - Error occured while parsing filter params from localStorage to be passed to joinConference`
            );
        }
        return null;
    }

    async changeFilter(filterInfo) {
        if (!this.stageRTCBroadcast) {
            return;
        }
        const isSupported = isFilterSupported();
        if (!isSupported) return false;

        printFilterSupportDetails();

        if (!filterInfo || filterInfo.type === FILTER_TYPE.none) {
            await this.stageRTCBroadcast.removeFilter();
        } else {
            let type =
                filterInfo.type === FILTER_TYPE.blur
                    ? FILTER_TYPE.blur
                    : FILTER_TYPE.bg;
            let pathToImage = filterInfo.details
                ? filterInfo.details.pathToImage
                : '';

            pathToImage = getBackgroundImage(pathToImage);

            await this.stageRTCBroadcast.applyFilter(type, pathToImage);
        }
    }

    validateChannel(confId, eventName) {
        if (confId && this.channelName && confId !== this.channelName) {
            this.log(
                `${STAGE_PREFIX} - Skip the above event, since it's not related to joined channel`,
                { confId, channelName: this.channelName, eventName }
            );
            return false;
        }
        return true;
    }

    initStageRTCBroadcast(userId) {
        const authToken = AuthService.token || userId;
        const isProdOrPreprod =
            process.env.REACT_APP_RTC_SDK_ENV === 'production' ||
            process.env.REACT_APP_RTC_SDK_ENV === 'preprod';

        this.stageRTCBroadcast = new AirmeetRTCClient({
            airmeetClientId: this.airmeetId,
            airmeetAuthToken: authToken,
            proxyConfig: {
                useVendorProxy: this.connectViaProxy,
                useAirmeetProxy: this.useAirmeetProxy,
                useAirmeetTURN: this.useAirmeetTURNServer,
            },
            firebaseProxyHelper: FirebaseProxyHelper.getFirebaseProxyHelperInstance(
                'SDK'
            ),
            environmentConfig: rtcSDKConfig,
            upstreamAuthUrl: !isProdOrPreprod
                ? process.env.REACT_APP_API_BASE_URL
                : null,
            isAVDForClosedCaptionEnabled: false,
        });
        // init and register filter client
        const filterClient = this.initNewFilterClient(
            this.stageRTCBroadcast.getLogger()
        );
        this.stageRTCBroadcast.registerModule('filter', filterClient);
        if (process.env.REACT_APP_RTC_SDK_ENV === 'production') {
            this.stageRTCBroadcast.setLogLevel(4);
        } else {
            this.stageRTCBroadcast.setLogLevel(0);
        }
        // bind events
        this.stageRTCBroadcast.on(SDK_EVENTS.USER_JOINED, (user) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - USER_JOINED`,
                user
            );
            if (
                user.isScreenUser ||
                user.userType !== 'user' ||
                !this.validateChannel(user.confId, SDK_EVENTS.USER_JOINED)
            ) {
                return;
            }
            let streamId = user.userId;
            const stream = {
                getId: () => user.userId,
                stream: true,
                isAudioOn: () => false,
                isVideoOn: () => false,
                mediaStatus: 'camera',
                confId: user.confId,
            };
            this.streams[streamId] = stream;
            this.emit(Events.rtcLiveRemoteStreamAdded, {
                id: streamId,
                userType: user.userType,
            });
        });
        this.stageRTCBroadcast.on(SDK_EVENTS.USER_LEFT, (user) => {
            // remove stream state here
            this.log(`${STAGE_PREFIX} - Received SDK event - USER_LEFT`, user);
            if (!this.validateChannel(user.confId, SDK_EVENTS.USER_LEFT)) {
                return;
            }
            if (user.userType === 'user') {
                this.removeStream(user.userId);
                this.emit(Events.rtcLiveStreamRemoved, {
                    id: { id: user.userId, userType: user.userType },
                });
            }
            if (user.userType === mediaTypes.SCREEN) {
                this.cleanupRemoteScreenShare(user);

                // [sc-204830]
                // this is to handle the case where screen is not visible locally but visible on remote end.
                // It happens when user goes offline and we do screenshare cleanup due to `user-left` event
                // and when user comes to network again, remote users will receive the REMOTE_SCREEN_PUBLISHED event but local user will not receive this
                // and due to that screen will not display locally but remote users will see it, so we end the screenshare when user-left received
                parseInt(user.userId) === this.authUser.id_seq &&
                    this.endScreenShare();
            }
            if (
                user.userType === mediaTypes.CUSTOM_PDF ||
                user.userType === mediaTypes.CUSTOM_VIDEO
            ) {
                // [sc-204830]
                // this is to handle the case where screen is not visible locally but visible on remote end.
                // It happens when user goes offline and we do screenshare cleanup due to `user-left` event
                // and when user comes to network again, remote users will receive the REMOTE_SCREEN_PUBLISHED event but local user will not receive this
                // and due to that screen will not display locally but remote users will see it, so we end the screenshare when user-left received
                user.userType === mediaTypes.CUSTOM_PDF &&
                    parseInt(user.userId) === this.authUser.id_seq &&
                    this.endScreenShare();

                this.cleanupRemotePdfOrVideo({
                    userId: user.userId,
                    customMediaType: user.userType,
                });
            }
        });
        this.stageRTCBroadcast.on(SDK_EVENTS.SELECTED_DEVICE_CHANGE, (data) => {
            const {
                currentAudioInputDeviceId,
                currentVideoInputDeviceId,
                currentAudioOutputDeviceId,
            } = data;
            if (currentAudioInputDeviceId)
                localStorage.setItem(PREF_AUDIO_IN, currentAudioInputDeviceId);
            if (currentVideoInputDeviceId)
                localStorage.setItem(PREF_VIDEO_IN, currentVideoInputDeviceId);
            if (currentAudioOutputDeviceId)
                localStorage.setItem(
                    PREF_AUDIO_OUT,
                    currentAudioOutputDeviceId
                );
        });
        this.stageRTCBroadcast.on(SDK_EVENTS.REMOTE_AUDIO_PUBLISHED, (user) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - REMOTE_AUDIO_PUBLISHED`,
                user
            );
            if (
                !this.validateChannel(
                    user.confId,
                    SDK_EVENTS.REMOTE_AUDIO_PUBLISHED
                )
            )
                return;
            this.setStreamProps(user.userId, {
                ...this.streams[user.userId],
                isAudioOn: () => true,
            });
            this.emit(Events.onUnmuteAudio, {
                uid: user.userId,
            });
        });
        this.stageRTCBroadcast.on(SDK_EVENTS.REMOTE_VIDEO_PUBLISHED, (user) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - REMOTE_VIDEO_PUBLISHED`,
                user
            );
            if (
                !this.validateChannel(
                    user.confId,
                    SDK_EVENTS.REMOTE_VIDEO_PUBLISHED
                )
            )
                return;
            const { userId } = user;
            this.setStreamProps(userId, {
                ...this.streams[userId],
                isVideoOn: () => true,
            });
            this.emit(Events.onUnmuteVideo, {
                uid: userId,
            });
        });
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_AUDIO_UNPUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_AUDIO_UNPUBLISHED`,
                    user
                );
                if (
                    !this.validateChannel(
                        user.confId,
                        SDK_EVENTS.REMOTE_AUDIO_UNPUBLISHED
                    )
                )
                    return;
                this.streams[user.userId].isAudioOn = () => false;
                this.emit(Events.onMuteAudio, {
                    uid: user.userId,
                });
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_VIDEO_UNPUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_VIDEO_UNPUBLISHED`,
                    user
                );
                if (
                    !this.validateChannel(
                        user.confId,
                        SDK_EVENTS.REMOTE_VIDEO_UNPUBLISHED
                    )
                )
                    return;
                this.streams[user.userId].isVideoOn = () => false;
                this.emit(Events.onMuteVideo, {
                    uid: user.userId,
                });
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.NETWORK_QUALITY_CHANGED,
            (quality) => {
                this.emit(Events.rtcLiveNetwokrQualityUpdated, {
                    uid: this.authUser.id_seq,
                    quality,
                });
            }
        );
        this.stageRTCBroadcast.on(SDK_EVENTS.NEW_DOMINANT_SPEAKER, (user) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - NEW_DOMINANT_SPEAKER`,
                user
            );
            this.activeSpeaker = user.userId;
            this.onActiveSpeaker(this.activeSpeaker);

            this.emit(Events.activeSpeaker, { id: this.activeSpeaker });
        });
        this.stageRTCBroadcast.on(SDK_EVENTS.USER_ACTIVE, (user) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - USER_ACTIVE`,
                user
            );
            const activeSpeaker = user.userId;
            this.onUserActive(activeSpeaker);
            this.emit(Events.newActiveSpeaker, { id: activeSpeaker });
        });
        this.stageRTCBroadcast.on(SDK_EVENTS.USER_INACTIVE, (user) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - USER_INACTIVE`,
                user
            );
            const { userId } = user;
            this.emit(Events.inactiveSpeaker, { id: userId });
        });
        this.stageRTCBroadcast.on(SDK_EVENTS.CAPTION_RECEIVED, (data) => {
            this.emit(SDK_EVENTS.CAPTION_RECEIVED, {
                message: data.transcript,
                user: data.id, // id contains the user object {name, type, userId}
            });
        });
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_SCREEN_PUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_SCREEN_PUBLISHED`,
                    user
                );
                if (
                    !this.validateChannel(
                        user.confId,
                        SDK_EVENTS.REMOTE_SCREEN_PUBLISHED
                    )
                )
                    return;
                const userId = user.userId;
                const streamId = createScreenShareUserId(userId);
                const streamObj = {
                    getId: () => streamId,
                    stream: true,
                    isAudioOn: () => false,
                    isVideoOn: () => true,
                    hasVideo: () => true,
                    mediaStatus: mediaTypes.SCREEN,
                    ...this.streams[streamId],
                    confId: user.confId,
                };
                this.setStreamProps(streamId, streamObj);
                this.emit(Events.rtcLiveRemoteStreamAdded, {
                    id: streamId,
                });

                this.emit(Events.onUnmuteVideo, {
                    uid: streamId,
                });

                this.emit(Events.streamAddedScreen, {
                    stream: this.streams[streamId],
                    id: streamId,
                    userId,
                });
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_SCREEN_AUDIO_PUBLISHED,
            ({ userId, confId }) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_SCREEN_AUDIO_PUBLISHED`,
                    userId
                );
                if (
                    !this.validateChannel(
                        confId,
                        SDK_EVENTS.REMOTE_SCREEN_AUDIO_PUBLISHED
                    )
                )
                    return;
                const streamId = createScreenShareUserId(userId);
                this.setStreamProps(streamId, {
                    ...this.streams[streamId],
                    getId: () => streamId,
                    isAudioOn: () => true,
                    isVideoOn: () => true,
                    stream: true,
                    mediaStatus: mediaTypes.SCREEN,
                    confId,
                });
                this.emit(Events.onUnmuteAudio, {
                    uid: streamId,
                });
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_SCREEN_UNPUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_SCREEN_UNPUBLISHED`,
                    user
                );

                if (
                    !this.validateChannel(
                        user.confId,
                        SDK_EVENTS.REMOTE_SCREEN_UNPUBLISHED
                    )
                )
                    return;
                this.cleanupRemoteScreenShare(user);
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.SCREEN_SHARE_STREAM_STOPPED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - SCREEN_SHARE_STREAM_STOPPED`,
                    user
                );
                if (
                    !this.validateChannel(
                        user.confId,
                        SDK_EVENTS.SCREEN_SHARE_STREAM_STOPPED
                    )
                )
                    return;
                this.localScreenStream = null;
                this.cleanupScreenShare('screen');
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_CUSTOM_VIDEO_PUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_CUSTOM_VIDEO_PUBLISHED`,
                    user
                );
                if (
                    !this.validateChannel(
                        user.confId,
                        SDK_EVENTS.REMOTE_CUSTOM_VIDEO_PUBLISHED
                    )
                )
                    return;
                const userId = user.userId;
                const mediaStatus = user.customMediaType;
                const streamId =
                    mediaStatus === mediaTypes.CUSTOM_VIDEO
                        ? createCustomMediaUserId(userId)
                        : this.createPDFShareUserId(userId);
                const streamObj = {
                    getId: () => streamId,
                    stream: true,
                    isVideoOn: () => true,
                    isAudioOn: () => false,
                    hasVideo: () => true,
                    mediaStatus,
                    ...this.streams[streamId],
                    confId: user.confId,
                };
                this.setStreamProps(streamId, streamObj);
                this.emit(Events.rtcLiveRemoteStreamAdded, {
                    id: streamId,
                });
                if (mediaStatus === mediaTypes.CUSTOM_VIDEO) {
                    this.emit(Events.streamAddedCustomMedia, {
                        stream: this.streams[streamId],
                        isOwner: false,
                        userId,
                    });
                }
                this.emit(Events.onUnmuteVideo, {
                    uid: streamId,
                });
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_CUSTOM_AUDIO_PUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_CUSTOM_AUDIO_PUBLISHED`,
                    user
                );
                if (
                    !this.validateChannel(
                        user.confId,
                        SDK_EVENTS.REMOTE_CUSTOM_AUDIO_PUBLISHED
                    )
                )
                    return;
                const streamId = createCustomMediaUserId(user.userId);
                this.setStreamProps(streamId, {
                    ...this.streams[streamId],
                    getId: () => streamId,
                    stream: true,
                    isAudioOn: () => true,
                    isVideoOn: () => true,
                    confId: user.confId,
                });
                this.emit(Events.onUnmuteAudio, {
                    uid: streamId,
                });
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_VIDEO_UNMUTED_LOCALLY,
            (user) => {
                if (
                    !this.validateChannel(
                        user.confId,
                        SDK_EVENTS.REMOTE_VIDEO_UNMUTED_LOCALLY
                    )
                )
                    return;
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_VIDEO_UNMUTED_LOCALLY`,
                    user
                );
                this.emit(Events.onUnmuteVideo, {
                    uid: user.userId,
                });
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_CUSTOM_VIDEO_UNPUBLISHED,
            (user) => {
                const { userId, customMediaType, confId } = user;
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_CUSTOM_VIDEO_UNPUBLISHED`,
                    { userId, customMediaType }
                );

                if (
                    !this.validateChannel(
                        confId,
                        SDK_EVENTS.REMOTE_CUSTOM_VIDEO_UNPUBLISHED
                    )
                )
                    return;
                this.cleanupRemotePdfOrVideo(user);
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_CUSTOM_AUDIO_UNPUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_CUSTOM_AUDIO_UNPUBLISHED`,
                    user
                );
            }
        );
        this.stageRTCBroadcast.on(SDK_EVENTS.USER_RECONNECT_START, (user) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - USER_RECONNECT_START`,
                user
            );
        });
        this.stageRTCBroadcast.on(SDK_EVENTS.USER_RECONNECT_FINISH, (user) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - USER_RECONNECT_FINISH`,
                user
            );
        });
        this.stageRTCBroadcast.on(
            SDK_EVENTS.REMOTE_USER_AUDIO_VIDEO_STATUS,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - REMOTE_USER_AUDIO_VIDEO_STATUS`,
                    user
                );
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.VOLUME_INDICATOR_CHANGED,
            (data) => {
                const speakingUser = data.find(
                    (obj) =>
                        obj.isSpeaking && obj.userId !== this.authUser.id_seq
                );
                this.emit(Events.conferenceVolumeIndicator, {
                    isSpeaking: !!speakingUser,
                });
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.LOCAL_AUDIO_TRACK_UNPUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - LOCAL_AUDIO_TRACK_UNPUBLISHED`,
                    user
                );
            }
        );
        this.stageRTCBroadcast.on(
            SDK_EVENTS.LOCAL_VIDEO_TRACK_UNPUBLISHED,
            (user) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - LOCAL_VIDEO_TRACK_UNPUBLISHED`,
                    user
                );
            }
        );
        this.stageRTCBroadcast.on(SDK_EVENTS.STREAM_AUTOPLAY_FAILED, (data) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - STREAM_AUTOPLAY_FAILED`,
                data
            );
            this.emit(Events.rtcBroadCastStreamNotAllowedAutoPlay);
        });
        this.stageRTCBroadcast.on(
            SDK_EVENTS.CONNECTION_STATE_CHANGE,
            (connectionStats) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - CONNECTION_STATE_CHANGE`,
                    connectionStats
                );
                if (connectionStats.currState === 'CONNECTED') {
                    this.isChannelOnNetwork = true;
                } else {
                    this.isChannelOnNetwork = false;
                }
                this.emit('connection-state-change', connectionStats);
            }
        );
        this.stageRTCBroadcast.on(SDK_EVENTS.ERROR, (user) => {
            this.log(`${STAGE_PREFIX} - Received SDK event - ERROR`, user);
        });
        this.stageRTCBroadcast.on(
            SDK_EVENTS.USER_SWITCH_CHANNEL_STATE_UPDATED,
            (data) => {
                // state 0 = new channel joined
                // state 1 = fully switched with audio/video transfer
                if (data.state === 0) {
                    this.channelName = data.confId;
                    this.channelJoined = true;
                    this.cleanupRemoteStreams();
                }
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - USER_SWITCH_CHANNEL_STATE_UPDATED`,
                    data
                );

                // update the local video/audio states to handle the cases where mute/unmute a/v api trigged with switch channel and if a/v failed to update
                if (data?.currMediaStates && this.hasUserStreamPublished()) {
                    const {
                        isAudioEnable,
                        isVideoEnable,
                    } = data?.currMediaStates;
                    const isLocalAudioEnable = this.localStream?.isAudioOn();
                    const isLocalVideoEnable = this.localStream?.isVideoOn();
                    const uid = this.localStream.getId();
                    if (isAudioEnable !== isLocalAudioEnable) {
                        this.processAudioEvents({
                            isAudioOn: isAudioEnable,
                            uid,
                        });
                        this.log(
                            'Setting the audio control state as per the switch',
                            { isAudioEnable }
                        );
                    }
                    if (isVideoEnable !== isLocalVideoEnable) {
                        this.processVideoEvents({
                            isVideoOn: isVideoEnable,
                            uid,
                        });
                        this.log(
                            'Setting the video control state as per the switch',
                            { isVideoEnable }
                        );
                    }
                }
            }
        );

        this.stageRTCBroadcast.on(SDK_EVENTS.CAM_MIC_DEVICE_BLOCKED, (data) => {
            this.log(
                `${STAGE_PREFIX} - Received SDK event - CAM_MIC_DEVICE_BLOCKED`,
                data
            );
            this.emit(Events.permissionsRevoked, data);
        });

        this.stageRTCBroadcast.on(
            SDK_EVENTS.CAM_MIC_PERMISSION_DENIED,
            (data) => {
                this.log(
                    `${STAGE_PREFIX} - Received SDK event - CAM_MIC_PERMISSION_DENIED`,
                    data
                );
                this.emit(Events.permissionsRevoked, data);
            }
        );
    }

    onActiveSpeakerRemove(activeSpeaker) {
        const inactiveUserIndex = this.activeSpeakers.indexOf(activeSpeaker);
        if (inactiveUserIndex === -1) {
            return;
        }
        // remove inactive user
        this.activeSpeakers.splice(inactiveUserIndex, 1);
    }

    onActiveSpeaker(activeSpeaker) {
        this.onActiveSpeakerRemove(activeSpeaker);
        this.activeSpeakers.unshift(activeSpeaker);
    }

    onUserActive(activeUser) {
        const activeSpeakerIndex = this.activeSpeakers.indexOf(activeUser);
        if (activeSpeakerIndex > -1) {
            return;
        }
        this.activeSpeakers.push(activeUser);
    }

    setResolution(resolution) {
        this.resolution = resolution || RESOLUTIONS_SD;
        this.resolutionsInfo = resolutionsList[resolution];
        const { layout, noOfStreams, mainStreamUids, stageView } =
            this.videoProfileParams || {};
        this.updateUserPublishVideoProfile(
            layout,
            noOfStreams,
            mainStreamUids,
            stageView
        );
        this.updateCustomMediaVideoProfile();
    }

    updateSourceModuleName(sourceModule) {
        this.log(`${STAGE_PREFIX} - updating source module to`, sourceModule);
        this.stageRTCBroadcast.updateSourceModuleName(sourceModule);
    }

    async canJoin() {
        if (!this.leaveChannelProgress) {
            return true;
        }
        this.log(`${STAGE_PREFIX} - waiting for leave request to be completed`);
        return await this.leaveChannelProgress.finally(true);
    }

    async join(eventChannelName, props = {}, callback = () => {}) {
        // check if channel is already joined
        await this.canJoin();
        if (
            this.channelJoined &&
            this.channelName &&
            this.channelName !== eventChannelName
        ) {
            this.log(
                `${STAGE_PREFIX} - Already join different channel ${this.channelName}. So, leaving the channel and join again`
            );
            await this.leaveBroadCastRTC();
        } else if (this.channelJoined) {
            this.log(
                `${STAGE_PREFIX} - Received join Channel request again. Channel already joined ${this.channelJoined}`
            );
            callback();
            return Promise.resolve(true);
        }
        if (this.joinChannelProgress && this.channelName !== eventChannelName) {
            this.log(
                `${STAGE_PREFIX} - Received join Channel request again. One already in process. So, leaving the channel and join again`
            );
            this.channelJoined = true;
            await this.leaveBroadCastRTC();
        } else if (this.joinChannelProgress) {
            const errorMessage = `${STAGE_PREFIX} - Skip to join Channel. Already in process`;
            this.log(errorMessage);
            return this.joinChannelProgress;
        }
        this.channelName = eventChannelName;
        const filterParams = this.getFilterParams();
        const {
            resolution = '',
            canSubscribe,
            is1080Enabled,
            sourceModule,
            userLoginType,
            mode,
            isClosedCaptioningEnabled,
            enableDualStream,
            enableCCSubscriptionViaFirebase,
            closedCaptionConfParams,
            customFPSConfig,
        } = props;
        const streamVideoProfile =
            resolution &&
            [RESOLUTIONS_HD_PLUS, RESOLUTIONS_SD, RESOLUTIONS_1080P].includes(
                resolution
            )
                ? resolution
                : RESOLUTIONS_SD;
        if (!canSubscribe) {
            this.setDefaultAudioMute(true);
        }
        this.setResolution(streamVideoProfile);
        let { layouts: videoProfilesList } = this.resolutionsInfo;
        const videoProfile =
            mode === 'backstage'
                ? videoProfilesList.backstagePanelLowProfile
                : videoProfilesList['split'][1];
        const mediaProfile = {
            audio: {
                isEnabled: false,
                microphoneId: localStorage.getItem(PREF_AUDIO_IN),
            },
            video: {
                isEnabled: false,
                ...videoProfile,
                frameRate: 15,
                minBitrate: videoProfile.maxBitrate * MIN_BITRATE_COEFFECIENT,
                cameraId: localStorage.getItem(PREF_VIDEO_IN),
                maxActiveSpeakers: this.maxActiveSpeakers,
            },
        };
        const audioOutputDeviceId =
            localStorage.getItem(PREF_AUDIO_OUT) || null;

        const ccPolyglotServiceProvider =
            closedCaptionConfParams?.['ccServiceProviders']?.[
                ccModules.SESSIONS
            ];
        const ccInputLanguage = getCCPrimaryLangVendorValue(
            closedCaptionConfParams?.ccPrimaryLang?.label,
            ccPolyglotServiceProvider
        );

        const conferenceParams = {
            conferenceId: eventChannelName,
            myUserId: this.authUser.id_seq,
            myRole: RTC_ROLES.AUDIENCE,
            mode: conferenceModes.LIVE,
            prefVideoCodec: videoCodecs.VP8,
            rtcProviderName: VENDOR_AGORA,
            mediaProfile,
            isAutoPublishEnabled: true,
            canSubscribe,
            filterParams,
            sourceModule,
            is1080Enabled,
            extraLogArgs: {
                isFree: getAirmeetUtilInstance()?.is_enable_watermark,
            },
            enableMusicMode: isMusicModeEnabled(this.airmeetId),
            enableNoiseSuppression: isNoiseSuppressionEnabled(this.airmeetId),
            audioOutputDeviceId,
            userLoginType: userLoginType,
            // closed caption Settings
            enableCCSubscriptionViaFirebase,
            isClosedCaptioningEnabled,
            enableDualStream,
            isCCUsingRTCSDK: closedCaptionConfParams.isCCUsingRTCSDK,
            ccPolyglotServiceProvider,
            ccInputLanguage,
            communityId: closedCaptionConfParams.communityId,
            customFPSConfig,
        };
        // moved flags to publish/unpublish part to promise to handle the scenarios where unpublish is requested in between the publish,
        // so the system will unpublish after the publish successfully
        // Also, it will handle the weird cases when publish/unpublish called from multiple places
        this.joinChannelProgress = new Promise(async (resolve, reject) => {
            try {
                this.log(
                    `${STAGE_PREFIX} - Channel join request`,
                    conferenceParams
                );
                this.resetData('joinChannel', {
                    skipCustomMedia: !canSubscribe,
                    eventChannelName,
                });

                const results = await this.stageRTCBroadcast.joinConference(
                    conferenceParams
                );
                if (this.channelName !== eventChannelName) {
                    this.channelJoined = true;
                    const errorMessage = `${STAGE_PREFIX} - Leaving the channel because of leave broadcast api called with join channel`;
                    this.log(errorMessage);
                    await this.leaveBroadCastRTC();
                    return reject([errorMessage]);
                }

                this.log(`${STAGE_PREFIX} - Channel joined success`, results);
                this.localMediaStatus = results?.localMediaStatus;
                this.channelJoined = true;
                callback();
                // checking for watch mode hd/ld/audio only
                if (this.watchMode !== this.updatedWatchMode) {
                    this.setRemoteMediaMode(this.watchMode);
                }
                return resolve(results);
            } catch (error) {
                QOELogger(
                    broadcastLogConstants.BROADCAST_FAILED_TO_JOIN_CHANNEL,
                    this.authUser.id_seq,
                    error
                );
                this.log(`${STAGE_PREFIX} - join channel error`, error);
                return reject([error, true]);
            }
        });
        // cleanup the promise flag when all the promises inside it either resolved or rejected
        this.joinChannelProgress.finally(() => {
            this.joinChannelProgress = null;
        });
        return this.joinChannelProgress;
    }

    resetData(source, props = {}) {
        this.log(`${STAGE_PREFIX} - reset data request`, {
            source,
            channelName: this.channelName,
        });
        this.localStream = null;
        this.hasStreamPublished = false;
        this.activeSpeakers = [];
        this.streams = {};
        this.publishStreamProgress = null;

        // this is to handle the condition because when pre-recorded video is played backstage
        // and then the session goes live, streams object will become empty because of the reset, so keep the pre-recorded stream active.
        if (
            props.skipCustomMedia &&
            this.channelName === props.eventChannelName &&
            this.hasPublishPreRecordedStream()
        ) {
            this.log(
                `${STAGE_PREFIX} - resetting streams but skip the pre-recorded stream for cloudhost`
            );
            const preRecordedStream = this.getLocalPreRecordedStream();
            const streamId = preRecordedStream.getId();
            this.streams[streamId] = preRecordedStream;
        }
    }

    async initPreRecordedClient(eventChannelName) {
        if (this.isVideoClientInit || this.channelJoined) {
            return this.isVideoClientInit || this.stageRTCBroadcast;
        }
        const mediaProfile = {
            audio: {
                isEnabled: false,
            },
            video: {
                isEnabled: false,
                maxActiveSpeakers: this.maxActiveSpeakers,
            },
        };
        const conferenceParams = {
            conferenceId: eventChannelName,
            myUserId: this.authUser.id_seq,
            myRole: 'audience',
            mode: conferenceModes.LIVE,
            prefVideoCodec: videoCodecs.VP8,
            rtcProviderName: VENDOR_AGORA,
            mediaProfile,
            isAutoPublishEnabled: true,
            canSubscribe: false,
            sourceModule: RTC_SOURCE_MODULES.LIVE_STAGE,
            extraLogArgs: {
                isFree: getAirmeetUtilInstance()?.is_enable_watermark,
            },
            enableMusicMode: isMusicModeEnabled(this.airmeetId),
            enableNoiseCancellation: isNoiseSuppressionEnabled(this.airmeetId),
        };
        try {
            this.log(`${STAGE_PREFIX} - pre recorded video init`);
            this.isVideoClientInit = await this.stageRTCBroadcast.initConference(
                conferenceParams
            );
            this.videoClientInitChannelName = eventChannelName;
            this.log(`${STAGE_PREFIX} - pre recorded video success`);
            return this.isVideoClientInit;
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - Failed to init conference for pre-recorded video`,
                e
            );
            return false;
        }
    }

    cleanupRemoteScreenShare(user) {
        const userId = user.userId;
        const streamId = createScreenShareUserId(userId);
        // avoid sending events multiple times when it's already removed
        if (!this.streams[streamId]) {
            return;
        }
        this.removeStream(streamId);
        this.emit(Events.rtcLiveStreamRemoved, { id: streamId });
        this.emit(Events.streamRemoveScreen, { id: streamId, userId });
        this.emit(Events.streamAddedScreen, {});
    }

    cleanupRemotePdfOrVideo(user) {
        const { userId, customMediaType } = user;
        // skip when media type is other than pdf and video
        if (
            ![mediaTypes.CUSTOM_VIDEO, mediaTypes.CUSTOM_PDF].includes(
                user.customMediaType
            )
        ) {
            return;
        }

        const streamId =
            customMediaType === mediaTypes.CUSTOM_VIDEO
                ? createCustomMediaUserId(userId)
                : this.createPDFShareUserId(userId);
        // avoid sending events multiple times when it's already removed
        if (!this.streams[streamId]) {
            return;
        }
        // remove custom video or pdf streams
        this.removeStream(streamId);
        this.emit(Events.rtcLiveStreamRemoved, { id: streamId });
        if (customMediaType === mediaTypes.CUSTOM_VIDEO) {
            this.emit(Events.streamAddedCustomMedia, {});
        }
    }

    removeStream(streamId) {
        delete this.streams[streamId];
        this.onActiveSpeakerRemove(streamId);
    }

    getStreamInfo(localStream) {
        return {
            settings: localStream.settings,
        };
    }

    async setPersistPublication({ isPersistPublication = false }) {
        this.isPersistPublication = isPersistPublication;
        this.log(`${STAGE_PREFIX} - Setting persist publication`, {
            isPersistPublication,
        });
    }

    async updateRemoteUserPrivileges(userId, role) {
        let state = 'downgrade';
        if (role === RTC_ROLES.HOST) {
            state = 'upgrade';
        }
        try {
            this.log(
                `${STAGE_PREFIX} - ${state} role to ${role} for user ${userId} init`
            );
            await this.stageRTCBroadcast.updateRemoteUserRole(userId, role);
            this.log(
                `${STAGE_PREFIX} - ${state} role to ${role} for user ${userId} success`
            );
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - ${state} role to ${role} for user ${userId} failed`,
                e
            );
        }
    }

    /**
     *
     * @param {string} msg - msg description
     * @param {string} logType - log Type error, info, warn
     */
    logDataToSDK({ msg, logType = 'info' }) {
        this.stageRTCBroadcast.injectLog({
            msg,
            type: logType,
        });
    }

    async toggleCCSubscription(ccSubscriptionLanguage) {
        try {
            const turningOn = ccSubscriptionLanguage?.length > 0;
            this.log(
                `${STAGE_PREFIX} - ${
                    turningOn ? 'Start' : 'Stop'
                } closed caption subscription request`
            );
            await this.stageRTCBroadcast.toggleClosedCaptions(
                ccSubscriptionLanguage
            );
            this.log(
                `${STAGE_PREFIX} - Successfully ${
                    turningOn ? 'started' : 'stopped'
                } closed caption subscription`
            );
        } catch (err) {
            this.log(
                `${STAGE_PREFIX} - toggle closed caption subscription failed`,
                err
            );
        }
    }

    async onCCPrimaryLanguageChange(newDefaultLanguage) {
        try {
            if (newDefaultLanguage?.value?.length > 0) {
                this.log(
                    `${STAGE_PREFIX} - closed-caption primary-language change request: ${newDefaultLanguage?.value}`
                );
            }
            await this.stageRTCBroadcast.onCCInputLanguageChange(
                newDefaultLanguage
            );
            this.log(
                `${STAGE_PREFIX} - Successfully changed closed-caption primary-language: ${newDefaultLanguage?.value}`
            );
        } catch (err) {
            this.log(
                `${STAGE_PREFIX} - [Error] - closed-caption primary-language change failed: ${newDefaultLanguage?.value}`,
                err
            );
        }
    }

    cleanupRemoteStreams() {
        this.log(
            `${STAGE_PREFIX} - cleaning up remote stream before switching the channel`
        );
        const streams = this.getStreams();
        streams.forEach((stream) => {
            if (stream.local || stream.confId === this.channelName) {
                return;
            }
            const streamId = stream.getId();
            this.log(
                `${STAGE_PREFIX} - cleaned ${streamId} before switching the channel`
            );
            this.removeStream(streamId);
        });
    }

    async switchChannel(toChannel) {
        if (this.switchChannelProgress || this.leaveChannelProgress) {
            this.log(
                `${STAGE_PREFIX} - Skip to to call switchChannel leave channel or switch channel is in progress`,
                {
                    switchChannelProgress: !!this.switchChannelProgress,
                    leaveChannelProgress: !!this.leaveChannelProgress,
                }
            );
            return;
        }
        if (this.unPublishStreamProgress) {
            this.log(
                `${STAGE_PREFIX} - Register to pending unpublishStream request[call switchChannel after unpublish]`
            );
            await this.unPublishStreamProgress;
            this.log(
                `${STAGE_PREFIX} - Released pending unpublishStream request[call switchChannel after unpublish]`
            );
        }
        this.switchChannelProgress = new Promise(async (resolve, reject) => {
            const fromChannel =
                this.videoClientInitChannelName || this.channelName;
            if (fromChannel === toChannel) {
                this.log(
                    `${STAGE_PREFIX} - skip the switch channel because from and to channel ids are same`,
                    {
                        fromChannel,
                        toChannel,
                    }
                );
                return resolve({
                    success: false,
                    errorCode: this.channelJoined
                        ? 'already_in_channel'
                        : 'mismatch_channel',
                    hasChannelJoined: this.channelJoined,
                });
            }
            const skipUserJoinValidation = this.isVideoClientInit
                ? true
                : false;
            const baseLog = `${STAGE_PREFIX} - Switching channel ${fromChannel} to ${toChannel} with skipUserJoinValidation:${skipUserJoinValidation}`;
            try {
                this.log(`${baseLog} init`);
                this.cleanupRemoteStreams();
                await this.stageRTCBroadcast.switchChannel(
                    fromChannel,
                    toChannel,
                    skipUserJoinValidation
                );
                this.log(`${baseLog} success`);
                return resolve({ success: true });
            } catch (e) {
                this.log(`${baseLog} failed`, e);
                this.channelJoined = false;
                return resolve({
                    success: false,
                    errorCode: 'failed_to_switch',
                });
            }
        });
        // cleanup the promise flag when all the promises inside it either resolved or rejected
        this.switchChannelProgress.finally(() => {
            this.switchChannelProgress = null;
        });
        return this.switchChannelProgress;
    }

    updateLocalVideoMirror(mirrorLocalVideo) {
        const localStream = this.getLocalUserStream();
        if (localStream) {
            localStream.mirror = mirrorLocalVideo;
        }
        if (this.streams?.[this.authUser.id_seq]) {
            this.streams[this.authUser.id_seq].mirror = mirrorLocalVideo;
        }
    }

    getChannelName() {
        return this.channelName;
    }

    setLiveStreamingId(liveStreamingId) {
        this.liveStreamingId = liveStreamingId;
        return;
    }

    getLiveStreamingId() {
        return this.liveStreamingId;
    }

    getChannelLiveStreamingUrl() {
        return getChannelLiveStreamingUrl(
            this.getLiveStreamingId(),
            [RESOLUTIONS_1080P].includes(this.resolution)
        );
    }

    getBroadCastRTCClient() {
        return this.stageRTCBroadcast;
    }

    getMuteVideos(streamsInfo) {
        const mutedStreams = {};
        streamsInfo.forEach(({ id, streamMetaData }) => {
            mutedStreams[id] = !streamMetaData?.isVideoEnable;
        });
        return mutedStreams;
    }

    getLocalUserStream() {
        return this.localStream;
    }

    hasUserStreamPublished() {
        return !!this.localStream;
    }

    getLocalScreenShareStream() {
        return this.localScreenStream;
    }

    getLocalPreRecordedStream() {
        return this.localMediaStream;
    }

    hasPublishPreRecordedStream() {
        return this.localMediaStream ? !!this.localMediaStream.getId() : false;
    }

    getStream(id) {
        return this.streams[id];
    }

    async updateActiveSpeakersCount(count) {
        if (count < 1) {
            this.log(
                `${STAGE_PREFIX} - skipping active speakers limit change to ${count}`
            );
            return;
        }
        try {
            this.maxActiveSpeakers = count;
            this.log(
                `${STAGE_PREFIX} - update active speakers limit init to ${count}`
            );
            await this.stageRTCBroadcast.updateActiveSpeakersCount(count);
            this.log(
                `${STAGE_PREFIX} - update active speakers limit success to ${count}`
            );
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - update active speakers limit to ${count}`,
                e
            );
        }
    }

    getStreams() {
        return Object.values(this.streams || {});
    }

    getActiveStreams() {
        return this.activeSpeakers;
    }

    getStreamsCount() {
        return this.getStreams().length || 0;
    }

    getActiveSpeaker() {
        return this.activeSpeaker;
    }

    getMainStream() {
        return;
    }

    async setRemoteStreamAudio(mute) {
        try {
            this.log(
                `${STAGE_PREFIX} - mute remote stream audio to ${mute} init`
            );
            await this.stageRTCBroadcast.muteRemoteMediaForSelf(mute, {
                mediaType: mediaTypes.AUDIO,
                toggleUserAVOnly: true,
            });
            this.log(
                `${STAGE_PREFIX} - mute remote stream audio to ${mute} success`
            );
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - Failed to mute/unmute remote stream audio`,
                e
            );
        }
    }

    async updateUserPublishVideoProfile(
        layout,
        noOfStreams,
        mainStreamUids,
        stageView
    ) {
        const { localStream, hasStreamPublished } = this;
        if (
            !layout ||
            !localStream ||
            noOfStreams === 0 ||
            !hasStreamPublished ||
            this.unPublishStreamProgress ||
            this.leaveChannelProgress
        ) {
            return;
        }
        this.videoProfileParams = {
            layout,
            noOfStreams,
            mainStreamUids,
            stageView,
        };
        const id = localStream.getId();

        let { videoProfile, layouts: videoProfilesList } = this.resolutionsInfo;
        if (layout === 'split' || layout === 'dominant') {
            const videoProfiles = videoProfilesList['split'];
            const noOfMainStreams = mainStreamUids.length;
            videoProfile = mainStreamUids.includes(id)
                ? videoProfiles[noOfMainStreams]
                    ? videoProfiles[noOfMainStreams]
                    : videoProfiles[6]
                : videoProfiles['smallStream'];
            this.isDominantStream =
                mainStreamUids.includes(id) && layout === 'dominant';
        } else if (layout === 'screen') {
            if (
                stageView === STAGE_VIEWS.USER_WITH_CONTENT &&
                mainStreamUids.includes(id)
            ) {
                const videoProfiles = videoProfilesList['split'];
                videoProfile = videoProfiles[2];
                this.log(
                    `${STAGE_PREFIX} - Updated video profile request for the publish in news layout ${JSON.stringify(
                        videoProfile
                    )} for layout ${layout}.`,
                    { stageView }
                );
            } else {
                const videoProfiles = videoProfilesList[layout];
                videoProfile = videoProfiles.smallStream;
            }
            this.isDominantStream = false;
        }

        if (
            localStream.isVideoOn() &&
            STAGE_STREAM_MODES[this.watchMode] ===
                STAGE_STREAM_MODES.lowResolution &&
            layout !== 'screen'
        ) {
            videoProfile = this.isDominantStream
                ? videoProfilesList.lowDefinitionProfile.mainStream
                : videoProfilesList.lowDefinitionProfile.smallStream;
        }
        if (this.videoProfile === videoProfile) {
            return;
        }
        this.videoProfile = videoProfile;
        try {
            const mediaProfile = {
                audio: {
                    isEnabled: localStream.isAudioOn(),
                },
                video: {
                    isEnabled: localStream.isVideoOn(),
                    ...videoProfile,
                    //frameRate: 15, // We not need to pass framerate again as we use the same frame rate
                    minBitrate:
                        videoProfile.maxBitrate * MIN_BITRATE_COEFFECIENT,
                    maxActiveSpeakers: this.maxActiveSpeakers,
                },
            };
            this.log(
                `${STAGE_PREFIX} - Updated the publish stream video profile ${JSON.stringify(
                    mediaProfile
                )} for layout ${layout}.`,
                { stageView }
            );

            await this.stageRTCBroadcast.updatePublication(mediaProfile, 1);
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - Failed to update the media profile updatePublication`,
                e
            );
        }
        return;
    }

    async updateCustomMediaVideoProfile() {
        if (!this.hasPublishPreRecordedStream()) return;
        const { customMediaConfig } = this.resolutionsInfo;
        try {
            const mediaProfile = {
                customVideo: {
                    minBitrate: customMediaConfig.minBitRate,
                    maxBitrate: customMediaConfig.maxBitRate,
                    height: customMediaConfig.height,
                },
            };

            this.log(
                `${STAGE_PREFIX} - Update the custom media video profile ${JSON.stringify(
                    mediaProfile
                )}`
            );
            await this.stageRTCBroadcast.updatePublication(mediaProfile, 1);
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - Failed to update the media profile updatePublication for custom media`,
                e
            );
        }

        return;
    }

    getRemoteStreamData(id) {
        return this.streams[id];
    }

    getRemoteStreams() {
        return this.streams;
    }

    getStreamById(id) {
        return this.getRemoteStreamData(id);
    }

    getAccountUid(id) {
        return this.authUser.id_seq;
    }

    hasPublished() {
        return this.hasStreamPublished;
    }

    async publishStream({ mirror = true }) {
        this.log(`${STAGE_PREFIX} - publish stream requested invoke`, {
            hasLocalStream: !!this.localStream,
            publishStreamProgress: !!this.publishStreamProgress,
            channelJoined: this.channelJoined,
            switchChannelProgress: !!this.switchChannelProgress,
        });
        if (
            //  (this.localStream && !this.unPublishStreamProgress) ||
            this.publishStreamProgress ||
            !this.channelJoined
        ) {
            this.log(`${STAGE_PREFIX} - publish stream requested skip`, {
                hasLocalStream: !!this.localStream,
                publishStreamProgress: !!this.publishStreamProgress,
                channelJoined: this.channelJoined,
            });
            return;
        }

        if (this.switchChannelProgress) {
            this.log(
                `${STAGE_PREFIX} - Register to pending switchChannelProgress request[publish stream immediate after switch]`
            );
            await this.switchChannelProgress;
            this.log(
                `${STAGE_PREFIX} - Released pending switchChannelProgress request[publish stream immediate after switch]`
            );
        }
        this.log(`${STAGE_PREFIX} - publish stream requested`);
        // moved flags to publish/unpublish part to promise to handle the scenarios where unpublish is requested in between the publish,
        // so the system will unpublish after the publish successfully
        // Also, it will handle the weird cases when publish/unpublish called from multiple places
        const channelId = this.channelName;
        this.publishStreamProgress = new Promise(async (resolve, reject) => {
            // upgrade user role
            await this.updateRemoteUserPrivileges(
                this.authUser.id_seq,
                RTC_ROLES.HOST
            );
            // get local media status
            this.localMediaStatus = this.stageRTCBroadcast.getConferenceInfo().capturedMediaStatus;
            this.hasStreamPublished = true;
            const localStream = {
                isAudioOn: () => false,
                isVideoOn: () => false,
                getId: () => this.authUser.id_seq,
                stream: true,
                local: true,
                mediaStatus: 'camera',
                mirror,
                channelId,
                localUid: uuid(),
            };
            this.localStream = localStream;
            this.log(
                `${STAGE_PREFIX} - publish stream set local stream with localUid: ${localStream.localUid}`
            );
            this.streams[this.authUser.id_seq] = localStream;
            // avoid updating the audio/video controls when persistent stage enabled
            // will be handled separately
            if (!this.isPersistPublication) {
                // updating the audio/video controls
                if (channelId !== this.channelName) {
                    this.log(
                        `${STAGE_PREFIX} - post publish skipping the audio state as mismatch channel`,
                        {
                            channelId,
                            updatedChannelId: this.channelName,
                        }
                    );
                    this.localStream = null;
                    return reject({
                        channelId,
                        updatedChannelId: this.channelName,
                        reason: 'mismatch_channel',
                    });
                }

                this.log(
                    `${STAGE_PREFIX} - post publish updating the Audio state`,
                    { defaultAudioMute: this.getDefaultAudioMute() }
                );
                await this.muteLocalAudio(!this.getDefaultAudioMute());

                if (channelId !== this.channelName) {
                    this.log(
                        `${STAGE_PREFIX} - post publish skipping the video state as mismatch channel`,
                        {
                            channelId,
                            updatedChannelId: this.channelName,
                        }
                    );
                    this.localStream = null;
                    return reject({
                        channelId,
                        updatedChannelId: this.channelName,
                        reason: 'mismatch_channel',
                    });
                }
                this.log(
                    `${STAGE_PREFIX} - post publish updating the video state`,
                    { defaultVideoMute: this.getDefaultVideoMute() }
                );
                await this.muteLocalVideo(!this.getDefaultVideoMute());
            }
            this.emit(Events.rtcLiveStreamPublished, {
                localStream,
            });
            this.log(`${STAGE_PREFIX} - publish stream success`);
            resolve(true);
        });
        this.publishStreamProgress.finally(() => {
            this.publishStreamProgress = null;
        });
        return this.publishStreamProgress;
    }

    async unpublishStream(onlyRemoveStream = false) {
        if (this.unPublishStreamProgress || this.leaveChannelProgress) {
            return;
        }
        if (this.publishStreamProgress) {
            this.log(
                `${STAGE_PREFIX} - Register to pending unpublishStream request[unpublish immediate after publish]`
            );
            await this.publishStreamProgress;
            this.log(
                `${STAGE_PREFIX} - Released pending unpublishStream request[unpublish immediate after publish]`
            );
        }
        if (!this.localStream) {
            this.log(
                `${STAGE_PREFIX} - Skip unpublishStream as localstream is not present`
            );
            return;
        }
        if (this.switchChannelProgress) {
            this.log(
                `${STAGE_PREFIX} - Register to pending switchChannelProgress request[unpublish immediate after switch]`
            );
            await this.switchChannelProgress;
            this.log(
                `${STAGE_PREFIX} - Released pending switchChannelProgress request[unpublish immediate after switch]`
            );
        }
        // moved flags to publish/unpublish part to promise to handle the scenarios where unpublish is requested in between the publish,
        // so the system will unpublish after the publish successfully
        // Also, it will handle the weird cases when publish/unpublish called from multiple places
        this.unPublishStreamProgress = new Promise(async (resolve) => {
            try {
                const localStreamUid = this.localStream.localUid;
                this.log(
                    `${STAGE_PREFIX} - unpublish stream requested for localStreamUid: ${localStreamUid} `
                );
                const streamId = this.localStream.getId();
                await this.updateRemoteUserPrivileges(
                    this.authUser.id_seq,
                    RTC_ROLES.AUDIENCE
                );

                if (
                    localStreamUid &&
                    this.localStream.localUid &&
                    localStreamUid !== this.localStream.localUid
                ) {
                    this.log(
                        `${STAGE_PREFIX} - Unpublish user stream skip due to new publish stream started`,
                        {
                            updatedLocalStreamUid: this.localStream.localUid,
                            localStreamUid,
                        }
                    );
                    return resolve(false);
                }
                // remove stream
                this.removeStream(streamId);
                this.log(`${STAGE_PREFIX} - Unpublish user stream success`, {
                    onlyRemoveStream,
                    id: streamId,
                });
                this.emit(Events.rtcLiveStreamRemoved, {
                    id: { id: streamId },
                });
                this.emit(Events.rtcLiveStreamUnpublished, {
                    preventScreen: onlyRemoveStream,
                    id: streamId,
                });
                this.hasStreamPublished = false;
                this.localStream = null;
                return resolve(true);
            } catch (e) {
                this.log(
                    `${STAGE_PREFIX} - Failed to unpublish user stream`,
                    e
                );
                return resolve(false);
            }
        });
        // cleanup the promise flag when all the promises inside it either resolved or rejected
        this.unPublishStreamProgress.finally(() => {
            this.unPublishStreamProgress = null;
        });
        return this.unPublishStreamProgress;
    }

    // [persistent stage]
    // Needed for persistent backstage where users join the stage and backstage channels and always publish.
    // We enable the controls based on where the user is interacting.
    async enableAudioVideoControls({ mirror, forceAudioMute, forceVideoMute }) {
        // publish the stream if not published
        if (!this.hasStreamPublished) {
            await this.publishStream({ mirror });
        }
        this.emit(Events.enableAVControlsPB, {
            status: 'start',
        });
        // avoid unmute the controls initially when pre-recorded media mode is enabled
        if (forceAudioMute && forceVideoMute) {
            this.setDefaultAVState({
                defaultAudioMute: true,
                defaultVideoMute: true,
            });
            this.log(
                `${STAGE_PREFIX} - updating the default audio and video to mute state when media mode is selected`
            );
            this.emit(Events.enableAVControlsPB, {
                status: 'finish',
            });
            return;
        }
        this.log(
            `${STAGE_PREFIX} - updating the Audio state for persistent backstage`,
            {
                defaultAudioMute: this.getDefaultAudioMute(),
            }
        );
        try {
            await this.muteLocalAudio(!this.getDefaultAudioMute());
            this.log(
                `${STAGE_PREFIX} - updating the video state for persistent backstage`,
                {
                    defaultVideoMute: this.getDefaultVideoMute(),
                }
            );
            await this.muteLocalVideo(!this.getDefaultVideoMute());
            this.emit(Events.enableAVControlsPB, {
                status: 'finish',
            });
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - error while updating the Audio state for persistent backstage`,
                {
                    defaultAudioMute: this.getDefaultAudioMute(),
                    defaultVideoMute: this.getDefaultVideoMute(),
                    error: e,
                }
            );
            this.emit(Events.enableAVControlsPB, {
                status: 'error',
            });
        }
    }

    // [persistent stage]
    // We disable the controls based on where the user is interacting.
    async disableAudioVideoControls() {
        await this.muteLocalAudio(false, false);
        await this.muteLocalVideo(false, false);
    }

    changeWatchMode(mode) {
        if (!this?.stageRTCBroadcast || !mode) return;
        // keep function in catches and call when channel joined
        this.watchMode = mode;
        if (!this.channelJoined) {
            return Promise.resolve(true);
        } else {
            return this.setRemoteMediaMode(mode);
        }
    }

    async setRemoteMediaMode(mode) {
        try {
            this.updatedWatchMode = mode;
            this.log(`${STAGE_PREFIX} - Change watch mode to ${mode}`);
            await this.stageRTCBroadcast.setRemoteMediaMode(
                STAGE_STREAM_MODES[mode]
            );
            // update local stream profile
            const { layout, noOfStreams, mainStreamUids, stageView } = this
                .videoProfileParams || {
                layout: null,
                noOfStreams: 0,
                stageView: null,
            };
            this.updateUserPublishVideoProfile(
                layout,
                noOfStreams,
                mainStreamUids,
                stageView
            );
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - Failed to Change watch mode to ${mode}`,
                e
            );
        }
    }

    pauseLocalStreamCheck() {
        return;
    }

    async resumeLocalStreamCheck() {
        try {
            let filterInfo = null;
            const filterStorage = FilterStorageManager.getInstance();
            try {
                filterInfo = filterStorage.getParsedFilterInfo();
            } catch (e) {
                this.log(
                    `${STAGE_PREFIX} - Error occured while parsing filter information from localStorage`,
                    e
                );
            }
            await this.changeFilter(filterInfo);
        } catch (e) {
            this.log('Failed to change filter', e);
        }
        let tasks = [];
        if (localStorage.getItem(PREF_VIDEO_IN)) {
            tasks.push(
                this.stageRTCBroadcast.setNewDeviceId(
                    DEVICE_TYPE_CAMERA,
                    localStorage.getItem(PREF_VIDEO_IN)
                )
            );
        }

        if (localStorage.getItem(PREF_AUDIO_IN)) {
            tasks.push(
                this.stageRTCBroadcast.setNewDeviceId(
                    DEVICE_TYPE_MIC,
                    localStorage.getItem(PREF_AUDIO_IN)
                )
            );
        }

        if (localStorage.getItem(PREF_AUDIO_OUT)) {
            tasks.push(
                this.stageRTCBroadcast.setSpeaker(
                    localStorage.getItem(PREF_AUDIO_OUT)
                )
            );
        }

        try {
            this.log(`${STAGE_PREFIX} - Initiate change device`);
            await Promise.all(tasks);
            this.log(`${STAGE_PREFIX} - Device change success`, {
                camera: localStorage.getItem(PREF_VIDEO_IN),
                mic: localStorage.getItem(PREF_AUDIO_IN),
            });
        } catch (e) {
            this.log(`${STAGE_PREFIX} - Failed to change device`, e);
        }
        await onAudioModeSettingChanged(this.stageRTCBroadcast, this.airmeetId);
    }

    async unpublishCustomLocalStream() {
        if (this.unpublishPreRecordedProgress) {
            return this.unpublishPreRecordedProgress;
        }
        if (this.publishPreRecordedProgress) {
            this.log(
                `${STAGE_PREFIX} - Register to pending unpublishCustomLocalStream request[unpublish immediate after publish]`
            );
            await this.publishPreRecordedProgress;
            this.log(
                `${STAGE_PREFIX} - Released pending unpublishCustomLocalStream request[unpublish immediate after publish]`
            );
        }
        if (this.localMediaStream) {
            // moved flags to publish/unpublish part to promise to handle the scenarios where unpublish is requested in between the publish,
            // so the system will unpublish after the publish successfully
            // Also, it will handle the weird cases when publish/unpublish called from multiple places
            this.unpublishPreRecordedProgress = new Promise(async (resolve) => {
                this.log(
                    'Stop custom media - stop custom media pre-recorded video unpublish [unpublishCustomMediaStream]'
                );
                try {
                    this.log(
                        `${STAGE_PREFIX} - stopping pre-recorded video init`
                    );
                    await this.stageRTCBroadcast.unpublishCustomLocalStream();
                    this.log(
                        `${STAGE_PREFIX} - stopping pre-recorded video success`
                    );
                    this.cleanupPreRecordedVideo();
                    return resolve(true);
                } catch (e) {
                    this.log(
                        `${STAGE_PREFIX} - Failed to stop pre-recorded video`,
                        e
                    );
                    return resolve(false);
                }
            });
            // cleanup the promise flag when all the promises inside it either resolved or rejected
            this.unpublishPreRecordedProgress.finally(() => {
                this.unpublishPreRecordedProgress = null;
            });
            return this.unpublishPreRecordedProgress;
        }
        return Promise.resolve(false);
    }

    muteSpeakerAudioAsHost(receiverId) {
        this.rtmClient.sendMessage(
            RtmClientMessages.hostRequestedMuteSpeakerAudioInBroadcast,
            receiverId,
            {}
        );
    }

    muteSpeakerVideoAsHost(receiverId) {
        this.rtmClient.sendMessage(
            RtmClientMessages.hostRequestedMuteSpeakerVideoInBroadcast,
            receiverId,
            {}
        );
    }

    async muteLocalAudio(isAudioOn, updateDefaultState = true) {
        this.log(
            isAudioOn
                ? `${STAGE_PREFIX} - Unmute audio init`
                : `${STAGE_PREFIX} - Mute audio init`
        );

        if (!this.localStream) {
            this.log(
                `${STAGE_PREFIX} - skip mute/unmute audio, has not have local stream`
            );
            return false;
        }

        if (this.switchChannelProgress || this.unPublishStreamProgress) {
            this.setDefaultAudioMute(!this.localStream?.isAudioOn());
            this.emit(
                this.localStream?.isAudioOn()
                    ? Events.rtcBrodCastLocalStreamAudioUnmute
                    : Events.rtcBrodCastLocalStreamAudioMute,
                {
                    localStream: this.localStream,
                }
            );

            this.log(
                'skipping mute/unmute audio when switching or unpublishing in progress.',
                {
                    switchChannelProgress: !!this.switchChannelProgress,
                    unPublishStreamProgress: !!this.unPublishStreamProgress,
                }
            );
            return false;
        }

        if (
            this.localStream?.isAudioOn() !== isAudioOn &&
            (!this.unPublishStreamProgress || this.isPersistPublication)
        ) {
            const channelId = this.localStream?.channelId;
            try {
                await this.stageRTCBroadcast.muteLocalAudio(isAudioOn);
                const uid = this.localStream.getId();
                this.processAudioEvents({ isAudioOn, uid, updateDefaultState });
                this.log(
                    isAudioOn
                        ? `${STAGE_PREFIX} - Unmute audio success`
                        : `${STAGE_PREFIX} - Mute audio success`
                );
                return true;
            } catch (e) {
                this.log(`${STAGE_PREFIX} - Failed to change audio control`, e);
                if (this.channelJoined && this.channelName === channelId) {
                    this.emit(Events.audioUnmuteFailed, {
                        message: e,
                        localStream: this.localStream,
                    });
                } else {
                    this.log(
                        `${STAGE_PREFIX} - Skip trigger event for failed to change audio control in case not joined channel`
                    );
                }
                return false;
            }
        } else {
            this.emit(
                isAudioOn
                    ? Events.rtcBrodCastLocalStreamAudioUnmute
                    : Events.rtcBrodCastLocalStreamAudioMute,
                {
                    localStream: this.localStream,
                }
            );
            this.log(
                `${STAGE_PREFIX} - Skip to mute/unmute audio. unpublish or already in desired state.`
            );
            return true;
        }
    }

    processAudioEvents({ isAudioOn, uid, updateDefaultState = true }) {
        this.localStream.isAudioOn = () => isAudioOn;
        this.setStreamProps(uid, {
            ...this.streams[uid],
            isAudioOn: () => isAudioOn,
        });
        if (updateDefaultState) this.setDefaultAudioMute(!isAudioOn);
        this.emit(
            isAudioOn
                ? Events.rtcBrodCastLocalStreamAudioUnmute
                : Events.rtcBrodCastLocalStreamAudioMute,
            {
                localStream: this.localStream,
            }
        );
        this.emit(isAudioOn ? Events.onUnmuteAudio : Events.onMuteAudio, {
            uid,
        });
    }

    processVideoEvents({ isVideoOn, uid, updateDefaultState = true }) {
        this.localStream.isVideoOn = () => isVideoOn;
        this.setStreamProps(uid, {
            ...this.streams[uid],
            isVideoOn: () => isVideoOn,
        });
        if (updateDefaultState) this.setDefaultVideoMute(!isVideoOn);
        this.emit(
            isVideoOn
                ? Events.rtcBrodCastLocalStreamVideoUnmute
                : Events.rtcBrodCastLocalStreamVideoMute,
            {
                localStream: this.localStream,
            }
        );
        this.emit(isVideoOn ? Events.onUnmuteVideo : Events.onMuteVideo, {
            uid,
        });
    }

    async muteLocalVideo(isVideoOn, updateDefaultState = true) {
        this.log(
            isVideoOn
                ? `${STAGE_PREFIX} - Unmute video init`
                : `${STAGE_PREFIX} - Mute video init`
        );

        if (this.switchChannelProgress || this.unPublishStreamProgress) {
            this.setDefaultVideoMute(!this.localStream?.isVideoOn());
            this.emit(
                this.localStream?.isVideoOn()
                    ? Events.rtcBrodCastLocalStreamVideoUnmute
                    : Events.rtcBrodCastLocalStreamVideoMute,
                {
                    localStream: this.localStream,
                }
            );

            this.log(
                'skipping mute/unmute video when switching or unpublishing in progress.',
                {
                    switchChannelProgress: !!this.switchChannelProgress,
                    unPublishStreamProgress: !!this.unPublishStreamProgress,
                }
            );
            return false;
        }

        if (
            this.localStream &&
            this.localStream.isVideoOn() !== isVideoOn &&
            (!this.unPublishStreamProgress || this.isPersistPublication)
        ) {
            const channelId = this.localStream?.channelId;
            try {
                await this.stageRTCBroadcast.muteLocalVideo(isVideoOn);
                const uid = this.localStream.getId();
                this.processVideoEvents({ isVideoOn, uid, updateDefaultState });
                this.log(
                    isVideoOn
                        ? `${STAGE_PREFIX} - Unmute video success`
                        : `${STAGE_PREFIX} - Mute video success`
                );
                return true;
            } catch (e) {
                this.log(`${STAGE_PREFIX} - Failed to change video control`, e);
                if (this.channelJoined && this.channelName === channelId) {
                    this.emit(Events.videoUnmuteFailed, {
                        localStream: this.localStream,
                        message:
                            SDK_CODES.ERROR.VIDEO.includes(e?.code) &&
                            Boolean(e?.description)
                                ? e.description
                                : '',
                    });
                } else {
                    this.log(
                        `${STAGE_PREFIX} - Skip trigger event for failed to change video control in case not joined channel`
                    );
                }
                return false;
            }
        } else if (
            this.localStream &&
            this.localStream?.isVideoOn() === isVideoOn
        ) {
            this.log(
                `${STAGE_PREFIX} - Skip to mute/unmute video. Video is already in desired state.`
            );
            this.emit(
                isVideoOn
                    ? Events.rtcBrodCastLocalStreamVideoUnmute
                    : Events.rtcBrodCastLocalStreamVideoMute,
                {
                    localStream: this.localStream,
                }
            );
            return true;
        } else if (!this.localStream) {
            this.log(
                `${STAGE_PREFIX} - Skip to mute/unmute video. Local stream is not present.`
            );
            return false;
        }
        return false;
    }

    setTurnConfig(data) {
        this.turnServerConfig = data;
    }

    setProxyConfiguration(type) {
        this.connectViaProxy = type === 'agora';
        this.useAirmeetProxy = type === 'airmeet';
    }

    hasPublishScreenStream() {
        return this.localScreenStream
            ? !!this.localScreenStream?.getId()
            : false;
    }

    // [to-do] create a separate method for PDF share [probably in v2]
    async shareScreen(props) {
        if (this.hasPublishScreenStream() || this.publishScreenProgress) {
            return Promise.reject(
                'screenshare has already published or in progress',
                {
                    hasPublishScreenStream: this.hasPublishScreenStream(),
                    publishScreenProgress: !!this.publishScreenProgress,
                }
            );
        }

        const userId = this.authUser.id_seq;
        const streamId =
            props.attendeeMode === 'canvas'
                ? this.createPDFShareUserId(userId)
                : createScreenShareUserId(userId);
        const stream = {
            getId: () => streamId,
            stream: true,
            local: true,
            isAudioOn: () => false,
            isVideoOn: () => true,
        };
        // moved flags to publish/unpublish part to promise to handle the scenarios where unpublish is requested in between the publish,
        // so the system will unpublish after the publish successfully
        // Also, it will handle the weird cases when publish/unpublish called from multiple places
        this.publishScreenProgress = new Promise(async (resolve, rejected) => {
            if (props.attendeeMode === 'canvas') {
                const mediaElement = document.getElementById(props.elementId);
                mediaElement.getContext('2d');
                const mediaStream = mediaElement.captureStream(
                    TEXT_CONTENT_SHARE_FRAMERATE
                );
                const mediaProfile = {
                    audio: {},
                    video: { optimizationMode: streamOptimizationMode.DETAIL },
                };
                try {
                    this.log(
                        `${STAGE_PREFIX} - Screen share init [pdf]`,
                        mediaProfile
                    );

                    await this.stageRTCBroadcast.publishCustomLocalStream(
                        mediaStream,
                        mediaTypes.CUSTOM_PDF,
                        mediaProfile
                    );
                } catch (e) {
                    this.log(`${STAGE_PREFIX} - Screen share error [pdf]`, e);
                    return rejected(e);
                }
                stream.mediaStatus = mediaTypes.CUSTOM_PDF;
                stream.videoTrack = mediaStream.getVideoTracks()?.[0];
                stream.settings = stream.videoTrack?.getSettings();
                this.log(
                    `${STAGE_PREFIX} - publish canvas media stream settings data`,
                    stream.settings
                );
                this.presenting = 'pdf';
            } else {
                const { resolution = '', reuseTracks } = props;
                const streamVideoProfile =
                    resolution &&
                    [
                        RESOLUTIONS_HD_PLUS,
                        RESOLUTIONS_SD,
                        RESOLUTIONS_1080P,
                    ].includes(resolution)
                        ? resolution
                        : RESOLUTIONS_SD;
                const { screenConfig } = resolutionsList[streamVideoProfile];
                const mediaProfile = {
                    ...screenConfig,
                    frameRate: this.enhanceScreenShareQuality
                        ? VIDEO_SHARE_MAX_FRAMERATE
                        : TEXT_CONTENT_SHARE_FRAMERATE,
                    optimizationMode: streamOptimizationMode.DETAIL,
                };
                try {
                    this.log(`${STAGE_PREFIX} - Screen share init [screen]`, {
                        mediaProfile,
                        reuseTracks,
                    });
                    const results = await this.stageRTCBroadcast.shareScreen(
                        {
                            withAudio: true,
                            optimizationMode: 'detail',
                            ...mediaProfile,
                        },
                        reuseTracks
                    );
                    stream.displaySurface = results.settings.displaySurface;
                    stream.settings = results.settings;
                } catch (e) {
                    this.log(
                        `${STAGE_PREFIX} - Screen share error [screen]`,
                        e
                    );
                    return rejected(e);
                }
                stream.mediaStatus = mediaTypes.SCREEN;
                this.presenting = 'screen';
            }

            this.localScreenStream = stream;
            this.streams[streamId] = stream;
            this.emit(Events.streamAddedScreen, {
                stream,
                isOwner: true,
                userId,
            });
            this.log(`${STAGE_PREFIX} - Screen share published`);
            return resolve(stream);
        });

        // cleanup the promise flag when all the promises inside it either resolved or rejected
        this.publishScreenProgress.finally(() => {
            this.publishScreenProgress = null;
        });
        return this.publishScreenProgress;
    }

    async updateScreenShareFramerate(enhance = false) {
        this.enhanceScreenShareQuality = enhance;
        // check if screnshare is published
        if (this.hasPublishScreenStream()) {
            // update framerate from an API
            try {
                this.log(
                    `${STAGE_PREFIX} - Update the screen share video profile init`
                );
                const mediaProfile = {
                    screenVideo: {
                        frameRate: this.enhanceScreenShareQuality
                            ? VIDEO_SHARE_MAX_FRAMERATE
                            : TEXT_CONTENT_SHARE_FRAMERATE,
                        optimizationMode: streamOptimizationMode.DETAIL,
                    },
                };
                this.log(
                    `${STAGE_PREFIX} - Updated the screen share video profile success`,
                    mediaProfile
                );

                await this.stageRTCBroadcast.updatePublication(mediaProfile, 1);
            } catch (e) {
                this.log(
                    `${STAGE_PREFIX} - Failed to update the media profile for screen share`,
                    e
                );
            }
        } else {
            return Promise.resolve(true);
        }
    }

    isScreenShareQualityEnhanced() {
        return !!this.enhanceScreenShareQuality;
    }

    cleanupScreenShare(mediaType) {
        this.presenting = null;
        this.enhanceScreenShareQuality = false;
        const userId = this.authUser.id_seq;
        const streamId =
            mediaType === 'pdf'
                ? this.createPDFShareUserId(userId)
                : createScreenShareUserId(userId);
        this.removeStream(streamId);
        this.emit(Events.streamRemoveScreen, {
            id: streamId,
            userId,
        });
        this.emit(Events.rtcLiveStreamRemoved, { id: streamId });
        this.emit(Events.streamAddedScreen, {});
    }

    cleanupPreRecordedVideo() {
        const userId = this.authUser.id_seq;
        const streamId = createCustomMediaUserId(userId);
        this.removeStream(streamId);
        this.localMediaStream = null;
        this.emit(Events.rtcLiveStreamRemoved, { id: streamId });
        this.emit(Events.streamAddedCustomMedia, {});
        this.emit(Events.streamRemoveCustomMedia, { id: streamId, userId });
    }

    async endScreenShare() {
        if (this.publishScreenProgress) {
            this.log(
                `${STAGE_PREFIX} - Register to pending endScreenShare request[unpublish immediate after publish]`
            );
            await this.publishScreenProgress;
            this.log(
                `${STAGE_PREFIX} - Released pending endScreenShare request[unpublish immediate after publish]`
            );
        }

        if (!this.localScreenStream) {
            return;
        }
        try {
            this.localScreenStream = null;
            if (this.presenting === 'pdf') {
                await this.stageRTCBroadcast.unpublishCustomLocalStream();
            } else {
                await this.stageRTCBroadcast.endScreenShare();
            }
            this.log(`${STAGE_PREFIX} - end screen share success`);
            // cleanup screen share steam
            this.cleanupScreenShare(this.presenting);
            return true;
        } catch (e) {
            this.log(`${STAGE_PREFIX} - Failed to unpublish screen share`, e);
            return false;
        }
    }

    async publishCustomLocalStream(url, elementId, props = {}) {
        if (this.publishPreRecordedProgress) {
            return Promise.reject('Request in progress');
        }
        const { streaming_resolution, channelId } = props;
        const channelName = channelId || this.channelName;
        this.log(`${STAGE_PREFIX} - publish custom local stream channel id`, {
            channelName,
            channelId,
        });
        const isVideoClientInit = await this.initPreRecordedClient(channelName);
        if (!isVideoClientInit) {
            this.publishPreRecordedProgress = null;
            return Promise.reject(
                'Pre recorded video client initialization error'
            );
        }
        const mediaElement = document.getElementById(elementId);
        const mediaStream = mediaElement.captureStream(60);
        // moved flags to publish/unpublish part to promise to handle the scenarios where unpublish is requested in between the publish,
        // so the system will unpublish after the publish successfully
        // Also, it will handle the weird cases when publish/unpublish called from multiple places
        this.publishPreRecordedProgress = new Promise(
            async (resolve, reject) => {
                try {
                    this.log(`${STAGE_PREFIX} - publishCustomLocalStream init`);
                    const resolution = streaming_resolution || RESOLUTIONS_SD;
                    const { customMediaConfig } = resolutionsList[resolution];
                    await this.stageRTCBroadcast.publishCustomLocalStream(
                        mediaStream,
                        mediaTypes.CUSTOM_VIDEO,
                        {
                            audio: {
                                stereo: true,
                                bitrate: 192,
                                sampleRate: 16,
                                sampleSize: 48000,
                            },
                            video: {
                                optimizationMode: streamOptimizationMode.MOTION,
                                minBitrate: customMediaConfig.minBitRate,
                                maxBitrate: customMediaConfig.maxBitRate,
                                height: customMediaConfig.height,
                            },
                        }
                    );

                    this.log(
                        `${STAGE_PREFIX} - publishCustomLocalStream success`
                    );
                    const userId = this.authUser.id_seq;
                    const streamId = createCustomMediaUserId(userId);
                    const stream = {
                        getId: () => streamId,
                        stream: true,
                        local: true,
                        isAudioOn: () => false,
                        isVideoOn: () => true,
                        mediaStatus: mediaTypes.CUSTOM_VIDEO,
                    };
                    this.localMediaStream = stream;
                    this.streams[streamId] = stream;
                    this.presenting = 'media';
                    this.emit(Events.streamAddedCustomMedia, {
                        stream,
                        isOwner: true,
                        userId,
                    });
                    return resolve();
                } catch (e) {
                    this.log(
                        `${STAGE_PREFIX} - publishCustomLocalStream error`,
                        e
                    );
                    return reject(e);
                }
            }
        );
        // cleanup the promise flag when all the promises inside it either resolved or rejected
        this.publishPreRecordedProgress.finally(() => {
            this.publishPreRecordedProgress = null;
        });
        return this.publishPreRecordedProgress;
    }

    async setAudioVolume(level) {
        if (!this.hasPublishPreRecordedStream()) {
            return;
        }
        if (level === 0) {
            this.muteCustomMedia();
        } else {
            this.unmuteCustomMedia();
        }
        try {
            this.log(
                `${STAGE_PREFIX} - Pre-recorded video local volume change initiate`
            );
            await this.stageRTCBroadcast.updateCustomAudioVolume(level / 10);
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - Pre-recorded video local volume change error`,
                e
            );
        }
    }

    canPlayScreenStream() {
        return this.localScreenStream?.displaySurface !== 'monitor';
    }

    async leaveBroadCastRTC() {
        if (this.joinChannelProgress) {
            this.log(
                `${STAGE_PREFIX} - leave channel request init to leave the channel which is in progress`,
                this.channelName
            );
            this.channelName = null;
        }
        if (
            !this.stageRTCBroadcast ||
            !this.channelJoined ||
            this.leaveChannelProgress
        ) {
            return;
        }
        // moved flags to publish/unpublish part to promise to handle the scenarios where unpublish is requested in between the publish,
        // so the system will unpublish after the publish successfully
        // Also, it will handle the weird cases when publish/unpublish called from multiple places
        this.leaveChannelProgress = new Promise(async (resolve) => {
            try {
                this.log(
                    `${STAGE_PREFIX} - leave channel request`,
                    this.channelName
                );
                this.localMediaStream = null;
                await this.stageRTCBroadcast.leave();
                this.resetData('leaveChannel');
                this.channelName = null;
                this.localScreenStream = null;
                this.channelJoined = null;
                this.videoClientInitChannelName = null;
                this.isVideoClientInit = null;
                this.remoteStreamAudioMute = false;
                this.videoProfile = null;
                this.log(`${STAGE_PREFIX} - leave channel success`);
                return resolve(true);
            } catch (e) {
                this.log(
                    `${STAGE_PREFIX} - leave channel error`,
                    e,
                    this.channelName
                );
                return resolve(false);
            }
        });
        // cleanup the promise flag when all the promises inside it either resolved or rejected
        this.leaveChannelProgress.finally(() => {
            this.leaveChannelProgress = null;
        });
        return this.leaveChannelProgress;
    }

    getVideoStats(env = 'local', type = 'bitrate', stream) {
        const uId = stream.getId();
        let userId = uId;
        if (isScreenShareStream(uId)) {
            userId = getScreenShareUserId(uId);
        } else if (isCustomMediaStream(uId)) {
            userId = getCustomMediaUserId(uId);
        }
        const mode = stream.isVideoOn() ? 'video' : 'audio';
        const mediaType = this.getMediaType(mode, stream.mediaStatus);
        if (type === 'stats') {
            const isLocal = env === 'local';
            return new Promise((resolved) => {
                const stats = this.stageRTCBroadcast.getRTCStats(
                    mediaType,
                    env,
                    userId
                );
                if (!stats) {
                    resolved({
                        accessDelay: 0,
                        resolution: 'n/a',
                        fps: 0,
                        networkStatus: 'n/a',
                    });
                }
                const width = isLocal
                    ? stats.sendResolutionWidth || 0
                    : stats.receiveResolutionWidth || 0;
                const height = isLocal
                    ? stats.sendResolutionHeight || 0
                    : stats.receiveResolutionHeight || 0;
                const fps = isLocal
                    ? stats.sendFrameRate
                    : stats.receiveFrameRate;
                resolved({
                    accessDelay: `${stats.accessDelay ? stats.accessDelay : 0}`,
                    resolution: `${width}x${height}`,
                    fps: `${fps || 0}`,
                    networkStatus: 'n/a',
                });
            });
        } else {
            return new Promise((resolved) => {
                const isLocal = stream.local;
                const stats = this.stageRTCBroadcast.getRTCStats(
                    mediaType,
                    isLocal ? 'local' : 'remote',
                    userId
                ) || {
                    sendBitrate: 0,
                    targetSendBitrate: 0,
                    packetLossRate: 0,
                    recvBitrate: 0,
                };
                if (isLocal) {
                    resolved({
                        sendBitrate: stats.sendBitrate,
                        targetSendBitrate: stats.targetSendBitrate,
                    });
                } else {
                    resolved({
                        packetLossRate: stats.receivePacketsLost,
                        recvBitrate: stats.receiveBitrate,
                    });
                }
            });
        }
    }

    async pinUnpinUser(userId, type) {
        try {
            this.log(`${STAGE_PREFIX} - ${type} request`, { userId, type });
            if (type === 'pin') {
                await this.stageRTCBroadcast.pinUser(userId, pinTypes.FOR_SELF);
            }
            if (type === 'unpin') {
                await this.stageRTCBroadcast.unpinUser(
                    userId,
                    pinTypes.FOR_SELF
                );
            } else if (type === 'focus') {
                await this.stageRTCBroadcast.unpinAll(pinTypes.FOR_SELF);
                await this.stageRTCBroadcast.pinUser(userId, pinTypes.FOR_SELF);
            }
        } catch (e) {
            this.log(`${STAGE_PREFIX} - ${type} error`, e);
        }
    }

    async setPinUsers(pinnedUserIds = []) {
        const prevPinUserIds = this.pinnedUserIds || [];
        this.pinnedUserIds = pinnedUserIds;

        if (pinnedUserIds.length === 0 && prevPinUserIds.length === 0) {
            return;
        }

        if (pinnedUserIds.length === 0) {
            this.log(`${STAGE_PREFIX} - Unpinned all users`);
            await this.stageRTCBroadcast.unpinAll(pinTypes.FOR_SELF);
            return;
        }

        (pinnedUserIds || []).forEach((id) => {
            if (prevPinUserIds.includes(id)) {
                return;
            }
            this.pinUnpinUser(id, 'pin');
        });

        (prevPinUserIds || []).forEach((id) => {
            if (pinnedUserIds.includes(id)) {
                return;
            }
            this.pinUnpinUser(id, 'unpin');
        });
    }

    async setMediaStream(localStream) {
        let stream = {};
        try {
            this.log(`${STAGE_PREFIX} - get Local User media Stream request`);
            stream = await this.stageRTCBroadcast.getCurrentLocalStream();
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - get Local User media Stream request error`,
                e
            );
        }
        localStream.stream = stream;
        return localStream;
    }

    async muteCustomMedia() {
        if (this.localMediaStream || this.channelJoined) {
            try {
                this.log(`${STAGE_PREFIX} - mute custom media stream request`);
                await this.stageRTCBroadcast.muteCustomAudio(false);
            } catch (e) {
                this.log(
                    `${STAGE_PREFIX} - Failed to mute custom media stream`,
                    e
                );
            }
        }
    }

    async unmuteCustomMedia() {
        if (this.localMediaStream || this.channelJoined) {
            try {
                this.log(
                    `${STAGE_PREFIX} - unmute custom media stream request`
                );
                await this.stageRTCBroadcast.muteCustomAudio(true);
            } catch (e) {
                this.log(
                    `${STAGE_PREFIX} - Failed to unmute custom media stream`,
                    e
                );
            }
        }
    }

    isRemoteStreamVideoMute(id) {
        return !this.getRemoteStreamData(id)?.isVideoOn?.();
    }

    isRemoteStreamAudioMute(id) {
        return !this.getRemoteStreamData(id)?.isAudioOn?.();
    }

    getMediaType(mode, mediaStatus) {
        switch (mediaStatus) {
            case 'camera': {
                return mode;
            }
            case mediaTypes.SCREEN: {
                return mode === 'audio'
                    ? mediaTypes.SCREEN_AUDIO
                    : mediaTypes.SCREEN;
            }
            case mediaTypes.CUSTOM_VIDEO: {
                return mode === 'audio'
                    ? mediaTypes.CUSTOM_AUDIO
                    : mediaTypes.CUSTOM_VIDEO;
            }
            case mediaTypes.CUSTOM_PDF: {
                return mediaTypes.CUSTOM_PDF;
            }
            default:
                return mediaStatus;
        }
    }

    play(uid) {
        const streamObj = this.streams[uid];
        this.log(
            `${STAGE_PREFIX} - received stream play request for user ${uid}`,
            {
                hasStreamObj: !!streamObj,
                isAudioOn: streamObj?.isAudioOn?.(),
                isVideoOn: streamObj?.isVideoOn?.(),
            }
        );
        if (streamObj?.isAudioOn?.()) {
            this.playStream({ mode: 'audio', streamObj });
        }
        if (streamObj?.isVideoOn?.()) {
            this.playStream({ mode: 'video', streamObj });
        }
    }

    playStream({ mode, streamObj }) {
        const { mediaStatus, local } = streamObj;
        const uid = streamObj.getId();
        const playerId = `${STREAM_PLAYER_PREFIX}${this.channelName}-${uid}`;
        const mediaType = this.getMediaType(mode, mediaStatus);
        const element = document.getElementById(playerId);
        // skip to play local user audio to avoid voice disturbance or echo
        if (mediaType === mediaTypes.AUDIO && local) {
            this.log(
                `${STAGE_PREFIX} - skip playing the audio for local user ${uid}`,
                {
                    mediaType,
                    local,
                }
            );
            return;
        }
        const logsParams = {
            playerId,
            local,
            mode,
            uid,
        };
        try {
            this.log(`${STAGE_PREFIX} - play ${mode} init`, logsParams);
            if (local) {
                if (
                    mediaType === mediaTypes.CUSTOM_VIDEO ||
                    mediaType === mediaTypes.CUSTOM_PDF
                )
                    this.stageRTCBroadcast.playLocalCustomStream(element);
                else this.stageRTCBroadcast.playLocalStream(mediaType, element);
            } else {
                let userId = uid;
                if (isScreenShareStream(uid)) {
                    userId = getScreenShareUserId(uid);
                } else if (isCustomMediaStream(uid)) {
                    userId = getCustomMediaUserId(uid);
                }
                this.stageRTCBroadcast.playRemoteStream(
                    userId,
                    mediaType,
                    element
                );
            }
            if (mode === 'video' && isSafariBrowser) {
                const videoElement = element.querySelector(
                    '.agora_video_player'
                );
                if (videoElement) {
                    videoElement.style.display = 'none';
                }
                setTimeout(() => {
                    const videoElement = element.querySelector(
                        '.agora_video_player'
                    );
                    if (videoElement) {
                        videoElement.style.display = 'block';
                    }
                }, 10);
            }
            this.log(`${STAGE_PREFIX} - play ${mode} success`, logsParams);
        } catch (e) {
            this.log(`${STAGE_PREFIX} - play ${mode} error`, logsParams, e);
        }
    }

    stop(uid) {
        const streamObj = this.streams[uid];
        this.log(
            `${STAGE_PREFIX} - received stream stop request for user ${uid}`,
            {
                hasStreamObj: !!streamObj,
                isAudioOn: streamObj?.isAudioOn?.(),
                isVideoOn: streamObj?.isVideoOn?.(),
            }
        );
        if (streamObj?.isAudioOn) {
            this.stopStream({ mode: 'audio', streamObj });
        }
        if (streamObj?.isVideoOn?.()) {
            this.stopStream({ mode: 'video', streamObj });
        }
    }

    stopStream({ mode, streamObj }) {
        const { mediaStatus, local } = streamObj;
        const uid = streamObj.getId();
        const mediaType = this.getMediaType(mode, mediaStatus);

        if (mediaType === mediaTypes.AUDIO && local) {
            this.log(
                `${STAGE_PREFIX} - skip stop the audio for local user ${uid}`,
                {
                    mediaType,
                    local,
                }
            );
            return;
        }
        const logsParams = {
            local,
            mode,
            uid,
            mediaType,
        };
        try {
            this.log(`${STAGE_PREFIX} - stop ${mode} init`, logsParams);
            let userId = uid;
            if (isScreenShareStream(uid)) {
                userId = getScreenShareUserId(uid);
            } else if (isCustomMediaStream(uid)) {
                userId = getCustomMediaUserId(uid);
            }
            this.stageRTCBroadcast.stopRemoteStream(userId, mediaType);
            this.log(`${STAGE_PREFIX} - stop ${mode} success`, logsParams);
        } catch (e) {
            this.log(`${STAGE_PREFIX} - stop ${mode} error`, logsParams, e);
        }
    }

    isChannelConnected() {
        return this?.isChannelOnNetwork;
    }

    unsubscribeStream() {
        return;
    }

    stopFilter() {
        if (this.stageRTCBroadcast) {
            this.stageRTCBroadcast.removeFilter();
        }
    }

    setStreamAudioOutput() {
        if (this.stageRTCBroadcast) {
            const deviceId = localStorage.getItem(PREF_AUDIO_OUT);
            this.stageRTCBroadcast.setSpeaker(deviceId);
            this.log(
                `${STAGE_PREFIX} - Audio output device change to ${deviceId}`
            );
        }
    }

    async muteRemoteUsersLocally(isMute) {
        if (this.remoteStreamAudioMute === isMute) {
            this.log(`${STAGE_PREFIX} - skip to toggle stage audio`, {
                isMute,
            });
            return;
        }
        try {
            this.log(`${STAGE_PREFIX} - remote user toggle stage audio init`, {
                isMute,
            });

            this.remoteStreamAudioMute = isMute;
            await this.stageRTCBroadcast.muteConference(isMute);
            this.log(
                `${STAGE_PREFIX} - remote user toggle stage audio success`,
                {
                    isMute,
                }
            );
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - remote user toggle stage audio error`,
                { isMute },
                e
            );
        }
    }

    async changeVolumeLevelLocally(volume) {
        if (this.joinChannelProgress) {
            this.log(
                `${STAGE_PREFIX} - Register to pending changeVolumeLevelLocally request[do immediate after joinChannelProgress]`
            );
            await this.joinChannelProgress;
            this.log(
                `${STAGE_PREFIX} - Released pending changeVolumeLevelLocally request[do immediate after joinChannelProgress]`
            );
        }
        try {
            this.log(`${STAGE_PREFIX} - remote user volume change init`, {
                volume,
            });

            this.remoteStreamAudioMute = volume;
            this.stageRTCBroadcast.changeVolumeLevel({
                userId: null,
                isLocalUser: false,
                changeUserAudioOnly: false,
                level: volume,
            });
            this.log(`${STAGE_PREFIX} - remote user volume change success`, {
                volume,
            });
            this.log(`${STAGE_PREFIX} - calling the muteConference init`, {
                volume,
                muteConference: !volume,
            });
            if (volume === 0) {
                await this.stageRTCBroadcast.muteConference(true);
            } else {
                await this.stageRTCBroadcast.muteConference(false);
            }
            this.log(`${STAGE_PREFIX} - calling the muteConference success`, {
                volume,
                muteConference: !volume,
            });
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - remote user volume change error`,
                { volume },
                e
            );
        }
    }
    hasScreenStream() {
        return !!this.getLocalScreenShareStream();
    }

    leaveFromStage() {
        this.endScreenShare();
        this.unpublishStream();
    }

    handRiseLeave() {
        this.logDataToSDK({
            msg: 'userRoleUpdateSource - Raise hand withdrawn',
        });
        this.leaveFromStage();
    }

    hasStreamPublish() {
        return Boolean(this.getLocalUserStream());
    }

    handRiseActive(props = emptyObject) {
        this.logDataToSDK({
            msg: 'userRoleUpdateSource - Raise hand accepted',
        });
        this.publishStream(props);
    }

    publishStreamIfNot(props) {
        if (this.getLocalUserStream()) {
            return Promise.resolve(false);
        }
        return this.publishStream({ attendeeMode: 'video', ...props });
    }

    replayStreams() {
        logger.info('Starting replay all the streams');
        this.getStreams().forEach((stream) => {
            this.play(stream.getId());
        });
    }

    publishCustomMediaStream(...props) {
        return this.publishCustomLocalStream(...props);
    }

    unpublishCustomMediaStream() {
        return this.unpublishCustomLocalStream();
    }

    publishScreenStream(props = {}) {
        return this.shareScreen(props);
    }

    stopSharing() {
        return this.endScreenShare();
    }

    // this is upgrade/downgrade the user priority.
    // In persistent backstage if user moved to stage, stage priority will be increased and decreased from backstage
    async updateUserPriority(isPrioritized) {
        try {
            this.log(`${STAGE_PREFIX} - Update user priority init`, {
                isPrioritized,
            });
            this.stageRTCBroadcast.setOwnPriorityForVoiceActivity(
                isPrioritized
            );
            this.log(`${STAGE_PREFIX} - Update user priority success`, {
                isPrioritized,
            });
        } catch (e) {
            this.log(
                `${STAGE_PREFIX} - Update user priority error`,
                { isPrioritized },
                e
            );
        }
    }
}
