import { useContainerNotifications } from 'atoms/Container/container';
import { useLiveEventClient } from 'hooks/live-airmeet';
import { useLiveEventClientPersistent } from 'hooks/live-airmeet';
import useHideUserStreams from 'hooks/live-airmeet/useHideUserStreams';
import useGetRTCServiceName from 'hooks/useGetRTCServiceName';
import { attachAccessCodeToLiveStreamingURL } from 'hooks/live-airmeet/useSessionRecording';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionLiteModeStreamDetails } from 'store/actions/airmeet';
import { getAirmeet } from 'store/selectors';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import { getChannelLiveStreamingUrl } from 'utils/common';
import { LEAVE_STAGE_RTC_CHANNEL } from 'utils/constants/containers/notifications';
import {
    PERSISTENT_STAGE_LOCATIONS,
    RTMP_STREAMING_PROVIDERS,
    STREAMING_RESOLUTIONS,
} from 'utils/constants/sessions.js';
import { logger } from 'utils/logger';
import PermissionUtils from 'utils/permission-utils';
import { getStreamingResolution, useLiveSessionContext } from './Session';

export const StageRTC = React.createContext(null);
export const BackstageRTC = React.createContext(null);

const StageRTCProviderWrapper = (props) => {
    const isPersistentBackstageEnabled = useLiveSessionContext(
        (ctx) => ctx.isPersistentBackstageEnabled
    );

    if (isPersistentBackstageEnabled) {
        return <StageRTCProviderPersistent {...props} />;
    }

    return <StageRTCProviderNonPersistent {...props} />;
};

const StageRTCProviderPersistent = (props) => {
    const {
        mode = PERSISTENT_STAGE_LOCATIONS.LIVESTAGE,
        allowMinRoleHost = false,
    } = props;
    const {
        airmeet: {
            data: { currentAirmeet, currentState },
        },
    } = useLiveAirmeetContext();
    const airmeet = useSelector(getAirmeet) || {};
    const { currentSession, userStageRole, userRole } = useLiveSessionContext();
    const streamingResolution = useLiveSessionContext(getStreamingResolution);

    const backstageServiceName = useGetRTCServiceName(
        PERSISTENT_STAGE_LOCATIONS.BACKSTAGE
    );
    const liveStageServiceName = useGetRTCServiceName(
        PERSISTENT_STAGE_LOCATIONS.LIVESTAGE
    );

    // const serviceName = useGetRTCServiceName(mode);
    const liveStageService = useMemo(() => {
        logger.info(
            `Created Stage Service for persistent stageId: ${liveStageServiceName}`
        );
        return getAirmeetUtilInstance().getStageService(liveStageServiceName);
    }, [liveStageServiceName]);
    const backstageService = useMemo(() => {
        logger.info(
            `Created Stage Service for persistent stageId: ${backstageServiceName}`
        );
        return getAirmeetUtilInstance().getStageService(backstageServiceName);
    }, [backstageServiceName]);

    const stageService =
        mode === PERSISTENT_STAGE_LOCATIONS.BACKSTAGE
            ? backstageService
            : liveStageService;

    const isMediaModeActive = useHideUserStreams({ stageService, mode });
    const channelDetails = useLiveEventClientPersistent({
        stageService,
        allowMinRoleHost,
        currentSession,
        airmeet: currentAirmeet,
        userStageRole,
        userRole,
        currentState,
        streamVideoProfile: streamingResolution,
        disableAirmeetBackstage:
            airmeet.parallelTrackData &&
            airmeet.parallelTrackData.isParallelTrackEvent,
        isMediaModeActive,
        mode,
    });
    if (mode === PERSISTENT_STAGE_LOCATIONS.BACKSTAGE) {
        return (
            <BackstageRTCProvider
                {...props}
                channelDetails={channelDetails}
                stageService={backstageService}
            />
        );
    }
    return (
        <StageRTCProvider
            {...props}
            channelDetails={channelDetails}
            stageService={liveStageService}
            backstageService={backstageService}
            backstageServiceName={backstageServiceName}
            liveStageServiceName={liveStageServiceName}
            isMediaModeActive={isMediaModeActive}
        />
    );
};

const StageRTCProviderNonPersistent = (props) => {
    const { mode = PERSISTENT_STAGE_LOCATIONS.LIVESTAGE } = props;
    const {
        airmeet: {
            data: { currentAirmeet, currentState },
        },
    } = useLiveAirmeetContext();
    const airmeet = useSelector(getAirmeet) || {};
    const { currentSession, userStageRole, userRole } = useLiveSessionContext();
    const streamingResolution = useLiveSessionContext(getStreamingResolution);

    const serviceName = useGetRTCServiceName(mode);
    const stageService = useMemo(() => {
        logger.info(
            `Created Stage Service for non persistent stageId: ${serviceName}`
        );
        return getAirmeetUtilInstance().getStageService(serviceName);
    }, [serviceName]);

    const isMediaModeActive = useHideUserStreams({ stageService });
    const channelDetails = useLiveEventClient({
        stageService,
        currentSession,
        airmeet: currentAirmeet,
        userStageRole,
        userRole,
        currentState,
        streamVideoProfile: streamingResolution,
        disableAirmeetBackstage:
            airmeet.parallelTrackData &&
            airmeet.parallelTrackData.isParallelTrackEvent,
    });

    return (
        <StageRTCProvider
            {...props}
            channelDetails={channelDetails}
            stageService={stageService}
            isMediaModeActive={isMediaModeActive}
        />
    );
};

const StageRTCProvider = ({
    children,
    channelDetails,
    stageService,
    backstageService,
    liveStageServiceName,
    backstageServiceName,
    isMediaModeActive,
}) => {
    const {
        featureDataClients: { stage: firebaseClient },
        airmeet: {
            data: { currentAirmeet },
        },
    } = useLiveAirmeetContext();
    const isPersistentBackstageEnabled = useLiveSessionContext(
        (ctx) => ctx.isPersistentBackstageEnabled
    );
    const dispatch = useDispatch();
    const { stageId, is1080PSession } = useLiveSessionContext();

    const { onNotify, offNotify } = useContainerNotifications();

    // [for persistent backstage] hide/display stage/backstage users
    const [selectedPersistentBarTab, setSelectedPersistentBarTab] = useState(
        PERSISTENT_STAGE_LOCATIONS.STAGE
    );

    const {
        joinedStageChannel,
        joinChannelError,
        stageJoining,
        setAllowToPublish,
        allowToPublish,
        leaveStageChannel,
        setDisableAutoJoin,
        channelJoined,
        stageChannelId,
        channelName,
    } = channelDetails;

    useEffect(() => {
        onNotify(LEAVE_STAGE_RTC_CHANNEL, leaveStageChannel);
        return () => {
            offNotify(LEAVE_STAGE_RTC_CHANNEL, leaveStageChannel);
        };
    }, [leaveStageChannel, onNotify, offNotify]);

    useEffect(() => {
        return () => {
            leaveStageChannel();
        };
    }, []);

    const getLiteModeRTMPStreamingURL = useCallback(
        async (userAccessCode) => {
            try {
                const response = await dispatch(
                    getSessionLiteModeStreamDetails(
                        currentAirmeet.airmeetId,
                        stageId,
                        {
                            stream_type: 'lite-mode',
                            quality: is1080PSession
                                ? STREAMING_RESOLUTIONS['1080']
                                : STREAMING_RESOLUTIONS['720'],
                        }
                    )
                );

                let liteModeURL = null;
                let providerName = null;
                logger.debug('litemode stream data ', {
                    ...response.payload,
                    stream_key: !!response.payload?.stream_url,
                });
                if (
                    response &&
                    response.payload &&
                    response.payload?.status &&
                    response.payload?.stream_key &&
                    response.payload?.stream_url
                ) {
                    const {
                        stream_key,
                        stream_url,
                        stream_provider: provider,
                    } = response?.payload;
                    liteModeURL = `${stream_url}/${stream_key}`;
                    providerName = provider;
                } else {
                    const liteModeStreamingURL = getChannelLiveStreamingUrl(
                        stageId,
                        is1080PSession
                    );
                    providerName = RTMP_STREAMING_PROVIDERS.airmeet;
                    liteModeURL = liteModeStreamingURL;
                }
                if (providerName === RTMP_STREAMING_PROVIDERS.airmeet) {
                    const liteModeStreamingURL = attachAccessCodeToLiveStreamingURL(
                        liteModeURL,
                        {
                            code: userAccessCode,
                        }
                    );
                    liteModeURL = liteModeStreamingURL;
                }
                return { liteModeURL, provider: providerName };
            } catch (err) {
                logger.Error(
                    `Failed to fetch litemode stream details, Err: ${err}`
                );
            }
        },
        [currentAirmeet.airmeetId, dispatch, is1080PSession, stageId]
    );

    useEffect(() => {
        if (!PermissionUtils.isSessionCloudHost()) return;
        firebaseClient.setTraceLoggerEnabled(
            PermissionUtils.isSessionCloudHost()
        );
        return () => {
            firebaseClient.setTraceLoggerEnabled(false);
        };
    }, []);

    return (
        <StageRTC.Provider
            value={{
                joinedStageChannel,
                joinChannelError,
                allowToPublish,
                setAllowToPublish,
                setDisableAutoJoin,
                channelJoined,
                stageJoining,
                stageService,
                isMediaModeActive,
                stageChannelId,
                channelName,
                getLiteModeRTMPStreamingURL,
                ...(isPersistentBackstageEnabled && {
                    selectedPersistentBarTab,
                    setSelectedPersistentBarTab,
                    backstageServiceName,
                    liveStageServiceName,
                    backstageService,
                }),
            }}
        >
            {children}
        </StageRTC.Provider>
    );
};

const BackstageRTCProvider = ({ children, channelDetails, stageService }) => {
    const { onNotify, offNotify } = useContainerNotifications();

    const {
        joinedStageChannel,
        joinChannelError,
        stageJoining,
        setAllowToPublish,
        allowToPublish,
        leaveStageChannel,
        setDisableAutoJoin,
        channelJoined,
        stageChannelId,
        channelName,
    } = channelDetails;

    useEffect(() => {
        onNotify(LEAVE_STAGE_RTC_CHANNEL, leaveStageChannel);
        return () => {
            offNotify(LEAVE_STAGE_RTC_CHANNEL, leaveStageChannel);
        };
    }, [leaveStageChannel, onNotify, offNotify]);

    useEffect(() => {
        return () => {
            leaveStageChannel();
        };
    }, []);

    return (
        <BackstageRTC.Provider
            value={{
                joinedStageChannel,
                joinChannelError,
                allowToPublish,
                setAllowToPublish,
                setDisableAutoJoin,
                channelJoined,
                stageJoining,
                stageService,
                stageChannelId,
                channelName,
            }}
        >
            {children}
        </BackstageRTC.Provider>
    );
};

export default StageRTCProviderWrapper;

export function useStageRTCContext(callback = (context) => context) {
    // The stage RTC context
    const ctx = useContext(StageRTC);

    // Return the up-to-date selected value
    return callback(ctx);
}

export function useBackStageRTCContext(callback = (context) => context) {
    // The stage RTC context
    const ctx = useContext(BackstageRTC);

    // Return the up-to-date selected value
    return callback(ctx);
}
