import InputV2 from 'atoms/Input';
import SVGSprite from 'components/general/SVGSprite';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { alpha } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text';
import { Typography } from 'foundations/typography';
import isFunction from 'lodash/isFunction';
import React, { useState } from 'react';
import styled from 'styled-components';
import { border, color, layout, space } from 'styled-system';

const noop = () => {};

const InputField = React.forwardRef((props, ref) => {
    const {
        onChange = noop,
        children,
        style = {},
        labelStyle = {},
        labelColor = 'ambience.10',
        type = 'text',
        name = '',
        value = null,
        id = '',
        placeholder = '  ',
        required = false,
        labelPlaceholder = '',
        labelText = '',
        labelHtmlFor = '',
        showLimit = false,
        maxLength,
        floatLabel = false,
        state = 'normal',
        variant = '',
        errorMsg,
        onKeyDown,
        password = false,
        togglePassword = noop,
        icon,
        showLeftIcon = true,
        iconstyle,
        zIndexLabel,
        isMandatory = false,
        inlineCharCount = false,
        hideReqdError = false,
        onMouseUp,
        onDblClick,
        reqdErrorMsg = '  cannot be empty',
        isDisabled = false,
        clearInput = false,
        showClearOnValue = false,
        clearStyle = {},
        onClear = noop,
        wrapperStyle = {},
        charCountStyle = {},
        inputColor = 'ambience.5',
        wrapperClass = '',
        passwordShowLabel = 'Show/hide password',
        socialAuthUser,
        shouldShowCharCountOnFocusOnly = false,
        inputErrorBorderColor = '',
        onFocus = null,
        onBlur = null,
        isThemeV2 = false,
        ariaLabel = '',
        wrapperMargin = 2,
        showErrorLine,
        ...rest
    } = props || {};

    const [showCharCountBox, setShowCharCountBox] = useState(false);

    const renderCharLimit = () => {
        return (
            <Typography
                variant={'subtext2'}
                color='ambience.0'
                style={{
                    padding: '3px 0 5px 0',
                }}
            >
                {value !== null ? value?.length || 0 : 0}/{maxLength}
            </Typography>
        );
    };

    const onValueChange = (e) => {
        onChange(e);
    };

    const showPasswordIcon = password && value && value.length > 0;

    return isThemeV2 ? (
        <FlexDiv
            pb='x6'
            flexDirection='column'
            alignItems='stretch'
            className={wrapperClass}
        >
            <InputV2
                ref={ref}
                id={id}
                type={type}
                name={name}
                required={required}
                onChange={onValueChange}
                placeholder={placeholder}
                value={value}
                maxLength={maxLength}
                onKeyDown={onKeyDown}
                onDoubleClick={onDblClick}
                onMouseUp={onMouseUp}
                disabled={isDisabled}
                aria-required={required ? true : false}
                aria-label={ariaLabel}
                label={labelText ? `${labelText}${isMandatory ? '*' : ''}` : ''}
                errorMsg={errorMsg}
            />
        </FlexDiv>
    ) : (
        <>
            <InputWrapper
                mb={wrapperMargin}
                variant={variant}
                state={state}
                className={`input-wrapper ${wrapperClass}`}
                errorMsg={errorMsg}
                style={{ ...(wrapperStyle || {}) }}
            >
                {icon && showLeftIcon && <StyledSvgSprite icon={icon} />}
                <InputDom
                    ref={ref}
                    id={id}
                    type={type}
                    name={name}
                    required={required || isMandatory}
                    onChange={onValueChange}
                    style={{ ...style }}
                    placeholder={placeholder}
                    value={value}
                    maxLength={maxLength}
                    variant={variant}
                    state={state}
                    onKeyDown={onKeyDown}
                    {...rest}
                    color={inputColor}
                    floatLabel={floatLabel}
                    onDoubleClick={onDblClick}
                    onMouseUp={onMouseUp}
                    disabled={isDisabled}
                    aria-required={required ? true : false}
                    aria-describedby={`${id}-error`}
                    aria-invalid={errorMsg ? true : false}
                    errorMsg={errorMsg}
                    shouldShowCharCountOnFocusOnly={
                        shouldShowCharCountOnFocusOnly
                    }
                    onFocus={(event) => {
                        if (isFunction(onFocus)) onFocus(event);

                        if (shouldShowCharCountOnFocusOnly) {
                            setShowCharCountBox(true);
                        }
                    }}
                    onBlur={(event) => {
                        if (isFunction(onBlur)) onBlur(event);

                        if (shouldShowCharCountOnFocusOnly) {
                            setShowCharCountBox(false);
                        }
                    }}
                    inputErrorBorderColor={inputErrorBorderColor}
                    showPasswordIcon={showPasswordIcon}
                >
                    {children}
                </InputDom>

                {(labelText || state === 'error') && (
                    <LabelDom
                        htmlFor={labelHtmlFor}
                        placeholder={labelPlaceholder}
                        variant={variant}
                        color={labelColor}
                        floatLabel={floatLabel}
                        value={value}
                        labelStyle={{ ...labelStyle }}
                        zIndexLabel={zIndexLabel}
                    >
                        {state === 'error' ? (
                            <InputError variant={variant}>
                                {required && !hideReqdError
                                    ? value.length > 0
                                        ? errorMsg
                                        : socialAuthUser
                                        ? 'Password' + reqdErrorMsg
                                        : labelText + reqdErrorMsg
                                    : errorMsg}
                            </InputError>
                        ) : (
                            `${labelText}${isMandatory ? '*' : ''}`
                        )}
                    </LabelDom>
                )}
                {showErrorLine &&
                    state === 'error' &&
                    (required || isMandatory) && (
                        <Text
                            variant='caption'
                            color='semantic.1'
                            id={`${id}-error`}
                            role='alert'
                            aria-live='assertive'
                            mt={0}
                        >
                            {labelText} is required
                        </Text>
                    )}
                {showPasswordIcon && (
                    <ToggleShowPassword
                        onClick={togglePassword}
                        tabIndex={0}
                        role='button'
                        aria-label={passwordShowLabel}
                    >
                        <SVGSprite
                            style={iconstyle ? iconstyle : {}}
                            icon={icon}
                        />
                    </ToggleShowPassword>
                )}
                {maxLength && showLimit && inlineCharCount && (
                    <CharCountDom
                        isClearInput={clearInput}
                        className={'charCount'}
                    >
                        {renderCharLimit()}
                    </CharCountDom>
                )}
                {(clearInput || showClearOnValue) && (
                    <InputClear
                        onClick={onClear}
                        isDisabled={
                            showClearOnValue
                                ? value.length > 0
                                    ? false
                                    : true
                                : false
                        }
                        clearStyle={clearStyle}
                    >
                        <SVGIcon
                            icon={'icon-dismiss-cross'}
                            width={8}
                            height={8}
                        />
                    </InputClear>
                )}
            </InputWrapper>
            {maxLength && showLimit && !inlineCharCount && (
                <CharCountDom
                    className={'CharCountbox'}
                    style={{ ...charCountStyle }}
                    shouldShowCharCountOnFocusOnly={
                        shouldShowCharCountOnFocusOnly
                    }
                    showCharCountBox={showCharCountBox}
                >
                    {renderCharLimit()}
                </CharCountDom>
            )}
        </>
    );
});

export default InputField;

const SVGIcon = styled(SVGSprite)`
    fill: ${({ theme }) =>
        theme.isLightTheme && theme?.isDashboardBrandingPage
            ? theme.colors.ambience[0]
            : alpha(theme.colors.white, 1)};
`;

const InputWrapper = styled.div`
    ${layout}
    ${space}
    ${color}
    ${border}
    width: 100%;
    position: relative;
    background: ${({ theme }) =>
        theme.isLightTheme ? 'transparent' : theme.colors.ambience[14]};
    border: 1px solid transparent;
    border-radius: 8px;
    font-family: ${({ theme }) => theme.fonts.book};
    ${({ variant, theme }) =>
        variant === 'light' &&
        `
        margin: 2px 0;
        background: ${theme.colors.ambience[0]};
    `}
    ${(props) =>
        props.variant === 'nolabel' &&
        props.state !== 'error' &&
        `
        margin: 0;
    `}
    ${(props) =>
        (props.variant === 'nolabel' || props.variant === 'hasIcon') &&
        props.state === 'error' &&
        `
        border-color: ${props.theme.colors.semantic[1]};
    `}

    background: transparent;
`;
const InputDom = styled.input`
    ${layout}
    ${space}
    ${color}
    ${border}
    width: 100%;
    outline: none;
    padding: 10px 15px;
    font-style: normal;
    font-size: 16px;
    line-height: 1.2;
    height: 60px;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 8px;
    transition: 0.2s ease-in-out;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.fonts.book};
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    background: ${({ theme }) =>
        theme.isLightTheme ? 'transparent' : theme.colors.ambience[22]};
    &:focus + label,
    &:not(:placeholder-shown) + label {
        font-size: 12px;
        top: 0;
        background: transparent;
        color: ${({ theme }) => theme.colors.ambience[13]};
        transition: 0.2s ease-in-out;
    }
    &:focus ~ .charCount,
    &:valid ~ .charCount {
        top: 20px;
        transition: 0.2s ease-in-out;
    }
    ${({ theme, value }) =>
        value !== '' &&
        `
        padding-top: 22px;
        padding-bottom: 8px;
        + label {
            font-size: 12px;
            top: 0;
            background: transparent;
            color: ${theme.colors.ambience[13]};
            transition: 0.2s ease-in-out;
        }
        ~ .charCount {
            top: 20px;
            transition: 0.2s ease-in-out;
        }
    `}

    &:focus,
    &:valid {
        padding-top: 22px;
        padding-bottom: 8px;
    }

    ${({ theme, variant }) =>
        variant === 'light' &&
        `
        box-sizing: border-box;
        background: transparent;
        color: ${theme.colors.ambience[18]};
        height: 54px;
        &:focus{
            color: ${theme.colors.ambience[18]};
        }
    `}
    ${({ variant, theme }) =>
        (variant === 'clear' ||
            variant === 'nolabel' ||
            variant === 'hasIcon') &&
        `
        background: transparent;
    `}
    ${(props) =>
        props.variant === 'nolabel' &&
        props.state !== 'error' &&
        `
        padding-top: 8px;
        
        &:focus,
        &:valid{
            padding-top: 8px;
        }
    `}
    &::placeholder {
        color: ${(props) => props.theme.colors.ambience[13]};
    }

    ${(props) =>
        props.variant === 'hasIcon' &&
        `
        padding-top: 8px;
        padding-left: 56px;
        
        &:focus,
        &:valid{
            padding-top: 8px;
        }
    `}

    ${({ state, theme }) =>
        state === 'empty' &&
        `
            color: ${theme.colors.ambience[6]};
    `}
    ${(props) =>
        (props.state === 'loading' || props.state === 'success') &&
        `
            pointer-events: none;
    `}
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px
            ${({ theme }) =>
                theme.isLightTheme
                    ? theme.colors.white
                    : theme.colors.ambience[21]}
            inset;
        -webkit-text-fill-color: ${({ theme }) =>
            theme.colors.ambience[5]} !important;
        border: ${({ theme }) =>
            theme.isLightTheme
                ? `1px solid ${theme.colors.ambience[15]}`
                : `1px solid ${theme.colors.ambience[21]}`};
        caret-color: ${({ theme }) =>
            theme.isLightTheme ? theme.colors.black : theme.colors.white};
        transition: background-color 5000s ease-in-out 5000s;
    }
    ${({ floatLabel, theme }) =>
        floatLabel === true &&
        `   
            padding-top: 22px;
            padding-bottom: 8px;
            &:focus + label, &:valid + label{
                top: 5px;
                color: ${theme.colors.ambience[0]};
            }
    `}
    &.input-mobile-large {
        @media (max-width: 470px) {
            height: 95px;
            padding-top: 35px;
        }
    }
    ${({ hideInputArrows }) =>
        hideInputArrows &&
        `   
            /* Chrome, Safari, Edge, Opera */
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            /* Firefox */
            &[type=number] {
                -moz-appearance: textfield;
            }
    `}

    background: transparent;
    border: ${({ theme }) =>
        theme.isLightTheme
            ? `1px solid ${theme.colors.ambience[15]}`
            : `1px solid ${theme.colors.ambience[14]}`};
    &:not(:disabled):hover {
        border: 1px solid
            ${({ theme }) =>
                theme.isLightTheme
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
    }
    &:focus {
        border: 1px solid
            ${({ theme }) =>
                theme.isLightTheme
                    ? theme.colors.accentPrimary[1]
                    : theme.colors.accentPrimary[0]};
        box-shadow: 0px 0px 2px 2px
            ${({ theme }) =>
                theme.isLightTheme
                    ? alpha(theme.colors.accentPrimary[1], 0.4)
                    : alpha(theme.colors.accentPrimary[0], 0.4)};
    }
    &:disabled{
        opacity: 0.6;
        cursor: not-allowed;
    }

    ${({ errorMsg, theme, inputErrorBorderColor }) =>
        errorMsg &&
        `
        border-color: ${
            inputErrorBorderColor
                ? inputErrorBorderColor
                : theme.colors.semantic[1]
        } !important;
        box-shadow: none !important;
    `}

    ${({ showPasswordIcon, theme }) =>
        theme.isNewOnboardingPage &&
        showPasswordIcon &&
        `
        padding-right: 40px;
    `}
`;

const InputError = styled.span`
    color: ${({ theme }) => theme.colors.semantic[1]};
    ${(props) =>
        props.variant === 'hasIcon' &&
        `
            position: absolute;
            left: 56px;
            top: 5px;
    `}
`;

const LabelDom = styled.label`
    ${layout}
    ${space}
    ${color}
    ${border}
    position: absolute;
    left: 0;
    text-align: left;
    transition: 0.2s ease-in-out;
    font-weight: normal;
    font-size: 16px;
    pointer-events: none;
    ${(props) => `
        z-index: ${props.zIndexLabel ? props.zIndexLabel : 0};	
    `}

    cursor: text;
    ${({ theme, variant }) =>
        variant === 'light' &&
        `	
        color: ${theme.colors.ambience[13]};	
    `}
    font-family: ${({ theme }) => theme.fonts.book};
    top: 8px;
    bottom: 0;
    padding: 8px 15px;
    margin-bottom: 13px;
    width: 100%;
    word-break: break-word;

    ${(props) => props.labelStyle && props.labelStyle}
    ${({ theme, floatLabel }) =>
        floatLabel === true &&
        `   
            font-size: 12px;
            top: 5px;
            left: 15px;
            z-index: 1;
            padding: 0px;
            background: transparent;
            color: ${theme.colors.ambience[0]};
            transition: 0.2s ease-in-out;
    `}
`;

const ToggleShowPassword = styled.span`
    position: absolute;
    top: 20px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
    .icon {
        width: 24px;
        height: 24px;
        fill: ${({ theme }) => theme.colors.ambience[13]};
    }
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: ${({ theme }) =>
            theme.isNewOnboardingPage
                ? `2px solid ${theme.colors.accentPrimary[1]}`
                : 'solid'};
    }
`;

const CharCountDom = styled.div`
    ${layout}
    ${space}
    ${color}
    ${border}
    font-family: ${({ theme }) => theme.fonts.book};
    justify-content: flex-end;
    display: flex;
    z-index: 0;
    cursor: text;
    ${({ theme, variant }) =>
        variant === 'light' &&
        `
        color: ${theme.colors.ambience[13]};
    `}
    font-family: ${({ theme }) => theme.fonts.book};
    top: 8px;
    bottom: 0;
    padding: 0 15px 8px 15px;
    margin: -10px 0 13px;
    width: 100%;
    &.charCount {
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: ${({ isClearInput }) => (isClearInput ? '56px' : '10px')};
        z-index: 2;
        margin: 0;
        padding: 0;
        bottom: inherit;
        p {
            padding: 0;
        }
    }
    ${({ shouldShowCharCountOnFocusOnly, showCharCountBox }) =>
        shouldShowCharCountOnFocusOnly &&
        `
        visibility: ${showCharCountBox ? 'visible' : 'hidden'};
    `}
`;

const StyledSvgSprite = styled(SVGSprite)`
    width: 20px;
    height: 20px;
    fill: ${({ theme, icon }) =>
        theme.isLightTheme
            ? icon === 'search-icon'
                ? theme.colors.ambience[9]
                : theme.colors.ambience[10]
            : icon === 'search-icon'
            ? theme.colors.ambience[13]
            : theme.colors.ambience[3]};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 17px;
    z-index: 2;
    &:hover {
        fill: ${({ theme, icon }) =>
            theme.isLightTheme
                ? icon === 'search-icon'
                    ? theme.colors.ambience[9]
                    : theme.colors.ambience[10]
                : icon === 'search-icon'
                ? theme.colors.ambience[13]
                : theme.colors.ambience[3]};
    }
`;

const InputClear = styled.div`
    width: 24px;
    height: 24px;
    background: ${({ theme }) =>
        theme?.isLightTheme && theme?.isDashboardBrandingPage
            ? theme.colors.ambience[22]
            : alpha(theme.colors.ambience[23], 0.64)};
    border-radius: 100%;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    cursor: pointer;
    text-align: center;
    line-height: 22px;
    display: ${({ isDisabled }) => (isDisabled ? 'none' : 'block')};

    ${({ clearStyle }) => clearStyle && clearStyle}
`;
