import { StyledSvgSprite } from 'components/general/SVGSprite';
import { alpha } from 'foundations/theme';
import useTheme from 'hooks/useTheme';
import useWhiteLabelling from 'hooks/useWhitelabelling';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { background, border, color, flexbox, space } from 'styled-system';
import { ExternalUrls } from 'utils/redirections';
import { getCurrentTenantData } from 'utils/tenant';
import { Typography } from '../../../foundations/typography';
import { cdnImage } from '../../../utils/core';
import HomeFooter from '../../molecules/footer/footer';

export default function Footer({ custom, isDashboard }) {
    const {
        hidden,
        marginBottom = '0',
        showHomeV2Footer = false,
    } = useSelector((state) => state.layout.footer);
    const isEventSeries = useSelector(
        (state) => !!state?.eventSeries?.combinedInfo?.seriesInfo?.uid
    );
    // Bypass store managed footer state for custom mode
    const showFooter = custom ? true : hidden === false;

    const handleCTAClick = (destination) => {
        window.open(destination, '_self');
    };

    const { theme, isLightTheme } = useTheme();
    const {
        whiteLabelText,
        isWhiteLabellingEnabled: isEnabled,
        isAirmeetBrandingRestricted,
    } = useWhiteLabelling();
    const hostname = window?.location?.hostname || '';
    const isWhiteLabellingEnabled =
        isEnabled ||
        (hostname &&
            hostname !== '' &&
            !hostname.includes('airmeet.com') &&
            !hostname.includes('localhost'));

    return showFooter ? (
        <>
            <MainFooter
                className='h-footer'
                style={{ marginBottom, paddingBottom: 0 }}
                bg={isLightTheme && theme.colors.white}
                border={isLightTheme && 'none'}
            >
                <div className='container-fluid'>
                    {isDashboard ? (
                        <Box justifyContent='space-between' alignItems='center'>
                            <Box>
                                <Typography
                                    variant='caption'
                                    color='ambience.6'
                                    mr={3}
                                >
                                    {whiteLabelText(
                                        `© ${new Date().getFullYear()} Airmeet Inc.`,
                                        ''
                                    )}
                                </Typography>

                                <Typography
                                    variant='caption'
                                    color='ambience.6'
                                    mr={3}
                                >
                                    <a
                                        onClick={() =>
                                            handleCTAClick(
                                                ExternalUrls.PRIVACY_POLICY
                                            )
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Privacy Policy
                                    </a>
                                </Typography>

                                <Typography
                                    variant='caption'
                                    color='ambience.6'
                                >
                                    <a
                                        onClick={() =>
                                            handleCTAClick(
                                                ExternalUrls.TERMS_OF_USE
                                            )
                                        }
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Terms of Use
                                    </a>
                                </Typography>
                            </Box>
                            <div className='social-links'>
                                <a
                                    href={ExternalUrls.AIRMEET_FACEBOOK}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <i className='fa fa-facebook-square'></i>
                                </a>
                                <a
                                    href={ExternalUrls.AIRMEET_TWITTER}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <i
                                        className='fa fa-twitter-square'
                                        aria-hidden='true'
                                    ></i>
                                </a>
                                <a
                                    href={ExternalUrls.AIRMEET_LINKEDIN}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    <i
                                        className='fa fa-linkedin-square'
                                        aria-hidden='true'
                                    ></i>
                                </a>
                            </div>
                        </Box>
                    ) : (
                        <>
                            <div className='row'>
                                <div
                                    className='col-md-3 col-lg-2'
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <span className='logo'>
                                        {isWhiteLabellingEnabled ||
                                        isAirmeetBrandingRestricted ||
                                        isEventSeries ? null : (
                                            <img
                                                src={
                                                    isLightTheme
                                                        ? cdnImage(
                                                              getCurrentTenantData(
                                                                  'logoLightThemeUrl'
                                                              )
                                                          )
                                                        : cdnImage(
                                                              getCurrentTenantData(
                                                                  'logoUrl'
                                                              )
                                                          )
                                                }
                                                alt='Airmeet'
                                                className='mw-100'
                                            />
                                        )}
                                    </span>
                                </div>
                                <FooterContainer className='col-md-6 col-lg-7'>
                                    <UnorderedList className='no-style f-nav-a'>
                                        <li>
                                            <Anchor
                                                onClick={() =>
                                                    handleCTAClick(
                                                        ExternalUrls.PRIVACY_POLICY
                                                    )
                                                }
                                                style={{ cursor: 'pointer' }}
                                                color={
                                                    isLightTheme
                                                        ? theme.colors
                                                              .ambience[4]
                                                        : theme.colors
                                                              .ambience[6]
                                                }
                                                role='link'
                                                tabIndex={0}
                                                aria-label='Airmeet Privacy Policy'
                                            >
                                                Privacy Policy
                                            </Anchor>
                                        </li>
                                        <li>
                                            <Anchor
                                                onClick={() =>
                                                    handleCTAClick(
                                                        ExternalUrls.TERMS_OF_USE
                                                    )
                                                }
                                                style={{ cursor: 'pointer' }}
                                                color={
                                                    isLightTheme
                                                        ? theme.colors
                                                              .ambience[4]
                                                        : theme.colors
                                                              .ambience[6]
                                                }
                                                role='link'
                                                tabIndex={0}
                                                aria-label='Airmeet Term of Use'
                                            >
                                                Terms of Use
                                            </Anchor>
                                        </li>
                                    </UnorderedList>
                                </FooterContainer>
                                <div className='col-md-3'>
                                    {!isWhiteLabellingEnabled ? (
                                        <div
                                            className={`social-links ${
                                                isLightTheme
                                                    ? 'light-theme'
                                                    : ''
                                            }`}
                                        >
                                            <FocusableAnchorTag
                                                href={
                                                    ExternalUrls.AIRMEET_FACEBOOK
                                                }
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                aria-label='Open Airmeet Facebook Page'
                                            >
                                                <i className='fa fa-facebook-square'></i>
                                            </FocusableAnchorTag>
                                            <FocusableAnchorTag
                                                href={
                                                    ExternalUrls.AIRMEET_TWITTER
                                                }
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                aria-label='Open Airmeet X (Formerly Twitter) Page'
                                            >
                                                <StyledSvgSprite
                                                    icon={
                                                        isLightTheme
                                                            ? 'twitter-x-square-light'
                                                            : 'twitter-x-square'
                                                    }
                                                    height='20.6px'
                                                    width='20.6px'
                                                />
                                            </FocusableAnchorTag>
                                            <FocusableAnchorTag
                                                href={
                                                    ExternalUrls.AIRMEET_LINKEDIN
                                                }
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                aria-label='Open Airmeet Linkedin Page'
                                            >
                                                <i
                                                    className='fa fa-linkedin-square'
                                                    aria-hidden='true'
                                                ></i>
                                            </FocusableAnchorTag>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            {!isWhiteLabellingEnabled ? (
                                <div className='row'>
                                    <div className='col-12'>
                                        <ul className='no-style f-nav-b'>
                                            <li className='a-rights text-center w-100'>
                                                <Typography
                                                    variant='caption'
                                                    color={
                                                        isLightTheme
                                                            ? theme.colors
                                                                  .ambience[4]
                                                            : theme.colors.white
                                                    }
                                                >
                                                    &copy;{' '}
                                                    {new Date().getFullYear()}{' '}
                                                    Airmeet Inc.{' '}
                                                </Typography>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            </MainFooter>
        </>
    ) : showHomeV2Footer ? (
        <HomeFooter />
    ) : null;
}

const Box = styled.div`
    ${space}
    ${flexbox}
    ${background}
    ${border}
    display: flex;
`;

const MainFooter = styled(Box)`
    border-top: ${({ theme }) =>
        theme.isLightTheme ? 'none' : `1px solid ${theme.colors.ambience[20]}`};
    position: ${({ theme }) => theme.isLightTheme && 'relative'};
    box-shadow: ${({ theme }) =>
        theme.isLightTheme &&
        `0px 0px 3.6px ${alpha(theme.colors.black, 0.11)},
        0px 0px 14.4px ${alpha(theme.colors.black, 0.04)}`};
`;

const Anchor = styled.span`
    ${color}
    &:focus-visible {
        outline: solid;
    }
`;

const FocusableAnchorTag = styled.a`
    &:focus {
        outline: none;
    }
    &:focus-visible {
        outline: solid;
    }
`;
const FooterContainer = styled.div`
    text-align: center;
`;

const UnorderedList = styled.ul`
    @media (min-width: 425px) {
        margin-left: 10em;
    }
`;
