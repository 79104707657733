import {
    ALL_FEATURES_CONFIG,
    ALL_PLATFORM_FEATURES,
    ALL_PLATFORM_CONFIG,
} from '../../hooks/useFeatureControl';
import { ccModules } from 'utils/constants/common';

const default_CLOSED_CAPTIONS_USING_RTC_SDK = 'true'; // false:'Symbl', true:'Polyglot'

export const CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION = {
    google: 0, // Google
    aws: 1, // AWS
    deepgram: 2, // DEEPGRAM
};

export const default_CLOSED_CAPTIONS_SERVICE_PROVIDERS = {
    [ccModules.SESSIONS]: CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.google,
    [ccModules.TABLES]: CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.google,
    [ccModules.DEFAULT]: CLOSED_CAPTIONS_BACKEND_FLAGS_DEFINITION.google,
};

const default_CLOSED_CAPTIONS_DEFAULT_LANGUAGE = 'en-US'; // (if default_CLOSED_CAPTIONS_USING_RTC_SDK is false(Symbl), NO impact of this value)
const default_ENABLE_CLOSED_CAPTIONS_TRANSLATION = false;

export const DEFAULT_CC_LANGUAGE = 'default';

export const defaultSelectedCCLanguage = {
    label: 'Off',
    value: 'off',
};

function parseBoolean(x: string) {
    return x?.length > 0 ? x.toLowerCase() === 'true' : false;
}

export const getStorageKeyForCCSubscriptionLanguage = (
    sessionId: string,
    userId: string
) => `${sessionId}-${userId}-closed-captions-language`;

export const createClosedCaptionsConfig = ({
    airmeetId,
    communityId,
    featuresConfig,
    platformConfig,
    backendConfig,
    live_config,
    closed_captions,
    defaultPrimaryLanguage,
}: {
    airmeetId: string;
    communityId: string;
    featuresConfig: Object;
    platformConfig: Object;
    backendConfig: Object;
    live_config: any;
    closed_captions: string;
    defaultPrimaryLanguage: any;
}) => {
    let closedCaptionsUsingRTCSDK = default_CLOSED_CAPTIONS_USING_RTC_SDK;
    let closedCaptionsServiceProviders = default_CLOSED_CAPTIONS_SERVICE_PROVIDERS;
    let enabledClosedCaptionTranslation = default_ENABLE_CLOSED_CAPTIONS_TRANSLATION;

    if (
        featuresConfig?.hasOwnProperty(
            ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS_USING_RTC_SDK
        )
    ) {
        closedCaptionsUsingRTCSDK =
            featuresConfig?.[
                ALL_PLATFORM_FEATURES.CLOSED_CAPTIONS_USING_RTC_SDK
            ];
    }

    if (
        featuresConfig?.hasOwnProperty(
            ALL_FEATURES_CONFIG.CLOSED_CAPTIONS_SERVICE_PROVIDERS
        )
    ) {
        closedCaptionsServiceProviders =
            featuresConfig[
                ALL_FEATURES_CONFIG.CLOSED_CAPTIONS_SERVICE_PROVIDERS
            ];
    } else if (
        platformConfig?.hasOwnProperty(
            ALL_PLATFORM_CONFIG.CLOSED_CAPTIONS_SERVICE_PROVIDERS
        )
    ) {
        closedCaptionsServiceProviders =
            platformConfig[
                ALL_PLATFORM_CONFIG.CLOSED_CAPTIONS_SERVICE_PROVIDERS
            ]; // values comes like 0(for google) or 1(for aws) or 2(for deepgram)
    }

    if (
        featuresConfig?.hasOwnProperty(
            ALL_PLATFORM_FEATURES.ENABLE_CLOSED_CAPTIONS_TRANSLATION
        )
    ) {
        // Double negation to convert string/number/any-value to boolean
        enabledClosedCaptionTranslation = !!featuresConfig?.[
            ALL_PLATFORM_FEATURES.ENABLE_CLOSED_CAPTIONS_TRANSLATION
        ];
    }

    const isCCTranslationEnabled =
        enabledClosedCaptionTranslation ??
        default_ENABLE_CLOSED_CAPTIONS_TRANSLATION;
    return {
        isCCUsingRTCSDK: parseBoolean(
            closedCaptionsUsingRTCSDK ?? default_CLOSED_CAPTIONS_USING_RTC_SDK
        ),
        ccServiceProviders: closedCaptionsServiceProviders,
        communityId,
        airmeetId,
        ccLangs:
            isCCTranslationEnabled && live_config?.allowTranslatedCaption
                ? live_config?.ccLangs
                : [],
        ccPrimaryLang: live_config?.ccPrimaryLang ?? defaultPrimaryLanguage,
        showCloseCaption: live_config?.showCloseCaption,
        allowTranslatedCaption: live_config?.allowTranslatedCaption,
        closed_captions,
        isCCTranslationEnabled,
    };
};

export const CC_LANGUAGES_WITH_BIGGER_FONTS_HEIGHT = [
    'te', // Telugu
    'kn', // Kannada
];

export const CC_LANGUAGES_WITH_CUSTOM_FONTS_FILE = [
    'mni-Mtei', // Manipuri
];
