import LocalDialog from 'components/molecules/LocalDialog/LocalDialog';
import { UnblockUserModal } from 'components/report/ReportModal';
import { Text } from 'foundations/themeV2/text';
import useRaiseHandActions, {
    useRaiseHandStoreList,
} from 'hooks/live-airmeet/useRaiseHandActions';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import keys from 'locale/keys';
import get from 'lodash/get';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import UserService from 'services/users/UserService';
import { setBlockUserModalVisibility } from 'store/actions/blockUserModal';
import { blockUnblockAttendee } from 'store/actions/login';
import { getAssocUserById, getBlockUserModal } from 'store/selectors/users';
import FirebaseLogger from 'utils/firebaseLogger';
import {
    findHandRaiseActiveRequest,
    findHandRaisePendingRequest,
} from 'utils/liveEvent';
import useLiveEventSelfUser from './useLiveEventSelfUser';

const useBlockUser = () => {
    const store = useStore();
    const dispatch = useDispatch();
    const blockedUsers = useSelector((store) =>
        get(store, ['lounge', 'reports', 'blockedUsers'])
    );
    const user = useLiveEventSelfUser();
    const isLoading = blockedUsers === null;
    const isCurrentUserBlocked =
        blockedUsers === null ? true : !!blockedUsers[user.id];
    const currentUserRole = useSelector(
        (store) => store.layout.header.userRole
    );

    const blockUserModal = useSelector(getBlockUserModal);
    const [blockUserModalVisible, setBlockUserModalVisible] = useState(false);
    const [unblockUserModalVisible, setUnblockUserModalVisible] = useState(
        false
    );
    const [selectedUser, setSelectedUser] = useState(null);
    const [blockTriggerPlace, setBlockTriggerPlace] = useState(null);

    const numberOfBlockedUsers = Object.keys(blockedUsers || {})?.length;

    const {
        airmeet: { airmeetId, data },
        user: authUser,
    } = useLiveAirmeetContext();

    const { t } = useCustomTranslation();

    const { currentSession } = data;

    const handRaiseService = useRaiseHandActions({
        sessionId: currentSession && currentSession.sessionid,
        airmeetId,
        user: authUser,
    });
    const serviceHelper = useRaiseHandStoreList();

    const getUser = useCallback(
        async (_user) => {
            if (typeof _user === 'string') {
                // TODO: Mukul flag for enable/disable dynamic load
                if (false) {
                    return Promise.resolve(
                        getAssocUserById(_user)(store.getState())
                    );
                }
                return await UserService.getInstance({ airmeetId }).fetchUser(
                    _user
                );
            }
            return Promise.resolve(_user);
        },
        [airmeetId, store]
    );

    const blockUser = useCallback(
        async (_user, fromPlace) => {
            const user = await getUser(_user);
            const userToBlock = selectedUser ? selectedUser.id : user.id;
            if (currentSession && currentSession.sessionid) {
                const handRaiseList = serviceHelper.getRaiseHandRequestList(
                    currentSession.sessionid
                );

                const pendingRequest = findHandRaisePendingRequest(
                    handRaiseList,
                    userToBlock
                );

                if (pendingRequest) {
                    handRaiseService.rejected(
                        pendingRequest.user,
                        pendingRequest.requestId
                    );
                }

                const activeRequest = findHandRaiseActiveRequest(
                    handRaiseList,
                    userToBlock
                );

                if (activeRequest) {
                    handRaiseService.leaveRequest(
                        activeRequest.user,
                        activeRequest.requestId
                    );
                }
            }
            const body = {
                userId: userToBlock,
            };
            const action = 'block';
            dispatch(blockUnblockAttendee(body, airmeetId, action));

            FirebaseLogger.blockUser({
                userId: userToBlock,
                airmeetId,
                fromPlace: fromPlace || blockTriggerPlace,
            });
            setBlockTriggerPlace(null);
        },
        [
            getUser,
            currentSession,
            selectedUser,
            dispatch,
            airmeetId,
            blockTriggerPlace,
            serviceHelper,
            handRaiseService,
        ]
    );

    const unblockUser = useCallback(
        async (_user) => {
            const user = await getUser(_user);
            const userToUnblock = selectedUser ? selectedUser.id : user.id;
            const body = {
                userId: userToUnblock,
            };
            const action = 'unblock';
            dispatch(blockUnblockAttendee(body, airmeetId, action));
            FirebaseLogger.unblockUser(userToUnblock, airmeetId);
        },
        [airmeetId, dispatch, getUser, selectedUser]
    );

    const onBlockUserClick = useCallback(
        async (_user, fromPlace, options = {}) => {
            const user = await getUser(_user);
            setSelectedUser(user);
            setBlockTriggerPlace(fromPlace);
            setBlockUserModalVisible(true);
            if (options.enableBlockUserModalVisibility === true) {
                dispatch(setBlockUserModalVisibility(user));
            }
        },
        [dispatch, getUser]
    );

    const onUnblockUserClick = useCallback(
        async (_user) => {
            const user = await getUser(_user);
            setSelectedUser(user);
            setUnblockUserModalVisible(true);
        },
        [getUser]
    );

    const onConfirmUserBlockModal = useCallback(() => {
        blockUser(selectedUser || blockUserModal);
        setBlockUserModalVisible(false);
        dispatch(setBlockUserModalVisibility(false));
    }, [blockUser, blockUserModal, dispatch, selectedUser]);

    const onConfirmUserUnblockModal = useCallback(() => {
        unblockUser(selectedUser);
        setUnblockUserModalVisible(false);
    }, [unblockUser, selectedUser]);

    const onBlockModalClose = useCallback(() => {
        setBlockUserModalVisible(false);
        dispatch(setBlockUserModalVisibility(false));
    }, [dispatch]);

    const renderConfirmationModal = useCallback(() => {
        const userName = selectedUser?.name || blockUserModal?.name || '';
        return (
            <LocalDialog
                title={`${t(keys.RHS_ACTIONS_BLOCK_USER_BLOCK)} ${userName}`}
                onConfirm={onConfirmUserBlockModal}
                onClose={onBlockModalClose}
                zIndex={1010}
            >
                <Text variant='caption' color='text.default.secondary'>
                    {t(keys.RHS_ACTIONS_BLOCK_USER_DESC_NEW)}
                </Text>
            </LocalDialog>
        );
    }, [
        blockUserModal,
        onBlockModalClose,
        onConfirmUserBlockModal,
        selectedUser,
        t,
    ]);

    const UserBlockModal = useCallback(() => {
        if (!blockUserModal) {
            return null;
        }
        return renderConfirmationModal();
    }, [blockUserModal, renderConfirmationModal]);

    const UserBlockConfirmationModal = useCallback(() => {
        if (!blockUserModalVisible) {
            return null;
        }
        return renderConfirmationModal();
    }, [blockUserModalVisible, renderConfirmationModal]);

    const UserUnblockConfirmationModal = useCallback(
        () => (
            <>
                {unblockUserModalVisible && (
                    <UnblockUserModal
                        user={selectedUser}
                        onConfirm={onConfirmUserUnblockModal}
                        onDismiss={() => setUnblockUserModalVisible(false)}
                    />
                )}
            </>
        ),
        [onConfirmUserUnblockModal, unblockUserModalVisible, selectedUser]
    );

    return {
        isCurrentUserBlocked,
        blockedUserInfoById: blockedUsers,
        currentUserRole,
        numberOfBlockedUsers,
        UserBlockModal,
        UserBlockConfirmationModal,
        UserUnblockConfirmationModal,
        onBlockUserClick,
        onUnblockUserClick,
        blockUser,
        unblockUser,
        isBlockUserLoading: isLoading,
    };
};

export default useBlockUser;
