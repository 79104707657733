import {
    INVITE_MEMBER_PATH,
    ONBOARDING_PATH,
    SIGNUP_PAGE_PATH,
    SSO_PATH,
} from 'components/auth/utils';
import { stringToHSL, themeWrapper } from 'foundations/theme';
import { FontImport } from 'foundations/typography';
import cloneDeep from 'lodash/cloneDeep';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { isGlobalRegion } from 'utils/constants/dataCenter';
import { getIsLightThemeDashboard } from 'utils/core';
import { getDefaultLandingPageConfig } from 'utils/eventLandingPage';
import { logger } from 'utils/logger';
import { getCurrentTenantData } from 'utils/tenant';
import history from '../../src/history';
import { getDefaultSeriesLandingPageConfig } from '../views/EventSeriesLandingPage/utils';

export const IconVersion = 61;

const BodyStyles = createGlobalStyle`
    body {
          background: ${({
              theme,
              isDashboardLightTheme,
              isNewOnboardingPage,
              isDashboardBrandingPage,
          }) =>
              `${
                  isDashboardLightTheme
                      ? theme.colors.ambience[23]
                      : isNewOnboardingPage
                      ? `
                    background: linear-gradient(108.14deg, #F2F6F9 0%, #E9F3F7 9.47%, #E2F0F7 20.52%, #DBEBF7 36.84%, #D3E1F7 51.58%, #D5DEF7 68.94%, #DBDDF2 83.15%, #DDDCF0 101.05%) !important;
                `
                      : isDashboardBrandingPage
                      ? theme.colors.ambience[24]
                      : theme.colors.background.page
              } !important`};
    }

    /* Add to calendar CSS */
    .atcb-list {
        &:after {
            content: 'Add to calendar';
            position: absolute;
            top: 0;
            padding: 14px 14px 5px 14px;
            font-weight: bold;
            pointer-events: none;
            width: 100%;
            background: var(--atcb-background);
            border-radius: 6px 6px 0 0;
            z-index: 1;
        }
        .atcb-list-item {
            border: 0;
            &:first-child {
                margin-top: 43px;
                border-radius: 0 !important;
            }
            &:nth-last-child(2) {
                border-radius: 0 0 6px 6px !important;
            }
        }
        #atcb-btn-custom-close {
            position: absolute;
            top: 0;
            right: 0;
            background: transparent;
            z-index: 2;
            .atcb-text {
                display: none;
            }
        }
    }
`;
const themeInfoSelector = (store) => store?.lounge?.airmeet?.theme_info;
const liveConfigSelector = (store) => store?.lounge?.airmeet?.live_config;
const seriesInfoSelector = (store) =>
    store?.eventSeries?.combinedInfo?.seriesInfo;
const allowFetchIconsSelector = (store) => store?.genral?.allowFetchIcons;
const lightThemePageSelector = (store) => store?.genral?.isLightThemePage;
const lightThemeLandingPageSelector = (store) =>
    store?.genral?.isEventLandingBrandingPage;
const seriesLandingPageSelector = (store) =>
    store?.genral?.isSeriesLandingBrandingPage;
const eventBrandingPageSelector = (store) => store?.genral?.isEventBrandingPage;
const dashboardBrandingPageSelector = (store) =>
    store?.genral?.isDashboardBrandingPage;

const isNewOnboardingPageSelector = (store) =>
    store?.genral?.isNewOnboardingPage;

const isLightThemeDashboardSelector = (store) =>
    store?.auth?.isLightThemeDashboard;
const communityCreatedAtSelector = (store) =>
    store?.Community?.current?.created_at;

//NOTE: Be carful with listening to auth.user redux value here. Might cause issues (eg while logging out from live side as organizer)
export const ThemeContext = React.createContext(null);
export function AirmeetThemeProvider({ children }) {
    const query = new URLSearchParams(history.location.search);
    const lightThemeOverride = query.get('isLightAmbience') === 'true';
    const darkThemeOverride = query.get('isDarkAmbience') === 'true';
    const themeInfo = useSelector(themeInfoSelector);
    const isLightThemePage = useSelector(lightThemePageSelector);

    const isEventLandingBrandingPage = useSelector(
        lightThemeLandingPageSelector
    );
    const isSeriesLandingBrandingPage = useSelector(seriesLandingPageSelector);
    const allowFetchIcons = useSelector(allowFetchIconsSelector);
    const isNewOnboardingPage = useSelector(isNewOnboardingPageSelector);
    const isEventBrandingPage = useSelector(eventBrandingPageSelector);
    const isDashboardBrandingPage = useSelector(dashboardBrandingPageSelector);
    const isLightThemeDashboard = useSelector(isLightThemeDashboardSelector);
    const communityCreatedAt = useSelector(communityCreatedAtSelector);

    const liveConfig = useSelector(liveConfigSelector);
    const seriesInfo = useSelector(seriesInfoSelector);
    const hasIconAlreadyFetched = useRef(false);

    const landingPageBranding = useMemo(
        () =>
            isSeriesLandingBrandingPage
                ? getDefaultSeriesLandingPageConfig({ eventSeries: seriesInfo })
                : isEventLandingBrandingPage
                ? getDefaultLandingPageConfig({
                      landingPageBranding: liveConfig?.landingPageBranding,
                      skipExtra: true,
                  })
                : {},
        [
            isEventLandingBrandingPage,
            liveConfig,
            seriesInfo,
            isSeriesLandingBrandingPage,
        ]
    );

    const dashboardBranding = useMemo(
        () => ({
            isLightTheme: getIsLightThemeDashboard(
                communityCreatedAt,
                isLightThemeDashboard
            ),
        }),
        [communityCreatedAt, isLightThemeDashboard]
    );

    const baseColor = useMemo(() => {
        let values = [];
        if (
            (isEventLandingBrandingPage || isSeriesLandingBrandingPage) &&
            landingPageBranding?.ambience
        ) {
            if (landingPageBranding?.primaryColor) {
                values = stringToHSL(landingPageBranding?.primaryColor);
            }
        } else if (isLightThemePage && themeInfo?.primary_color) {
            values = stringToHSL(themeInfo?.primary_color);
        }
        if (Array.isArray(values) && values.length >= 4) {
            return {
                H: Number(values[1]),
                S: Number(values[2]),
                L: Number(values[3]),
            };
        }
        return getCurrentTenantData('primaryColor');
    }, [
        isEventLandingBrandingPage,
        isSeriesLandingBrandingPage,
        isLightThemePage,
        themeInfo,
        landingPageBranding,
    ]);

    const isLightTheme = useMemo(() => {
        // Since redux takes a few ms to update light theme value to true (default is false),
        // user will see the dark theme loader in that timeframe. Below condition is to
        // ensure that user sees the light theme loader at all times in any auth flow paths
        const isOnLightThemeAuthRoute = [
            SIGNUP_PAGE_PATH,
            SSO_PATH,
            INVITE_MEMBER_PATH,
            ONBOARDING_PATH,
        ].some((path) => history.location.pathname.startsWith(path));

        if (
            lightThemeOverride ||
            isNewOnboardingPage ||
            isOnLightThemeAuthRoute
        ) {
            return true;
        }
        if (darkThemeOverride) return false;

        if (isDashboardBrandingPage) return dashboardBranding?.isLightTheme;

        // If landing page config present then apply landing page ambience for landing pages
        // If landing page config not present then consume themeInfo
        if (isEventLandingBrandingPage || isSeriesLandingBrandingPage) {
            switch (landingPageBranding?.ambience) {
                case 'light':
                    return true;
                case 'dark':
                    return false;
                default:
                    break;
            }
        }
        if (isLightThemePage && themeInfo?.is_light_theme) {
            return true;
        }

        return false;
    }, [
        isLightThemePage,
        themeInfo,
        isNewOnboardingPage,
        lightThemeOverride,
        isSeriesLandingBrandingPage,
        isEventLandingBrandingPage,
        landingPageBranding,
        isDashboardBrandingPage,
        dashboardBranding,
        darkThemeOverride,
    ]);
    const isEventLandingPage =
        history.location.pathname.startsWith('/e/') &&
        history.location.pathname.split('/')?.length === 3;
    const [theme, setTheme] = useState(themeWrapper(baseColor, isLightTheme));

    const eventBranding = useMemo(() => {
        let eventBranding = {};
        if (isEventBrandingPage || isEventLandingBrandingPage) {
            eventBranding = cloneDeep(liveConfig?.eventBranding || {});

            if (
                liveConfig?.eventBranding?.background?.type &&
                liveConfig?.eventBranding?.background?.value
            ) {
                eventBranding.hasCustomBackground = true;
                eventBranding.showUnderlay = true;
            }
        }

        return eventBranding;
    }, [isEventBrandingPage, liveConfig, isEventLandingBrandingPage]);

    const primaryButtonTextColor = useMemo(() => {
        if (
            (isEventLandingBrandingPage || isSeriesLandingBrandingPage) &&
            landingPageBranding?.ambience
        ) {
            return landingPageBranding?.primaryButtonTextColor;
        } else if (
            isEventBrandingPage &&
            eventBranding?.primaryButtonTextColor
        ) {
            return eventBranding?.primaryButtonTextColor;
        }
        return '';
    }, [
        eventBranding,
        isEventBrandingPage,
        isEventLandingBrandingPage,
        isSeriesLandingBrandingPage,
        landingPageBranding,
    ]);

    useEffect(() => {
        setTheme(
            themeWrapper(
                baseColor,
                isLightTheme,
                eventBranding,
                primaryButtonTextColor,
                landingPageBranding,
                isDashboardBrandingPage,
                isNewOnboardingPage
            )
        );
    }, [
        baseColor,
        isLightTheme,
        eventBranding,
        landingPageBranding,
        primaryButtonTextColor,
        isDashboardBrandingPage,
        isNewOnboardingPage,
    ]);

    useEffect(() => {
        // CDN path must be '/' for other JS files to load, for images we need to do this only
        if (
            (isEventLandingPage && !allowFetchIcons) ||
            hasIconAlreadyFetched.current
        ) {
            // To delay fetching the Icon file, because of its big size.
            return;
        }
        const cdnUrl = process.env.CDN_ASSETS_URL;
        const baseUrl =
            process.env.REACT_APP_ENV !== 'test' || isGlobalRegion
                ? `${
                      cdnUrl === '/'
                          ? process.env.REACT_APP_BASE_URL + '/'
                          : cdnUrl
                  }`
                : process.env.BUILD_ID
                ? `${process.env.REACT_APP_BASE_URL}/${process.env.BUILD_ID}/`
                : `${process.env.REACT_APP_BASE_URL}/`;
        const svgPath = `${baseUrl}images/icons.svg?v=${IconVersion}`;

        fetch(svgPath)
            .then((res) => res.text())
            .then((res) => {
                hasIconAlreadyFetched.current = true;
                var div = document.createElement('div');
                div.style.display = 'none';
                div.innerHTML = res;
                document.body.insertBefore(div, document.body.childNodes[0]);
            })
            .catch((error) => {
                logger.error('Failed to fetch icons', error, {
                    message: error?.message,
                    cdnUrl,
                    baseUrl,
                    svgPath,
                });
            });
    }, [isEventLandingPage, allowFetchIcons]);

    return (
        <ThemeContext.Provider
            value={{
                theme,
                isLightTheme,
                themeWrapper,
                baseColor,
                isNewOnboardingPage,
            }}
        >
            <ThemeProvider theme={theme}>
                <FontImport />
                <BodyStyles
                    isDashboardLightTheme={
                        isDashboardBrandingPage && isLightTheme
                    }
                    isNewOnboardingPage={isNewOnboardingPage}
                    isDashboardBrandingPage={isDashboardBrandingPage}
                />
                {children}
            </ThemeProvider>
        </ThemeContext.Provider>
    );
}
