import AudioResumeModal from 'components/modals/AudioResumeModal';
import useAuthActionHandler from 'hooks/useAuthActionHandler';
import useDeviceInfo from 'hooks/useDeviceInfo';
import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import FirebaseLogger from 'utils/firebaseLogger';
import PermissionUtils from 'utils/permission-utils';
import UserService from '../../services/users/UserService';
import { closeAppModal } from '../../store/actions/layout';
import { logger } from '../../utils/logger';
import FullScreenPortal from '../general/FullScreenPortal';
import Portal from '../general/Portal';
import NetworkLostModal from '../molecules/modal/NetworkLostModal';
import ConfirmationModal from './templates/ConfirmationModal';
import TestMailModal from './TestMailModal';

import {
    AUDIO_RESUME_MODAL,
    DISABLE_ENRICHMENT_MODAL,
    EMAIL_TEMPLATE_CONFIRMATION,
    MODAL_EDIT_VIDEO_HEADLINE,
    MODAL_TYPE_ADD_INTEREST,
    MODAL_TYPE_ADD_PAYMENT_DETAILS,
    MODAL_TYPE_ADD_TO_CALENDAR,
    MODAL_TYPE_ADD_VIDEO,
    MODAL_TYPE_AIR_GENIE,
    MODAL_TYPE_AIR_GENIE_IMAGE,
    MODAL_TYPE_AIRMEET_DEMO,
    MODAL_TYPE_AIRMEET_STATUS_CONFIRMATION,
    MODAL_TYPE_ALLOWED_ACCESS,
    MODAL_TYPE_APPLY_COUPON,
    MODAL_TYPE_APPSUMO,
    MODAL_TYPE_ASSIGN_HOST,
    MODAL_TYPE_ATTENDEE_BLOCKED,
    MODAL_TYPE_ATTENDEE_INTRO,
    MODAL_TYPE_AUTH_SSO,
    MODAL_TYPE_BASE_MODAL_V2,
    MODAL_TYPE_BILLING_PLAN_UPGRADE,
    MODAL_TYPE_BLACKLIST_PARTICIPANTS,
    MODAL_TYPE_BLOCK_UNBLOCK_ATTENDEE,
    MODAL_TYPE_BOOTH_HALL,
    MODAL_TYPE_BOOTH_SESSION,
    MODAL_TYPE_BOOTH_SUBSCRIBE,
    MODAL_TYPE_BOOTHS,
    MODAL_TYPE_BREAKOUT_ANNOUNCEMENT,
    MODAL_TYPE_BREAKOUT_CLOSE_CONFIRMATION,
    MODAL_TYPE_BREAKOUT_CONFIRMATION,
    MODAL_TYPE_CHANGE_ROLE,
    MODAL_TYPE_CHECKOUTFLOW,
    MODAL_TYPE_COMMUNITY_SWITCHER,
    MODAL_TYPE_COMPARE_PLAN,
    MODAL_TYPE_COMPARE_PLANS_V2,
    MODAL_TYPE_CONFERENCE_TRIAL,
    MODAL_TYPE_CONFIRM,
    MODAL_TYPE_CONFIRMATION,
    MODAL_TYPE_CONNECTION_STATE,
    MODAL_TYPE_CONNECTIVITY_FAILURE,
    MODAL_TYPE_COUPON_CONFIRM,
    MODAL_TYPE_CREATE_COMMUNITY,
    MODAL_TYPE_CREATE_FIRST_AIRMEET,
    MODAL_TYPE_CREATE_MULTI_COMMUNITY,
    MODAL_TYPE_CREATE_ORGANISATION,
    MODAL_TYPE_CUSTOM_REGISTRATION,
    MODAL_TYPE_CUSTOMISE_ENTERPRISE_PLAN,
    MODAL_TYPE_DASHBOARD_INTEGRATION,
    MODAL_TYPE_DELETE_FORM,
    MODAL_TYPE_DELETE_MEMBER,
    MODAL_TYPE_DELETE_ORGANIZER,
    MODAL_TYPE_DETAILS_TERMS_OF_SERVICE,
    MODAL_TYPE_EDIT_IMAGE,
    MODAL_TYPE_EDIT_LEADERBOARD_ACTIONS,
    MODAL_TYPE_EDIT_LEADERBOARD_BLOCK_DOMAIN,
    MODAL_TYPE_EDIT_LOUNGE,
    MODAL_TYPE_EDIT_PERMISSION,
    MODAL_TYPE_EDIT_RECEPTION,
    MODAL_TYPE_ENTRY_FLOW,
    MODAL_TYPE_ENTRY_RESTRICTED,
    MODAL_TYPE_EVENT_ACCESS,
    MODAL_TYPE_EVENT_ACCESS_PANEL,
    MODAL_TYPE_EVENT_DETAILS,
    MODAL_TYPE_EVENT_ENTRY,
    MODAL_TYPE_EVENT_ENTRY_OTP,
    MODAL_TYPE_EVENT_NOT_ACCESSIBLE,
    MODAL_TYPE_EVENT_REGISTRATION,
    MODAL_TYPE_EVENT_SHARE,
    MODAL_TYPE_EXHIBITOR_ACCESS_DENIED,
    MODAL_TYPE_FILE_VIEWER,
    MODAL_TYPE_GOOGLE_TAG_MANAGER,
    MODAL_TYPE_GROUP_MEETING,
    MODAL_TYPE_INSUFFICIENT_PERMISSIONS,
    MODAL_TYPE_INVITE_OTHERS,
    MODAL_TYPE_INVITE_TEAM_MEMBER_V1,
    MODAL_TYPE_INVITE_TEAM_MEMBER_V2,
    MODAL_TYPE_INVITE_TO_TABLE,
    MODAL_TYPE_LANG_SELECT,
    MODAL_TYPE_LANGUAGE,
    MODAL_TYPE_LEGACY_AIRMEET_SUPPORT,
    MODAL_TYPE_LIGHT,
    MODAL_TYPE_LIVE_MARK_AS_READY,
    MODAL_TYPE_LIVE_PROMPT,
    MODAL_TYPE_MANAGE_SUBSCRIPTION,
    MODAL_TYPE_MEDIA_LIBRARY,
    MODAL_TYPE_METERED_PRICING,
    MODAL_TYPE_METERED_PRICING_DRAWER,
    MODAL_TYPE_METERING_POLICY,
    MODAL_TYPE_NETWORK_LOST,
    MODAL_TYPE_NETWORKING_SESSION,
    MODAL_TYPE_NOTIFICATION_POPUP,
    MODAL_TYPE_ONBOARDING_QUESTION_MODAL,
    MODAL_TYPE_ONBOARDING_WELCOME_MODAL,
    MODAL_TYPE_PASSWORD_CHECKLIST,
    MODAL_TYPE_PAUSE_EMAIL,
    MODAL_TYPE_PERMISSION_SETUP,
    MODAL_TYPE_PLAN_FEATURE_LIST,
    MODAL_TYPE_POST_EVENT_ENTRY_RESTRICTED,
    MODAL_TYPE_PREMIUM_FEATURE,
    MODAL_TYPE_PREVIEW_EVENT_SERIES,
    MODAL_TYPE_PREVIEW_EVENT_TEMPLATE,
    MODAL_TYPE_REDUCE_ADDON_SEATS,
    MODAL_TYPE_REFRESH_INTEGRATION_APP,
    MODAL_TYPE_REGISTRATION_FORM,
    MODAL_TYPE_REGISTRATION_SUCCESS_MODAL,
    MODAL_TYPE_REGISTRATION_SYNC,
    MODAL_TYPE_REGISTRATIONS_CLOSED,
    MODAL_TYPE_RESOURCES,
    MODAL_TYPE_RESUME_EMAIL,
    MODAL_TYPE_SAVE_REGISTRATION,
    MODAL_TYPE_SEND_EMAIL_CONFIRMATION,
    MODAL_TYPE_SESSION_LEAVE_CONFIRMATION,
    MODAL_TYPE_SESSION_PLAYBACK_TOGGLE,
    MODAL_TYPE_SESSION_STARTED_PROMPT,
    MODAL_TYPE_SHOW_ORGANISATION_TYPE,
    MODAL_TYPE_SPEAKER_INVITE,
    MODAL_TYPE_SPEAKER_REORDER,
    MODAL_TYPE_STATUS_MODAL,
    MODAL_TYPE_TEAM_MEMBER_ADDON,
    MODAL_TYPE_TERMS_OF_SERVICE,
    MODAL_TYPE_TEST_MAIL,
    MODAL_TYPE_TICKET,
    MODAL_TYPE_TOAST_MODAL,
    MODAL_TYPE_UPDATE_PASSWORD,
    MODAL_TYPE_UPGRADE_PLAN,
    MODAL_TYPE_UPLOAD_MEDIA,
    MODAL_TYPE_USER_AUTH,
    MODAL_TYPE_USER_EDIT_PROFILE,
    MODAL_TYPE_USER_WAITLISTED,
    MODAL_TYPE_VALIDATE_DUPLICATION,
    MODAL_TYPE_VIDEO,
    MODAL_TYPE_VIDEO_NEW,
    MODAL_TYPE_VIEW_ORGANIZER,
    RESYNC_MODAL_INTEGRATION,
    SYNC_MODAL_INTEGRATION,
} from './constants.js';
import EventNotAccessibleModal from './EventNotAccessibleModal.js';

export {
    AUDIO_RESUME_MODAL,
    DISABLE_ENRICHMENT_MODAL,
    MODAL_EDIT_VIDEO_HEADLINE,
    MODAL_TYPE_ADD_PAYMENT_DETAILS,
    MODAL_TYPE_ADD_TO_CALENDAR,
    MODAL_TYPE_ADD_VIDEO,
    MODAL_TYPE_AIRMEET_DEMO,
    MODAL_TYPE_AIRMEET_STATUS_CONFIRMATION,
    MODAL_TYPE_ALLOWED_ACCESS,
    MODAL_TYPE_APPSUMO,
    MODAL_TYPE_ASSIGN_HOST,
    MODAL_TYPE_ATTENDEE_BLOCKED,
    MODAL_TYPE_ATTENDEE_INTRO,
    MODAL_TYPE_AUTH_SSO,
    MODAL_TYPE_BILLING_PLAN_UPGRADE,
    MODAL_TYPE_BLACKLIST_PARTICIPANTS,
    MODAL_TYPE_BLOCK_UNBLOCK_ATTENDEE,
    MODAL_TYPE_BOOTH_HALL,
    MODAL_TYPE_BOOTH_SESSION,
    MODAL_TYPE_BOOTH_SUBSCRIBE,
    MODAL_TYPE_BOOTHS,
    MODAL_TYPE_BREAKOUT_ANNOUNCEMENT,
    MODAL_TYPE_BREAKOUT_CLOSE_CONFIRMATION,
    MODAL_TYPE_BREAKOUT_CONFIRMATION,
    MODAL_TYPE_CHANGE_ROLE,
    MODAL_TYPE_CHECKOUTFLOW,
    MODAL_TYPE_COMMUNITY_SWITCHER,
    MODAL_TYPE_COMPARE_PLAN,
    MODAL_TYPE_CONFERENCE_TRIAL,
    MODAL_TYPE_CONNECTION_STATE,
    MODAL_TYPE_CONNECTIVITY_FAILURE,
    MODAL_TYPE_CREATE_COMMUNITY,
    MODAL_TYPE_CREATE_FIRST_AIRMEET,
    MODAL_TYPE_CREATE_MULTI_COMMUNITY,
    MODAL_TYPE_CUSTOM_REGISTRATION,
    MODAL_TYPE_CUSTOMISE_ENTERPRISE_PLAN,
    MODAL_TYPE_DASHBOARD_INTEGRATION,
    MODAL_TYPE_DELETE_MEMBER,
    MODAL_TYPE_DELETE_ORGANIZER,
    MODAL_TYPE_DETAILS_TERMS_OF_SERVICE,
    MODAL_TYPE_EDIT_IMAGE,
    MODAL_TYPE_EDIT_LEADERBOARD_ACTIONS,
    MODAL_TYPE_EDIT_LEADERBOARD_BLOCK_DOMAIN,
    MODAL_TYPE_EDIT_LOUNGE,
    MODAL_TYPE_EDIT_PERMISSION,
    MODAL_TYPE_EDIT_RECEPTION,
    MODAL_TYPE_ENTRY_RESTRICTED,
    MODAL_TYPE_EVENT_ACCESS,
    MODAL_TYPE_EVENT_ACCESS_PANEL,
    MODAL_TYPE_EVENT_DETAILS,
    MODAL_TYPE_EVENT_ENTRY,
    MODAL_TYPE_EVENT_REGISTRATION,
    MODAL_TYPE_EVENT_SHARE,
    MODAL_TYPE_EXHIBITOR_ACCESS_DENIED,
    MODAL_TYPE_GOOGLE_TAG_MANAGER,
    MODAL_TYPE_GROUP_MEETING,
    MODAL_TYPE_INSUFFICIENT_PERMISSIONS,
    MODAL_TYPE_INVITE_TEAM_MEMBER_V1,
    MODAL_TYPE_INVITE_TEAM_MEMBER_V2,
    MODAL_TYPE_INVITE_TO_TABLE,
    MODAL_TYPE_LANG_SELECT,
    MODAL_TYPE_LANGUAGE,
    MODAL_TYPE_LEGACY_AIRMEET_SUPPORT,
    MODAL_TYPE_LIGHT,
    MODAL_TYPE_LIVE_MARK_AS_READY,
    MODAL_TYPE_LIVE_PROMPT,
    MODAL_TYPE_MANAGE_SUBSCRIPTION,
    MODAL_TYPE_MEDIA_LIBRARY,
    MODAL_TYPE_METERED_PRICING,
    MODAL_TYPE_METERED_PRICING_DRAWER,
    MODAL_TYPE_METERING_POLICY,
    MODAL_TYPE_NETWORK_LOST,
    MODAL_TYPE_NETWORKING_SESSION,
    MODAL_TYPE_ONBOARDING_WELCOME_MODAL,
    MODAL_TYPE_PAUSE_EMAIL,
    MODAL_TYPE_PERMISSION_SETUP,
    MODAL_TYPE_REDUCE_ADDON_SEATS,
    MODAL_TYPE_REFRESH_INTEGRATION_APP,
    MODAL_TYPE_REGISTRATION_FORM,
    MODAL_TYPE_REGISTRATION_SUCCESS_MODAL,
    MODAL_TYPE_REGISTRATION_SYNC,
    MODAL_TYPE_REGISTRATIONS_CLOSED,
    MODAL_TYPE_RESOURCES,
    MODAL_TYPE_SAVE_REGISTRATION,
    MODAL_TYPE_SESSION_LEAVE_CONFIRMATION,
    MODAL_TYPE_SESSION_PLAYBACK_TOGGLE,
    MODAL_TYPE_SESSION_STARTED_PROMPT,
    MODAL_TYPE_SPEAKER_INVITE,
    MODAL_TYPE_STATUS_MODAL,
    MODAL_TYPE_TEAM_MEMBER_ADDON,
    MODAL_TYPE_TERMS_OF_SERVICE,
    MODAL_TYPE_TOAST_MODAL,
    MODAL_TYPE_UPDATE_PASSWORD,
    MODAL_TYPE_UPGRADE_PLAN,
    MODAL_TYPE_UPLOAD_MEDIA,
    MODAL_TYPE_USER_AUTH,
    MODAL_TYPE_USER_EDIT_PROFILE,
    MODAL_TYPE_VALIDATE_DUPLICATION,
    MODAL_TYPE_VIDEO,
    MODAL_TYPE_VIDEO_NEW,
    MODAL_TYPE_VIEW_ORGANIZER,
    RESYNC_MODAL_INTEGRATION,
    SYNC_MODAL_INTEGRATION,
};

const VideoModal = React.lazy(() => import('../molecules/modal/VideoModal'));
const SpeakerLinkModal = React.lazy(() =>
    import('../molecules/modal/SpeakerLinkModal')
);
const NewVideoModal = React.lazy(() =>
    import('../molecules/modal/NewVideoModal')
);
const SubscribeModal = React.lazy(() =>
    import('components/molecules/modal/SubscribeModal')
);
const AuthModal = React.lazy(() => import('./AuthModal_V2'));
const ConnectionLostModal = React.lazy(() => import('./ConnectionLostModal'));
const DemoModal = React.lazy(() => import('./DemoModal'));
const EventModal = React.lazy(() => import('./EventModal'));
const LiveEventPrompt = React.lazy(() => import('./LiveEventPrompt'));
const SessionStartedPrompt = React.lazy(() => import('./SessionStartedPrompt'));
const EditWelcomeModal = React.lazy(() => import('./EditWelcomeModal'));
const RegistrationModal = React.lazy(() => import('./RegistrationModal'));
const ConferenceTrialModal = React.lazy(() => import('./ConferenceTrialModal'));
const BlockUnblocAttendeekModal = React.lazy(() =>
    import('./BlockUnblockAttendeeModal')
);
const PostEventEntryRestricted = React.lazy(() =>
    import('components/eRegister/registration/PostEventEntryRestricted')
);
const BlockedAttendeeModal = React.lazy(() => import('./BlockedAttendeeModal'));

// const StreamPublishFailModal = React.lazy(() =>
//     import('./StreamPublishFailModal')
// );
const LiveEventMarkAsReadyModal = React.lazy(() =>
    import('./LiveEventMarkAsReadyModal')
);
const DashboardIntegrationModal = React.lazy(() =>
    import('./DashboardIntegrationModal')
);
const BoothsModal = React.lazy(() => import('./BoothsModal'));
const BoothHallModal = React.lazy(() => import('./BoothHallModal'));
const LangSelectionModal = React.lazy(() =>
    import('../molecules/modal/LangSelectionModal')
);
const SaveRegistrationModal = React.lazy(() =>
    import('./SaveRegistrationModal')
);
const AddVideoDrawer = React.lazy(() =>
    import('components/community/mediaLibrary/AddVideoDrawer')
);
const EventAccessModal = React.lazy(() => import('./EventAccessModal'));
const EventRestrictedModal = React.lazy(() => import('./EntryRestrictedModal'));
const AttendeeIntroModal = React.lazy(() => import('./AttendeeIntroModal'));
const UserEditProfileModal = React.lazy(() => import('./UserEditProfileModal'));
const LanguageModal = React.lazy(() => import('./LanguageModal'));
const StatusModal = React.lazy(() => import('./StatusModal'));
const AddMemberModal = React.lazy(() => import('./AddMemberModal'));
const CreateCommunityModal = React.lazy(() => import('./CreateCommunityModal'));
const DeleteMemberModal = React.lazy(() => import('./deleteMemberModal'));
const ToastPopup = React.lazy(() => import('../molecules/ToastPopup'));
const AppSumoModal = React.lazy(() => import('./AppSumoModal'));
const ExhibitorAccess = React.lazy(() => import('./accessNotFound'));
const AuthModalSSO = React.lazy(() => import('./AuthModal_SSO'));
const CheckoutFlow = React.lazy(() => import('./checkoutFlowModal'));

const AirmeetStatusConfirmation = React.lazy(() =>
    import('./airmeetStatusConfirmation')
);
const CustomRegistration = React.lazy(() =>
    import('../../views/customRegistration')
);
const AddPaymentDetails = React.lazy(() =>
    import('components/meteredPricing/AddPaymentDetails')
);
const DetailsTermsOfService = React.lazy(() =>
    import('../molecules/termsOfService/DetailsModal')
);
const BillingPlanUpgradeModal = React.lazy(() =>
    import('./BillingPlanUpgradeModal')
);
const EventEntryModal = React.lazy(() => import('./EventEntryModal'));

const EditLeaderboardActions = React.lazy(() =>
    import('./EditLeaderboardActions')
);
const localLog = logger.init('Modals:', 'yellow');
const MeteredModal = React.lazy(() => import('./MeteredModal'));
const AttendeeMeteredDrawer = React.lazy(() =>
    import('./AttendeeMeteredDrawer')
);
const TermsOfService = React.lazy(() => import('./TermsOfService'));
const MeteringPolicyModal = React.lazy(() => import('./MeteringPolicyModal'));
const ManageSubscriptionModal = React.lazy(() =>
    import('./ManageSubscriptionModal')
);

const RegistrationsClosedModal = React.lazy(() =>
    import('./RegistrationsClosedModal')
);
const UpgradePlanModal = React.lazy(() => import('./UpgradePlanModal'));

const PermissionSetupModal = React.lazy(() =>
    import('./postPermissionWorkflow')
);
const ConnectionFailureModal = React.lazy(() =>
    import('./SessionConnectivityFailure')
);
const LightModal = React.lazy(() => import('./templates/LightModal'));
const NetworkingSessionModal = React.lazy(() =>
    import('./NetworkingSessionModal')
);

const SessionPlayBackToggleModal = React.lazy(() =>
    import('./SessionPlayBackToggleModal')
);
const BoothSessionModal = React.lazy(() => import('./BoothSessionModal'));

const OnboardingQuestionModal = React.lazy(() =>
    import('./newOnboardingFlow/onBoardingQuestionModal')
);

const InviteOthersModal = React.lazy(() =>
    import('./newOnboardingFlow/inviteOthersModal')
);

const CreateOrganisationModal = React.lazy(() =>
    import('./newOnboardingFlow/CreateOrganisationModal')
);
const OrganisationTypeModal = React.lazy(() =>
    import('../newOnboardingFlow/createOrganisation/organisationTypeModal.js')
);

const ValidateDuplication = React.lazy(() =>
    import('./ValidateDuplicationModal')
);
const AssignHost = React.lazy(() => import('./AssignHostModal'));

const LegacyAirmeetSupportModal = React.lazy(() =>
    import('./LegacyAirmeetSupportModal')
);

const PlanFeatureList = React.lazy(() =>
    import('./ManageSubscription/PlanFeatureListModal')
);

const CustomiseEnterprisePlan = React.lazy(() =>
    import('./ManageSubscription/CustomiseEnterprisePlanModal')
);
const CreateMultiCommunity = React.lazy(() =>
    import('./multiCommunity/createMultiCommunityModal')
);

const OnboardingWelcomeModal = React.lazy(() =>
    import('./newOnboardingFlow/welcomeModal')
);
const PremiumFeatureModal = React.lazy(() => import('./PremiumFeatureModal'));
const BreakoutConfirmationModal = React.lazy(() =>
    import('./Breakout/BreakoutConfirmationModal')
);

const BreakoutAnnouncementModal = React.lazy(() =>
    import('./Breakout/AnnouncementModal')
);

const BreakoutCloseConfirmation = React.lazy(() =>
    import('./Breakout/BreakoutCloseConfirmation')
);

const AllowedAccessModal = React.lazy(() => import('./AllowedAccessModal'));
const BlacklistParticipantsModal = React.lazy(() =>
    import('./BlacklistParticipantsModal')
);

const EventAccessPanel = React.lazy(() => import('./EventAccessPanel'));

const RegistrationSuccessModal = React.lazy(() =>
    import('./RegistrationSuccessModal')
);
const AddInterestModal = React.lazy(() => import('./AddInterestModal'));

const CommunitySwitcherModal = React.lazy(() =>
    import('./CommunitySwitcherModal')
);
const ComparePlanModal = React.lazy(() => import('./ComparePlanModal'));
const EventShareModal = React.lazy(() => import('./EventShareModal'));
const SendEmailConfirmation = React.lazy(() =>
    import('./SendEmailConfirmation')
);
const TicketModal = React.lazy(() => import('./TicketModal'));
const CouponConfirmationModal = React.lazy(() =>
    import('./CouponConfirmationModal')
);
const ApplyCouponModal = React.lazy(() => import('./ApplyCouponModal'));

const EntryFlowModal = React.lazy(() => import('./EntryFlowModal'));
const DeleteFormModal = React.lazy(() => import('./DeleteFormModal'));
const ConfirmModal = React.lazy(() => import('./ConfirmModal'));
const PasswordCheckListModal = React.lazy(() =>
    import('./PasswordCheckListModal')
);

const EditImageModal = React.lazy(() => import('./EditImageModal'));

const FileViewerModal = React.lazy(() => import('./FileViewerModal'));
const ResourceModal = React.lazy(() => import('./ResourceModal'));
const ChangeRoleModal = React.lazy(() => import('./ChangeRoleModal'));
const InviteTeamMemberV2 = React.lazy(() => import('./InviteTeamMemberV2'));
const EditPermissionModal = React.lazy(() => import('./EditPermissionModal'));
const ViewOrganizersModal = React.lazy(() =>
    import('./rolesAndPermissions/ViewOrganizersModal')
);
const TeamMemberAddonModal = React.lazy(() =>
    import('../community/team/teamMemberAddon')
);
const ReduceSeatsLimitModal = React.lazy(() =>
    import('../ManageSeatsLimit/ReduceSeatLimit')
);
const UpdatePasswordModal = React.lazy(() => import('./UpdatePasswordModal'));
const EventTemplatePreviewModal = React.lazy(() =>
    import('./EventTemplatePreviewModal')
);
const EventSeriesPreviewModal = React.lazy(() =>
    import('./EventSeriesPreviewModal/')
);
const NotificationPopup = React.lazy(() => import('atoms/NotificationPopup'));

const MediaLibraryModal = React.lazy(() =>
    import('views/CMS/componentLibrary/MediaLibrary/index')
);
const UploadMediaModal = React.lazy(() =>
    import('components/community/mediaLibrary/UploadDrawer.js')
);
const LeadRegistrationFormModal = React.lazy(() =>
    import('views/CMS/componentLibrary/LeadRegistrationForm/index.js')
);
const InsufficientPermissionModal = React.lazy(() =>
    import('components/community/allmeets/AccessTrueModal')
);
const GoogleTagManagerModal = React.lazy(() =>
    import('./GoogleTagManagerModal')
);
const PauseEmailModal = React.lazy(() => import('./PauseEmailModal'));
const ResumeEmailModal = React.lazy(() => import('./ResumeEmailModal'));

const DeleteOrganizersModal = React.lazy(() =>
    import('./rolesAndPermissions/DeleteOrganizersModal')
);
const CreateFirstAirmeetFromTemplatesModal = React.lazy(() =>
    import('./CreateFirstAirmeetFromTemplatesModal')
);
const SpeakerReorder = React.lazy(() =>
    import('views/EventSeriesLandingPage/common/BuilderDrawer/SpeakerReorder')
);

const LeaderboardBlockDomain = React.lazy(() =>
    import('components/modals/LeaderboardBlockDomain.tsx')
);
const RegistrationSyncModal = React.lazy(() =>
    import(
        'components/community/editPublicAirmeet/Integrations/RegistrationSyncModal'
    )
);
const ResyncModal = React.lazy(() =>
    import('components/community/editPublicAirmeet/Integrations/ResyncModal')
);
const SyncModal = React.lazy(() =>
    import('components/community/editPublicAirmeet/Integrations/SyncData')
);
const AirGenieModal = React.lazy(() => import('./AirGenieModal/'));
const RefreshIntegrationApp = React.lazy(() =>
    import(
        'components/community/editPublicAirmeet/Integrations/RefreshIntegrationApp'
    )
);
const AirGenieImageModal = React.lazy(() =>
    import('./AirGenieModal/ImageModal')
);
const EmailTemplatesConfirmationModal = React.lazy(() =>
    import('components/modals/EmailTemplatesConfirmationModal')
);
const AddToCalendarModal = React.lazy(() => import('./AddToCalendarModal'));

const UserWaitlistedModal = React.lazy(() => import('./UserWaitlistedModal'));

const BaseModalV2 = React.lazy(() => import('./templates/BaseModalV2'));
const EditVideoHeadline = React.lazy(() => import('./EditVideoHeadline'));

const ComparePlansModalV2 = React.lazy(() => import('./ComparePlansModalV2'));

const EnrichmentModal = React.lazy(() =>
    import('components/community/integrations/EnrichmentModal')
);

const EventEntryOtpModal = React.lazy(() => import('./EventEntryOtpModal'));

const SessionLeaveConfirmationModal = React.lazy(() =>
    import('./SessionLeaveConfirmationModal')
);
const LazyLoaded = (Component) => (props) => (
    <Suspense fallback={<></>}>
        {props.portTo ? (
            <Portal
                container={props.portTo}
                children={<Component {...props} />}
            />
        ) : props.fullscreenSupport ? (
            <FullScreenPortal>
                <Component {...props} />
            </FullScreenPortal>
        ) : (
            <Component {...props} />
        )}
    </Suspense>
);

const _MODAL_MAP = {
    [MODAL_TYPE_ADD_PAYMENT_DETAILS]: {
        component: LazyLoaded(AddPaymentDetails),
    },
    [MODAL_TYPE_REGISTRATIONS_CLOSED]: {
        component: LazyLoaded(RegistrationsClosedModal),
    },
    [MODAL_TYPE_METERED_PRICING]: { component: LazyLoaded(MeteredModal) },
    [MODAL_TYPE_METERED_PRICING_DRAWER]: {
        component: LazyLoaded(AttendeeMeteredDrawer),
    },
    [MODAL_TYPE_TERMS_OF_SERVICE]: { component: LazyLoaded(TermsOfService) },
    [MODAL_TYPE_DETAILS_TERMS_OF_SERVICE]: {
        component: LazyLoaded(DetailsTermsOfService),
    },
    [MODAL_TYPE_AIRMEET_DEMO]: { component: LazyLoaded(DemoModal) },
    [MODAL_TYPE_EVENT_DETAILS]: { component: LazyLoaded(EventModal) },
    [MODAL_TYPE_USER_AUTH]: { component: LazyLoaded(AuthModal) },
    [MODAL_TYPE_CONNECTION_STATE]: {
        component: LazyLoaded(ConnectionLostModal),
    },
    [MODAL_TYPE_EVENT_REGISTRATION]: {
        component: LazyLoaded(RegistrationModal),
    },
    [MODAL_TYPE_LIVE_PROMPT]: { component: LazyLoaded(LiveEventPrompt) },
    [MODAL_TYPE_SESSION_STARTED_PROMPT]: {
        component: LazyLoaded(SessionStartedPrompt),
    },
    [MODAL_TYPE_EDIT_RECEPTION]: { component: LazyLoaded(EditWelcomeModal) },
    // [MODAL_TYPE_STREAM_PUBLISH_FAIL]: {
    //     component: LazyLoaded(StreamPublishFailModal),
    // },
    [MODAL_TYPE_LIVE_MARK_AS_READY]: {
        component: LazyLoaded(LiveEventMarkAsReadyModal),
    },
    [MODAL_TYPE_PERMISSION_SETUP]: {
        component: LazyLoaded(PermissionSetupModal),
    },
    [MODAL_TYPE_CONNECTIVITY_FAILURE]: {
        component: LazyLoaded(ConnectionFailureModal),
    },
    [MODAL_TYPE_CREATE_COMMUNITY]: {
        component: LazyLoaded(CreateCommunityModal),
    },
    [MODAL_TYPE_SPEAKER_INVITE]: {
        component: LazyLoaded(SpeakerLinkModal),
    },
    [MODAL_TYPE_DASHBOARD_INTEGRATION]: {
        component: LazyLoaded(DashboardIntegrationModal),
    },
    [MODAL_TYPE_BOOTHS]: {
        component: LazyLoaded(BoothsModal),
    },
    [MODAL_TYPE_BOOTH_HALL]: {
        component: LazyLoaded(BoothHallModal),
    },
    [MODAL_TYPE_DELETE_MEMBER]: {
        component: LazyLoaded(DeleteMemberModal),
    },
    [MODAL_TYPE_NETWORK_LOST]: {
        component: LazyLoaded(NetworkLostModal),
    },
    [MODAL_TYPE_VIDEO]: {
        component: LazyLoaded(VideoModal),
    },
    [MODAL_TYPE_LANG_SELECT]: {
        component: LazyLoaded(LangSelectionModal),
    },
    [MODAL_TYPE_VIDEO_NEW]: {
        component: LazyLoaded(NewVideoModal),
    },
    [MODAL_TYPE_BOOTH_SUBSCRIBE]: {
        component: LazyLoaded(SubscribeModal),
    },
    [MODAL_TYPE_STATUS_MODAL]: {
        component: LazyLoaded(StatusModal),
    },
    [MODAL_TYPE_SESSION_LEAVE_CONFIRMATION]: {
        component: LazyLoaded(SessionLeaveConfirmationModal),
    },
    [MODAL_TYPE_TOAST_MODAL]: {
        component: LazyLoaded(ToastPopup),
    },
    [MODAL_TYPE_APPSUMO]: {
        component: LazyLoaded(AppSumoModal),
    },
    [MODAL_TYPE_EXHIBITOR_ACCESS_DENIED]: {
        component: LazyLoaded(ExhibitorAccess),
    },
    [MODAL_TYPE_AIRMEET_STATUS_CONFIRMATION]: {
        component: LazyLoaded(AirmeetStatusConfirmation),
    },
    [MODAL_TYPE_EVENT_ACCESS]: {
        component: LazyLoaded(EventAccessModal),
    },
    [MODAL_TYPE_ENTRY_RESTRICTED]: {
        component: LazyLoaded(EventRestrictedModal),
    },
    [MODAL_TYPE_ATTENDEE_INTRO]: {
        component: LazyLoaded(AttendeeIntroModal),
    },
    [MODAL_TYPE_USER_EDIT_PROFILE]: {
        component: LazyLoaded(UserEditProfileModal),
    },
    [MODAL_TYPE_METERING_POLICY]: {
        component: LazyLoaded(MeteringPolicyModal),
    },
    [MODAL_TYPE_BILLING_PLAN_UPGRADE]: {
        component: LazyLoaded(BillingPlanUpgradeModal),
    },
    [MODAL_TYPE_CHECKOUTFLOW]: {
        component: LazyLoaded(CheckoutFlow),
    },
    [MODAL_TYPE_SAVE_REGISTRATION]: {
        component: LazyLoaded(SaveRegistrationModal),
    },
    [MODAL_TYPE_EVENT_ENTRY]: {
        component: LazyLoaded(EventEntryModal),
    },
    [MODAL_TYPE_CONFERENCE_TRIAL]: {
        component: LazyLoaded(ConferenceTrialModal),
    },
    [MODAL_TYPE_AUTH_SSO]: { component: LazyLoaded(AuthModalSSO) },
    [MODAL_TYPE_CUSTOM_REGISTRATION]: {
        component: LazyLoaded(CustomRegistration),
    },
    [MODAL_TYPE_LANGUAGE]: {
        component: LazyLoaded(LanguageModal),
    },
    [MODAL_TYPE_UPGRADE_PLAN]: {
        component: LazyLoaded(UpgradePlanModal),
    },
    [MODAL_TYPE_LIGHT]: {
        component: LazyLoaded(LightModal),
    },
    [MODAL_TYPE_NETWORKING_SESSION]: {
        component: LazyLoaded(NetworkingSessionModal),
    },
    [MODAL_TYPE_GROUP_MEETING]: {
        component: LazyLoaded(NetworkingSessionModal),
    },
    [MODAL_TYPE_BOOTH_SESSION]: {
        component: LazyLoaded(BoothSessionModal),
    },
    [MODAL_TYPE_SESSION_PLAYBACK_TOGGLE]: {
        component: LazyLoaded(SessionPlayBackToggleModal),
    },
    [MODAL_TYPE_ONBOARDING_QUESTION_MODAL]: {
        component: LazyLoaded(OnboardingQuestionModal),
    },
    [MODAL_TYPE_CREATE_ORGANISATION]: {
        component: LazyLoaded(CreateOrganisationModal),
    },
    [MODAL_TYPE_SHOW_ORGANISATION_TYPE]: {
        component: LazyLoaded(OrganisationTypeModal),
    },
    [MODAL_TYPE_INVITE_OTHERS]: {
        component: LazyLoaded(InviteOthersModal),
    },

    [MODAL_TYPE_VALIDATE_DUPLICATION]: {
        component: LazyLoaded(ValidateDuplication),
    },
    [MODAL_TYPE_ASSIGN_HOST]: {
        component: LazyLoaded(AssignHost),
    },
    [MODAL_TYPE_EDIT_LEADERBOARD_ACTIONS]: {
        component: LazyLoaded(EditLeaderboardActions),
    },
    [MODAL_TYPE_LEGACY_AIRMEET_SUPPORT]: {
        component: LazyLoaded(LegacyAirmeetSupportModal),
    },
    [MODAL_TYPE_PLAN_FEATURE_LIST]: {
        component: LazyLoaded(PlanFeatureList),
    },
    [MODAL_TYPE_MANAGE_SUBSCRIPTION]: {
        component: LazyLoaded(ManageSubscriptionModal),
    },
    [MODAL_TYPE_CUSTOMISE_ENTERPRISE_PLAN]: {
        component: LazyLoaded(CustomiseEnterprisePlan),
    },
    [MODAL_TYPE_CREATE_MULTI_COMMUNITY]: {
        component: LazyLoaded(CreateMultiCommunity),
    },
    [MODAL_TYPE_ONBOARDING_WELCOME_MODAL]: {
        component: LazyLoaded(OnboardingWelcomeModal),
    },
    [MODAL_TYPE_MEDIA_LIBRARY]: {
        component: LazyLoaded(MediaLibraryModal),
    },
    [MODAL_TYPE_UPLOAD_MEDIA]: {
        component: LazyLoaded(UploadMediaModal),
    },
    [MODAL_TYPE_REGISTRATION_FORM]: {
        component: LazyLoaded(LeadRegistrationFormModal),
    },
    [MODAL_TYPE_PREMIUM_FEATURE]: {
        component: LazyLoaded(PremiumFeatureModal),
    },
    [MODAL_TYPE_BREAKOUT_CONFIRMATION]: {
        component: LazyLoaded(BreakoutConfirmationModal),
    },
    [MODAL_TYPE_BREAKOUT_ANNOUNCEMENT]: {
        component: LazyLoaded(BreakoutAnnouncementModal),
    },
    [MODAL_TYPE_BREAKOUT_CLOSE_CONFIRMATION]: {
        component: LazyLoaded(BreakoutCloseConfirmation),
    },
    [MODAL_TYPE_ALLOWED_ACCESS]: {
        component: LazyLoaded(AllowedAccessModal),
    },
    [MODAL_TYPE_BLACKLIST_PARTICIPANTS]: {
        component: LazyLoaded(BlacklistParticipantsModal),
    },
    [MODAL_TYPE_EVENT_ACCESS_PANEL]: {
        component: LazyLoaded(EventAccessPanel),
    },
    [MODAL_TYPE_REGISTRATION_SUCCESS_MODAL]: {
        component: LazyLoaded(RegistrationSuccessModal),
    },
    [MODAL_TYPE_ADD_INTEREST]: {
        component: LazyLoaded(AddInterestModal),
    },
    [MODAL_TYPE_COMMUNITY_SWITCHER]: {
        component: LazyLoaded(CommunitySwitcherModal),
    },
    [MODAL_TYPE_USER_EDIT_PROFILE]: {
        component: LazyLoaded(UserEditProfileModal),
    },
    [MODAL_TYPE_COMPARE_PLAN]: {
        component: LazyLoaded(ComparePlanModal),
    },
    [MODAL_TYPE_EVENT_SHARE]: {
        component: LazyLoaded(EventShareModal),
    },
    [MODAL_TYPE_SEND_EMAIL_CONFIRMATION]: {
        component: LazyLoaded(SendEmailConfirmation),
    },
    [MODAL_TYPE_TEAM_MEMBER_ADDON]: {
        component: LazyLoaded(TeamMemberAddonModal),
    },
    [MODAL_TYPE_REDUCE_ADDON_SEATS]: {
        component: LazyLoaded(ReduceSeatsLimitModal),
    },
    [MODAL_TYPE_TICKET]: {
        component: LazyLoaded(TicketModal),
    },
    [MODAL_TYPE_COUPON_CONFIRM]: {
        component: LazyLoaded(CouponConfirmationModal),
    },
    [MODAL_TYPE_APPLY_COUPON]: {
        component: LazyLoaded(ApplyCouponModal),
    },
    [MODAL_TYPE_ENTRY_FLOW]: {
        component: LazyLoaded(EntryFlowModal),
    },
    [MODAL_TYPE_CHANGE_ROLE]: {
        component: LazyLoaded(ChangeRoleModal),
    },
    [MODAL_TYPE_INVITE_TEAM_MEMBER_V2]: {
        component: LazyLoaded(InviteTeamMemberV2),
    },
    [MODAL_TYPE_INVITE_TEAM_MEMBER_V1]: {
        component: LazyLoaded(AddMemberModal),
    },
    [MODAL_TYPE_EDIT_PERMISSION]: {
        component: LazyLoaded(EditPermissionModal),
    },
    [MODAL_TYPE_DELETE_FORM]: {
        component: LazyLoaded(DeleteFormModal),
    },
    [MODAL_TYPE_CONFIRM]: {
        component: LazyLoaded(ConfirmModal),
    },
    [MODAL_TYPE_PASSWORD_CHECKLIST]: {
        component: LazyLoaded(PasswordCheckListModal),
    },
    [MODAL_TYPE_EDIT_IMAGE]: {
        component: LazyLoaded(EditImageModal),
    },
    [MODAL_TYPE_FILE_VIEWER]: {
        component: LazyLoaded(FileViewerModal),
    },
    [MODAL_TYPE_RESOURCES]: {
        component: LazyLoaded(ResourceModal),
    },
    [MODAL_TYPE_UPDATE_PASSWORD]: {
        component: LazyLoaded(UpdatePasswordModal),
    },
    [MODAL_TYPE_VIEW_ORGANIZER]: {
        component: LazyLoaded(ViewOrganizersModal),
    },
    [MODAL_TYPE_PREVIEW_EVENT_TEMPLATE]: {
        component: LazyLoaded(EventTemplatePreviewModal),
    },
    [MODAL_TYPE_PREVIEW_EVENT_SERIES]: {
        component: LazyLoaded(EventSeriesPreviewModal),
    },
    [MODAL_TYPE_NOTIFICATION_POPUP]: {
        component: LazyLoaded(NotificationPopup),
    },
    [MODAL_TYPE_CONFIRMATION]: {
        component: LazyLoaded(ConfirmationModal),
    },
    [MODAL_TYPE_INSUFFICIENT_PERMISSIONS]: {
        component: LazyLoaded(InsufficientPermissionModal),
    },
    [MODAL_TYPE_BLOCK_UNBLOCK_ATTENDEE]: {
        component: LazyLoaded(BlockUnblocAttendeekModal),
    },
    [MODAL_TYPE_ATTENDEE_BLOCKED]: {
        component: LazyLoaded(BlockedAttendeeModal),
    },
    [MODAL_TYPE_GOOGLE_TAG_MANAGER]: {
        component: LazyLoaded(GoogleTagManagerModal),
    },
    [MODAL_TYPE_TEST_MAIL]: {
        component: LazyLoaded(TestMailModal),
    },
    [MODAL_TYPE_PAUSE_EMAIL]: {
        component: LazyLoaded(PauseEmailModal),
    },
    [MODAL_TYPE_RESUME_EMAIL]: {
        component: LazyLoaded(ResumeEmailModal),
    },
    [MODAL_TYPE_DELETE_ORGANIZER]: {
        component: LazyLoaded(DeleteOrganizersModal),
    },
    [MODAL_TYPE_POST_EVENT_ENTRY_RESTRICTED]: {
        component: LazyLoaded(PostEventEntryRestricted),
    },
    [MODAL_TYPE_EDIT_LEADERBOARD_BLOCK_DOMAIN]: {
        component: LazyLoaded(LeaderboardBlockDomain),
    },
    [MODAL_TYPE_REGISTRATION_SYNC]: {
        component: LazyLoaded(RegistrationSyncModal),
    },
    [MODAL_TYPE_CREATE_FIRST_AIRMEET]: {
        component: LazyLoaded(CreateFirstAirmeetFromTemplatesModal),
    },
    [MODAL_TYPE_SPEAKER_REORDER]: {
        component: LazyLoaded(SpeakerReorder),
    },
    [MODAL_TYPE_REFRESH_INTEGRATION_APP]: {
        component: LazyLoaded(RefreshIntegrationApp),
    },
    [MODAL_TYPE_AIR_GENIE]: {
        component: LazyLoaded(AirGenieModal),
    },
    [MODAL_TYPE_ADD_TO_CALENDAR]: {
        component: LazyLoaded(AddToCalendarModal),
    },
    [MODAL_TYPE_USER_WAITLISTED]: {
        component: LazyLoaded(UserWaitlistedModal),
    },
    [RESYNC_MODAL_INTEGRATION]: {
        component: LazyLoaded(ResyncModal),
    },
    [SYNC_MODAL_INTEGRATION]: {
        component: LazyLoaded(SyncModal),
    },
    [MODAL_TYPE_AIR_GENIE_IMAGE]: {
        component: LazyLoaded(AirGenieImageModal),
    },
    [EMAIL_TEMPLATE_CONFIRMATION]: {
        component: LazyLoaded(EmailTemplatesConfirmationModal),
    },
    [DISABLE_ENRICHMENT_MODAL]: {
        component: LazyLoaded(EnrichmentModal),
    },
    [MODAL_TYPE_BASE_MODAL_V2]: {
        component: LazyLoaded(BaseModalV2),
    },
    [MODAL_TYPE_COMPARE_PLANS_V2]: {
        component: LazyLoaded(ComparePlansModalV2),
    },
    [MODAL_TYPE_EVENT_NOT_ACCESSIBLE]: {
        component: LazyLoaded(EventNotAccessibleModal),
    },
    [MODAL_TYPE_ADD_VIDEO]: {
        component: LazyLoaded(AddVideoDrawer),
    },
    [MODAL_EDIT_VIDEO_HEADLINE]: {
        component: LazyLoaded(EditVideoHeadline),
    },
    [AUDIO_RESUME_MODAL]: {
        component: LazyLoaded(AudioResumeModal),
    },
    [MODAL_TYPE_EVENT_ENTRY_OTP]: {
        component: LazyLoaded(EventEntryOtpModal),
    },
};

function AppModals() {
    const dispatch = useDispatch();
    const { user: authUser } = useAuthActionHandler();
    const community_id = useSelector(
        ({ lounge }) => lounge?.airmeet?.community_id || null
    );
    const { isDesktopMode, isAndroid, isIOS } = useDeviceInfo();
    const deviceSource = isDesktopMode
        ? 'Web'
        : (isAndroid && 'Android') || (isIOS && 'iOS');

    // Central store for modal props
    const { active, hide, props, allowClose = true } = useSelector(
        (state) => state.layout.modal
    );

    const modalKey = props?.modalKey;

    // Local state to manage active modals
    const [activeStack, setActiveStack] = useState([]);

    // Manage the active stack when active changes
    useEffect(() => {
        const newStack = [...activeStack];
        if (active) {
            // If we're opening a new modal

            // Set common close handler
            const closeHandler = props.onClose;
            props.onClose = () => {
                if (closeHandler) {
                    closeHandler();
                }

                dispatch(closeAppModal(active));
            };

            localLog('Showing modal', active);
            // Stack the new modal
            newStack.push({ active, props });
        } else if (hide) {
            // If we're closing a specific modal
            let hideIndex = newStack.findIndex((el) => el.active === hide);
            while (hideIndex >= 0) {
                // Close every instance of it
                const lastActive = newStack.splice(hideIndex, 1);
                hideIndex = newStack.findIndex((el) => el.active === hide);

                localLog(
                    'Closed specific modal',
                    lastActive.length ? lastActive[0].active : 'none'
                );
            }
        } else {
            // If we're closing the top most modal
            const lastActive = newStack.pop();
            localLog(
                'Closed top modal',
                lastActive ? lastActive.active : 'none'
            );
        }

        setActiveStack(newStack);
    }, [active, hide, modalKey]);

    const updateUserProfileClosed = async () => {
        let user = await UserService.getInstance().fetchUser(authUser?.id);

        FirebaseLogger.updateUserProfileDiscarded({
            userId: authUser?.id,
            airmeetId: props.airmeetId,
            communityId: community_id,
            userRole: user?.role,
            deviceInfo: deviceSource,
        });
    };

    return activeStack.length && !PermissionUtils.isEventCloudHost() ? (
        <div>
            {activeStack.map(
                ({ active: activeModal, props: activeProps }, index) => {
                    const { component: ActiveModal, props: extraProps = {} } =
                        activeModal && activeModal in _MODAL_MAP
                            ? _MODAL_MAP[activeModal]
                            : null;
                    const handleClose = () => {
                        dispatch(closeAppModal(activeModal));
                        if (activeProps.onClose) activeProps.onClose();
                    };
                    return (
                        <ActiveModal
                            key={`app-modal-${activeModal}-${index}`}
                            {...extraProps}
                            {...activeProps}
                            onClose={handleClose}
                        />
                    );
                }
            )}
            {/* Dark tint BG with close on click */}
            {props.hasBG !== false ? (
                <Wrapper
                    className={`modal-backdrop fade show ${
                        props.backdropClassName || ''
                    }`}
                    style={props.backdropStyles ? props.backdropStyles : {}}
                    onClick={() => {
                        if (allowClose) {
                            if (active === MODAL_TYPE_USER_EDIT_PROFILE) {
                                updateUserProfileClosed();
                            }
                            dispatch(closeAppModal());
                            if (props.onBackdropClick) {
                                props.onBackdropClick();
                            }
                        }
                    }}
                />
            ) : null}
        </div>
    ) : null;
}

export default AppModals;

const Wrapper = styled.div`
    ${({ theme }) =>
        theme?.isLightTheme && theme?.isDashboardBrandingPage
            ? `
        background-color: rgba(10, 9, 11, 0.5);
    `
            : ''}
`;
