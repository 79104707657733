import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Typography } from 'foundations/typography';
import React, { useMemo } from 'react';
import { noComponent, emptyObject } from 'utils/constants/common';
import Select from 'react-select';

export default function CcPrimaryLangSelect({
    options,
    selectStyles = emptyObject,
    isLoading = false,
    onChange,
    style = emptyObject,
    ccPrimaryLang,
}) {
    const value = useMemo(
        () =>
            ccPrimaryLang
                ? options.primaryLangs?.find(
                      (option) => option?.label === ccPrimaryLang?.label
                  )
                : options.primaryLangs[0],
        [ccPrimaryLang, options]
    );

    return (
        <FlexDiv
            flexDirection='column'
            alignItems='stretch'
            flex='1'
            pt='16px'
            style={style}
        >
            <Typography variant='subtitle2-bold' color='ambience.0' pb='5px'>
                Event Caption Language
            </Typography>
            <Typography variant='subtext2' color='ambience.6' pb='8px'>
                Choose the language in which stage participants will communicate
            </Typography>
            <Select
                defaultValue={options.primaryLangs[0]}
                name='language'
                options={options.primaryLangs}
                components={{
                    IndicatorSeparator: noComponent,
                    ClearIndicator: noComponent,
                }}
                styles={selectStyles}
                isDisabled={isLoading}
                onChange={onChange}
                value={value}
            />
        </FlexDiv>
    );
}
