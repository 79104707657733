import Btn from 'atoms/Button/btn';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import FocusTrap from 'atoms/FocusTrap';
import { alpha } from 'foundations/theme';
import { Text } from 'foundations/themeV2/text';
import { useCustomTranslation } from 'hooks/useCustomTranslation';
import keys from 'locale/keys';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Box, FlexDiv, UnstyledButton } from '../utils/basicComponents';

const LocalDialog = ({
    title = '',
    children = null,
    confirmLabel,
    onConfirm,
    closeLabel,
    onClose,
    className = '',
    variant,
    zIndex = 10,
}) => {
    const { t } = useCustomTranslation();
    if (!confirmLabel) {
        confirmLabel = t(keys.GENERAL_CONFIRM);
    }
    if (!closeLabel) {
        closeLabel = t(keys.GENERAL_CANCEL);
    }
    return (
        <Container
            className={className}
            position='absolute'
            top='0px'
            right='0px'
            bottom='0px'
            left='0px'
            zIndex={zIndex}
        >
            <FocusTrap focusTrapOptions={{ initialFocus: true }}>
                <DialogDisplay
                    display='flex'
                    flexDirection='column'
                    justifyContent='space-between'
                    width='100%'
                    maxWidth='340px'
                    position='absolute'
                    top='50%'
                    left='50%'
                    className='local-dialog-display'
                    p='x4'
                >
                    {variant === 'v2' ? (
                        <>
                            <UnstyledButton
                                position='absolute'
                                top='20px'
                                right='20px'
                                onClick={onClose}
                            >
                                <StyledSvgSprite
                                    icon='icon_dismiss_cross_light'
                                    width='26px'
                                    height='26px'
                                />
                            </UnstyledButton>
                            {children}
                            <FlexDiv
                                width='100%'
                                alignSelf='flex-end'
                                className='button-wrapper'
                                justifyContent='space-evenly'
                                alignItems='center'
                            >
                                {onClose && (
                                    <Btn variant='link' onClick={onClose}>
                                        {closeLabel}
                                    </Btn>
                                )}
                                {onConfirm && (
                                    <Btn variant='primary' onClick={onConfirm}>
                                        {confirmLabel}
                                    </Btn>
                                )}
                            </FlexDiv>
                        </>
                    ) : (
                        <>
                            <Box width='100%'>
                                <Text variant='h5'>{title}</Text>
                            </Box>
                            <Box
                                width='100%'
                                className='local-dialog-content'
                                mt='x3'
                                mb='x5'
                            >
                                {children}
                            </Box>
                            <Box
                                display='flex'
                                width='100%'
                                alignSelf='flex-end'
                                className='button-wrapper'
                            >
                                {onConfirm && (
                                    <Box mr={'x2'}>
                                        <Btn
                                            variant='danger'
                                            size='small'
                                            onClick={onConfirm}
                                            className='confirm-button'
                                            mr={'x2'}
                                        >
                                            {confirmLabel}
                                        </Btn>
                                    </Box>
                                )}
                                {onClose && (
                                    <Btn
                                        variant='secondary'
                                        size='small'
                                        onClick={onClose}
                                        className='close-button'
                                    >
                                        {closeLabel}
                                    </Btn>
                                )}
                            </Box>
                        </>
                    )}
                </DialogDisplay>
            </FocusTrap>
        </Container>
    );
};

LocalDialog.propTypes = {
    /**
     * Title of the Dialog
     */
    title: PropTypes.string,
    /**
     * Element that will be rendered as its children
     */
    children: PropTypes.any,
    /**
     * Confirm button text
     */
    confirmLabel: PropTypes.string,
    /**
     * onConfirm callback fired when user clicks on confirm button
     * if not provided, then no button confirm rendered
     */
    onConfirm: PropTypes.func,
    /**
     * Close button text
     */
    closeLabel: PropTypes.string,
    /**
     * onClose callback fired when user clicks on cancel button
     * if not provided, then no cancel button rendered
     */
    onClose: PropTypes.func,
    /**
     * Class or classes (separated by space) to be applied
     */
    className: PropTypes.string,
};

const Container = styled(Box)`
    background-color: ${({ theme }) =>
        theme.isLightTheme
            ? alpha(theme.colors.ambience[5], 0.5)
            : alpha(theme.colors.ambience[23], 0.8)};
`;

const DialogDisplay = styled(Box)`
    background-color: ${({ theme }) => theme.colors.fill.default1};
    border: solid 1px ${({ theme }) => theme.colors.border.default1};
    border-radius: ${({ theme }) => theme.radii.x3};

    transform: translate(-50%, -50%);
    box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.2);
`;

export default LocalDialog;
