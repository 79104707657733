import * as Label from '@radix-ui/react-label';
import * as RadioGroup from '@radix-ui/react-radio-group';
import { FlexDiv } from 'components/molecules/utils/basicComponents';
import { Text } from 'foundations/themeV2/text/';
import isBoolean from 'lodash/isBoolean';
import React from 'react';
import styled from 'styled-components';

type VariantEnum = 'cta' | undefined;
interface RadioProps {
    defaultValue?: string;
    ariaLabel?: string;
    name?: string;
    disabled?: Boolean;
    options: Array<string>;
    value?: string;
    onValueChange: () => void;
    variant?: VariantEnum;
    label?: string;
    size?: string;
    errorMsg?: string;
}

const RadioGroupV2: React.FC<RadioProps> = ({
    defaultValue,
    ariaLabel,
    name,
    disabled,
    options = [],
    label,
    size = 'large',
    errorMsg,
    value,
    onValueChange,
    variant,
}) => {
    return (
        <>
            {label && (
                <InputLabel>
                    <Text variant={size === 'small' ? 'caption' : 'body2'}>
                        {label}
                    </Text>
                </InputLabel>
            )}
            <RadioGroupRoot
                className='RadioGroupRoot'
                defaultValue={defaultValue}
                disabled={disabled}
                aria-label={ariaLabel}
                value={value}
                onValueChange={onValueChange}
                name={name}
                variant={variant}
                role='radiogroup'
            >
                {(options || []).map((option) =>
                    option?.value || isBoolean(option?.value) ? (
                        <>
                            <RadioWrapper
                                key={option?.label}
                                className='ItemWrapper'
                                variant={variant}
                            >
                                <RadioGroupItem
                                    className='RadioGroupItem'
                                    value={option?.value}
                                    id={`${name}-${option?.value}`}
                                >
                                    <RadioGroupIndicator className='RadioGroupIndicator' />
                                </RadioGroupItem>
                                {option?.label && (
                                    <Label.Root
                                        className='LabelRoot'
                                        htmlFor={`${name}-${option?.value}`}
                                    >
                                        <Text variant='body2'>
                                            {option?.label}
                                        </Text>
                                    </Label.Root>
                                )}
                            </RadioWrapper>
                            {option?.value === value
                                ? option?.activeChildren
                                : null}
                        </>
                    ) : null
                )}
            </RadioGroupRoot>
            {errorMsg && (
                <InputInfo>
                    <ErrorMsg>
                        <Text variant='caption' color='sem.error'>
                            {errorMsg}
                        </Text>
                    </ErrorMsg>
                </InputInfo>
            )}
        </>
    );
};

export default RadioGroupV2;

const RadioGroupRoot = styled(RadioGroup.Root)`
    display: flex;
    flex-direction: column;

    .LabelRoot {
        color: ${({ theme }) => theme.colors.text.default.primary};
        margin-left: ${({ theme }) => theme.space.x2};
    }

    ${({ variant, theme }) =>
        variant === 'cta' &&
        `
        flex-direction: row;
        width: 100%;
        gap: ${theme.space.x2};
        .LabelRoot {
            margin-bottom: 0;
            flex: 1;
            cursor: pointer;
        }
    `}

    &[data-disabled] {
        opacity: 0.5;
    }
`;

const RadioGroupItem = styled(RadioGroup.Item)`
    background-color: ${({ theme }) => theme.colors.fill.default3};
    border: 2px solid ${({ theme }) => theme.colors.border.other1};
    border-radius: 100%;
    width: 20px;
    min-width: 20px;
    height: 20px;
    min-height: 20px;
    padding: 0;

    &:hover {
        outline: 2px solid ${({ theme }) => theme.colors.fill.default1};
        box-shadow: 0 0 0 3px ${({ theme }) => theme.colors.border.other1};
    }
    &:focus {
        box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.border.other1};
        outline: 4px solid ${({ theme }) => theme.colors.border.default1};
    }
    &[data-state='checked'] {
        border-color: ${({ theme }) => theme.colors.brandDefault};
    }
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        outline: none;
        box-shadow: none;
    }
`;

const RadioWrapper = styled(FlexDiv)`
    ${({ variant, theme }) =>
        variant === 'cta' &&
        `
        padding: calc(${theme.space.x2} + ${theme.space.x0});
        padding-right: ${theme.space.x3};
        border-radius: ${theme.radii.x4};
        background-color: ${theme.colors.fill.other3};
        flex: 1;
        border: 1px solid transparent;
        &:has(${RadioGroupItem}[data-state='checked']){
            border-color: ${theme.colors.border.other1};
        }
    `}
`;

const RadioGroupIndicator = styled(RadioGroup.Indicator)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 8px;

    &:after {
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.brandDefault};
    }
`;

const InputLabel = styled(Label.Root)`
    display: block;
    margin-bottom: ${({ theme }) => theme.space.x1};
`;

const InputInfo = styled(FlexDiv)`
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
`;

const ErrorMsg = styled.div`
    display: inline-flex;
    padding: 0;
    margin-top: ${({ theme }) => theme.space.x1};
    margin-right: ${({ theme }) => theme.space.x3};
`;
