export const MODAL_TYPE_REGISTRATIONS_CLOSED =
    'MODAL_TYPE_REGISTRATIONS_CLOSED';
export const MODAL_TYPE_METERED_PRICING = 'MODAL_TYPE_METERED_PRICING';
export const MODAL_TYPE_METERED_PRICING_DRAWER =
    'MODAL_TYPE_METERED_PRICING_DRAWER';
export const MODAL_TYPE_TERMS_OF_SERVICE = 'MODAL_TYPE_TERMS_OF_SERVICE';
export const MODAL_TYPE_DETAILS_TERMS_OF_SERVICE =
    'MODAL_TYPE_DETAILS_TERMS_OF_SERVICE';
export const MODAL_TYPE_AIRMEET_DEMO = 'AIRMEET_DEMO';
export const MODAL_TYPE_EVENT_DETAILS = 'EVENT_DETAILS';
export const MODAL_TYPE_USER_AUTH = 'USER_AUTH';
export const MODAL_TYPE_CONNECTION_STATE = 'CONNECTION_STATE';
export const MODAL_TYPE_EVENT_REGISTRATION = 'EVENT_REGISTRATION';
export const MODAL_TYPE_LIVE_PROMPT = 'LIVE_PROMPT';
export const MODAL_TYPE_SESSION_STARTED_PROMPT = 'SESSION_STARTED_PROMPT';
export const MODAL_TYPE_EDIT_LOUNGE = 'EDIT_LOUNGE';
export const MODAL_TYPE_EDIT_RECEPTION = 'EDIT_RECEPTION';
// export const MODAL_TYPE_STREAM_PUBLISH_FAIL = 'STREAM_PUBLISH_FAIL';
export const MODAL_TYPE_LIVE_MARK_AS_READY = 'MODAL_TYPE_LIVE_MARK_AS_READY';
export const MODAL_TYPE_PERMISSION_SETUP = 'MODAL_TYPE_PERMISSION_SETUP';
export const MODAL_TYPE_CONNECTIVITY_FAILURE =
    'MODAL_TYPE_CONNECTIVITY_FAILURE';
export const MODAL_TYPE_CREATE_COMMUNITY = 'CREATE_COMMUNITY';
export const MODAL_TYPE_SPEAKER_INVITE = 'MODAL_TYPE_SPEAKER_INVITE';
export const MODAL_TYPE_DASHBOARD_INTEGRATION =
    'MODAL_TYPE_DASHBOARD_INTEGRATION';
export const MODAL_TYPE_BOOTHS = 'MODAL_TYPE_BOOTHS';
export const MODAL_TYPE_BOOTH_HALL = 'MODAL_TYPE_BOOTH_HALL';
export const MODAL_TYPE_NETWORK_LOST = 'MODAL_TYPE_NETWORK_LOST';
export const MODAL_TYPE_VIDEO = 'MODAL_TYPE_VIDEO';
export const MODAL_TYPE_LANG_SELECT = 'MODAL_TYPE_LANG_SELECT';
export const MODAL_TYPE_VIDEO_NEW = 'MODAL_TYPE_VIDEO_NEW';
export const MODAL_TYPE_BOOTH_SUBSCRIBE = 'MODAL_TYPE_BOOTH_SUBSCRIBE';
export const MODAL_TYPE_ADD_MEMBER = 'MODAL_TYPE_ADD_MEMBER';
export const MODAL_TYPE_DELETE_MEMBER = 'MODAL_TYPE_DELETE_MEMBER';
export const MODAL_TYPE_STATUS_MODAL = 'MODAL_TYPE_STATUS_MODAL';
export const MODAL_TYPE_TOAST_MODAL = 'MODAL_TYPE_TOAST_MODAL';
export const MODAL_TYPE_APPSUMO = 'MODAL_TYPE_APPSUMO';
export const MODAL_TYPE_EXHIBITOR_ACCESS_DENIED =
    'MODAL_TYPE_EXHIBITOR_ACCESS_DENIED';
export const MODAL_TYPE_SAVE_REGISTRATION = 'MODAL_TYPE_SAVE_REGISTRATION';
export const MODAL_TYPE_AIRMEET_STATUS_CONFIRMATION =
    'MODAL_TYPE_AIRMEET_STATUS_CONFIRMATION';
export const MODAL_TYPE_EVENT_ACCESS = 'EVENT_ACCESS';
export const MODAL_TYPE_ENTRY_RESTRICTED = 'ENTRY_RESTRICTED';
export const MODAL_TYPE_ATTENDEE_INTRO = 'ATTENDEE_INTRO';
export const MODAL_TYPE_USER_EDIT_PROFILE = 'USER_EDIT_PROFILE';
export const MODAL_TYPE_METERING_POLICY = 'MODAL_TYPE_METERING_POLICY';
export const MODAL_TYPE_BILLING_PLAN_UPGRADE =
    'MODAL_TYPE_BILLING_PLAN_UPGRADE';
export const MODAL_TYPE_EVENT_ENTRY = 'MODAL_TYPE_EVENT_ENTRY';
export const MODAL_TYPE_AUTH_SSO = 'MODAL_TYPE_AUTH_SSO';
export const MODAL_TYPE_CONFERENCE_TRIAL = 'MODAL_TYPE_CONFERENCE_TRIAL';
export const MODAL_TYPE_CUSTOM_REGISTRATION = 'MODAL_TYPE_CUSTOM_REGISTRATION';
export const MODAL_TYPE_CHECKOUTFLOW = 'MODAL_TYPE_CHECKOUTFLOW';
export const MODAL_TYPE_LANGUAGE = 'MODAL_LANGUAGE';
export const MODAL_TYPE_LIGHT = 'MODAL_LIGHT';
export const MODAL_TYPE_UPGRADE_PLAN = 'MODAL_TYPE_UPGRADE_PLAN';
export const MODAL_TYPE_ADD_PAYMENT_DETAILS = 'MODAL_TYPE_ADD_PAYMENT_DETAILS';
export const MODAL_TYPE_NETWORKING_SESSION = 'MODAL_TYPE_NETWORKING_SESSION';
export const MODAL_TYPE_SESSION_PLAYBACK_TOGGLE =
    'MODAL_TYPE_SESSION_PLAYBACK_TOGGLE';
export const MODAL_TYPE_GROUP_MEETING = 'MODAL_TYPE_GROUP_MEETING';
export const MODAL_TYPE_SESSION_LEAVE_CONFIRMATION =
    'MODAL_TYPE_SESSION_LEAVE_CONFIRMATION';
export const MODAL_TYPE_ONBOARDING_QUESTION_MODAL =
    'MODAL_TYPE_ONBOARDING_QUESTION_MODAL';
export const MODAL_TYPE_INVITE_OTHERS = 'MODAL_TYPE_INVITE_OTHERS';
export const MODAL_TYPE_CREATE_ORGANISATION = 'MODAL_TYPE_CREATE_ORGANISATION';

export const MODAL_TYPE_SHOW_ORGANISATION_TYPE =
    'MODAL_TYPE_SHOW_ORGANISATION_TYPE';

export const MODAL_TYPE_VALIDATE_DUPLICATION =
    'MODAL_TYPE_VALIDATE_DUPLICATION';
export const MODAL_TYPE_ASSIGN_HOST = 'MODAL_TYPE_ASSIGN_HOST';
export const MODAL_TYPE_EDIT_LEADERBOARD_ACTIONS =
    'MODAL_TYPE_EDIT_LEADERBOARD_ACTIONS';
export const MODAL_TYPE_LEGACY_AIRMEET_SUPPORT =
    'MODAL_TYPE_LEGACY_AIRMEET_SUPPORT';
export const MODAL_TYPE_PLAN_FEATURE_LIST = 'MODAL_TYPE_PLAN_FEATURE_LIST';
export const MODAL_TYPE_MANAGE_SUBSCRIPTION = 'MODAL_TYPE_MANAGE_SUBSCRIPTION';
export const MODAL_TYPE_CUSTOMISE_ENTERPRISE_PLAN =
    'MODAL_TYPE_CUSTOMISE_ENTERPRISE_PLAN';
export const MODAL_TYPE_CREATE_MULTI_COMMUNITY =
    'MODAL_TYPE_CREATE_MULTI_COMMUNITY';
export const MODAL_TYPE_INVITE_TO_TABLE = 'MODAL_TYPE_INVITE_TO_TABLE';
export const MODAL_TYPE_ONBOARDING_WELCOME_MODAL =
    'MODAL_TYPE_ONBOARDING_WELCOME_MODAL';
export const MODAL_TYPE_MEDIA_LIBRARY = 'MODAL_TYPE_MEDIA_LIBRARY';
export const MODAL_TYPE_UPLOAD_MEDIA = 'MODAL_TYPE_UPLOAD_MEDIA';
export const MODAL_TYPE_REGISTRATION_FORM = 'MODAL_TYPE_REGISTRATION_FORM';

export const MODAL_TYPE_PREMIUM_FEATURE = 'MODAL_TYPE_PREMIUM_FEATURE';
export const MODAL_TYPE_BOOTH_SESSION = 'MODAL_TYPE_BOOTH_SESSION';
export const MODAL_TYPE_BREAKOUT_CONFIRMATION =
    'MODAL_TYPE_BREAKOUT_CONFIRMATION';
export const MODAL_TYPE_BREAKOUT_ANNOUNCEMENT =
    'MODAL_TYPE_BREAKOUT_ANNOUNCEMENT';
export const MODAL_TYPE_BREAKOUT_CLOSE_CONFIRMATION =
    'MODAL_TYPE_BREAKOUT_CLOSE_CONFIRMATION';
export const MODAL_TYPE_ALLOWED_ACCESS = 'MODAL_TYPE_ALLOWED_ACCESS';
export const MODAL_TYPE_BLACKLIST_PARTICIPANTS =
    'MODAL_TYPE_BLACKLIST_PARTICIPANTS';
export const MODAL_TYPE_EVENT_ACCESS_PANEL = 'MODAL_TYPE_EVENT_ACCESS_PANEL';
export const MODAL_TYPE_REGISTRATION_SUCCESS_MODAL =
    'MODAL_TYPE_REGISTRATION_SUCCESS_MODAL';
export const MODAL_TYPE_ADD_INTEREST = 'MODAL_TYPE_ADD_INTEREST';
export const MODAL_TYPE_COMMUNITY_SWITCHER = 'MODAL_TYPE_COMMUNITY_SWITCHER';
export const MODAL_TYPE_COMPARE_PLAN = 'MODAL_TYPE_COMPARE_PLAN';
export const MODAL_TYPE_EVENT_SHARE = 'MODAL_TYPE_EVENT_SHARE';
export const MODAL_TYPE_SEND_EMAIL_CONFIRMATION =
    'MODAL_TYPE_SEND_EMAIL_CONFIRMATION';
export const MODAL_TYPE_TEAM_MEMBER_ADDON = 'MODAL_TYPE_TEAM_MEMBER_ADDON';
export const MODAL_TYPE_REDUCE_ADDON_SEATS = 'MODAL_TYPE_REDUCE_ADDON_SEATS';
export const MODAL_TYPE_TICKET = 'MODAL_TYPE_TICKET';
export const MODAL_TYPE_COUPON_CONFIRM = 'MODAL_TYPE_COUPON_CONFIRM';
export const MODAL_TYPE_APPLY_COUPON = 'MODAL_TYPE_APPLY_COUPON';
export const MODAL_TYPE_ENTRY_FLOW = 'MODAL_TYPE_ENTRY_FLOW';
export const MODAL_TYPE_CHANGE_ROLE = 'MODAL_TYPE_CHANGE_ROLE';
export const MODAL_TYPE_INVITE_TEAM_MEMBER_V1 =
    'MODAL_TYPE_INVITE_TEAM_MEMBER_V1';
export const MODAL_TYPE_INVITE_TEAM_MEMBER_V2 =
    'MODAL_TYPE_INVITE_TEAM_MEMBER_V2';
export const MODAL_TYPE_EDIT_PERMISSION = 'MODAL_TYPE_EDIT_PERMISSION';
export const MODAL_TYPE_CONFIRM = 'MODAL_TYPE_CONFIRM';
export const MODAL_TYPE_INTRO_VIDEO = 'MODAL_TYPE_INTRO_VIDEO';
export const MODAL_TYPE_DELETE_FORM = 'MODAL_TYPE_DELETE_FORM';
export const MODAL_TYPE_EVENT_DASHBOARD_FEEDBACK =
    'MODAL_TYPE_EVENT_DASHBOARD_FEEDBACK';
export const MODAL_TYPE_PASSWORD_CHECKLIST = 'MODAL_TYPE_PASSWORD_CHECKLIST';
export const MODAL_TYPE_EDIT_IMAGE = 'MODAL_TYPE_EDIT_IMAGE';
export const MODAL_TYPE_FILE_VIEWER = 'MODAL_TYPE_FILE_VIEWER';
export const MODAL_TYPE_RESOURCES = 'MODAL_TYPE_RESOURCES';
export const MODAL_TYPE_UPDATE_PASSWORD = 'MODAL_TYPE_UPDATE_PASSWORD';
export const MODAL_TYPE_VIEW_ORGANIZER = 'MODAL_TYPE_VIEW_ORGANIZER';
export const MODAL_TYPE_RESTRICTED_EVENT_ENTRY =
    'MODAL_TYPE_RESTRICTED_EVENT_ENTRY';
export const MODAL_TYPE_PREVIEW_EVENT_TEMPLATE = 'PREVIEW_EVENT_TEMPLATE';
export const MODAL_TYPE_NOTIFICATION_POPUP = 'MODAL_TYPE_NOTIFICATION_POPUP';
export const MODAL_TYPE_CONFIRMATION = 'MODAL_TYPE_CONFIRMATION';
export const MODAL_TYPE_INSUFFICIENT_PERMISSIONS =
    'MODAL_TYPE_INSUFFICIENT_PERMISSIONS';
export const MODAL_TYPE_BLOCK_UNBLOCK_ATTENDEE =
    'MODAL_TYPE_BLOCK_UNBLOCK_ATTENDEE';
export const MODAL_TYPE_ATTENDEE_BLOCKED = 'MODAL_TYPE_ATTENDEE_BLOCKED';
export const MODAL_TYPE_GOOGLE_TAG_MANAGER = 'MODAL_TYPE_GOOGLE_TAG_MANAGER';
export const MODAL_TYPE_TEST_MAIL = 'MODAL_TYPE_TEST_MAIL';
export const MODAL_TYPE_PAUSE_EMAIL = 'MODAL_TYPE_PAUSE_EMAIL';
export const MODAL_TYPE_RESUME_EMAIL = 'MODAL_TYPE_RESUME_EMAIL';
export const MODAL_TYPE_DELETE_ORGANIZER = 'MODAL_TYPE_DELETE_ORGANIZER';
export const MODAL_TYPE_POST_EVENT_ENTRY_RESTRICTED =
    'MODAL_TYPE_POST_EVENT_ENTRY_RESTRICTED';
export const MODAL_TYPE_PREVIEW_EVENT_SERIES = 'PREVIEW_EVENT_SERIES';
export const MODAL_TYPE_EDIT_LEADERBOARD_BLOCK_DOMAIN =
    'MODAL_TYPE_EDIT_LEADERBOARD_BLOCK_DOMAIN';
export const MODAL_TYPE_REGISTRATION_SYNC = 'MODAL_TYPE_REGISTRATION_SYNC';
export const MODAL_TYPE_CREATE_FIRST_AIRMEET =
    'MODAL_TYPE_CREATE_FIRST_AIRMEET';
export const MODAL_TYPE_SPEAKER_REORDER = 'MODAL_TYPE_SPEAKER_REORDER';
export const MODAL_TYPE_REFRESH_INTEGRATION_APP =
    'MODAL_TYPE_REFRESH_INTEGRATION_APP';
export const MODAL_TYPE_AIR_GENIE = 'MODAL_TYPE_AIR_GENIE';
export const MODAL_TYPE_ADD_TO_CALENDAR = 'MODAL_TYPE_ADD_TO_CALENDAR';
export const MODAL_TYPE_USER_WAITLISTED = 'USER_WAITLISTED';
export const RESYNC_MODAL_INTEGRATION = 'RESYNC_MODAL_INTEGRATION';
export const SYNC_MODAL_INTEGRATION = 'SYNC_MODAL_INTEGRATION';
export const MODAL_TYPE_AIR_GENIE_IMAGE = 'MODAL_TYPE_AIR_GENIE_IMAGE';
export const EMAIL_TEMPLATE_CONFIRMATION = 'EMAIL_TEMPLATE_CONFIRMATION';
export const DISABLE_ENRICHMENT_MODAL = 'DISABLE_ENRICHMENT_MODAL';
export const MODAL_TYPE_BASE_MODAL_V2 = 'MODAL_TYPE_BASE_MODAL_V2';
export const MODAL_EDIT_VIDEO_HEADLINE = 'MODAL_EDIT_VIDEO_HEADLINE';
export const MODAL_TYPE_COMPARE_PLANS_V2 = 'MODAL_TYPE_COMPARE_PLANS_V2';
export const MODAL_TYPE_EVENT_NOT_ACCESSIBLE =
    'MODAL_TYPE_EVENT_NOT_ACCESSIBLE';
export const MODAL_TYPE_ADD_VIDEO = 'MODAL_TYPE_ADD_VIDEO';
export const AUDIO_RESUME_MODAL = 'AUDIO_RESUME_MODAL';
export const MODAL_TYPE_EVENT_ENTRY_OTP = 'MODAL_TYPE_EVENT_ENTRY_OTP';

export const getModalProps = ({
    isEventEntryV2,
    customStyles,
    mobileBottomSheet,
    bottomSheetBreakPoint,
    isLightTheme,
    theme,
}) => {
    const modalPropsV2 = {
        disabledScroll: true,
        bodyScroll: true,
        mobileBottomSheet: mobileBottomSheet,
        bottomSheetBreakPoint: bottomSheetBreakPoint,
        closeBtnStyle: {
            minWidth: '2.25rem',
            minHeight: '2.25rem',
            maxWidth: '2.25rem',
            maxHeight: '2.25rem',
            background: isLightTheme
                ? theme.colors.white
                : theme.colors.ambience[21],
            padding: 0,
        },
        closeIconStyle: {
            fontFamily: 'NotoSans-SemiBold,sans-serif',
            fontSize: 26,
            lineHeight: '30px',
            fontWeight: '100',
        },
        modalStyle: {
            minWidth: 'inherit !important',
            maxWidth: ['100%', '475px'],
            background: theme.colors.ambience[22],
            borderRadius: ['0', '24px'],
            padding: '0 !important',
        },
        modalHeaderStyle: {
            marginBottom: '0 !important',
            padding: '16px 16px 0 16px',
        },
        modalBodyStyle: {
            padding: customStyles
                ? '0 24px 32px'
                : mobileBottomSheet
                ? ['0 16px 85px', '0 16px 85px', '0 52px 52px']
                : '0 52px 52px',
        },
        bsModalContentStyle: { padding: '0 !important' },
    };

    const modalPropsV3 = {
        disabledScroll: true,
        bodyScroll: true,
        closeBtnStyle: {
            minWidth: '24px',
            minHeight: '24px',
            maxWidth: '24px',
            maxHeight: '24px',
            background: 'transparent',
            padding: 0,
        },
        closeIconStyle: {
            fontFamily: 'NotoSans-SemiBold,sans-serif',
            fontSize: 24,
            lineHeight: '24px',
            fontWeight: '100',
        },
        modalStyle: {
            minWidth: 'inherit !important',
            maxWidth: ['100%', '590px'],
            background: theme.colors.ambience[22],
            borderRadius: '0px',
            padding: '0 !important',
            maxHeight: '100% !important',
            height: '100vh',
            marginRight: '0px !important',
        },
        modalHeaderStyle: {
            marginBottom: '0 !important',
            padding: '0px',
            position: 'absolute',
            right: '32px',
            top: '24px',
            className: 'auth-modal-header',
        },
        modalBodyStyle: {
            padding: '40px 53px 40px 32px',
            maxHeight: '100% !important',
            justifyContent: 'flex-start !important',
            className: 'auth-modal-body',
        },
        modalWrapperStyles: {
            height: '100vh',
            maxHeight: '100% !important',
        },
        modalContainerStyle: {
            style: { animation: 'slideInRight 0.4s ease' },
        },
        mobileBottomSheet: mobileBottomSheet,
        bottomSheetBreakPoint: '767px',
        enableSheetClose: true,
    };
    return isEventEntryV2 ? modalPropsV3 : modalPropsV2;
};
