import * as TG from '@radix-ui/react-toggle-group';
import Icon from 'foundations/icon';
import { Text } from 'foundations/themeV2/text';
import React from 'react';
import styled from 'styled-components';

enum TYPES {
    single = 'single',
    multiple = 'multiple',
}

enum SIZES {
    large = 'large',
    small = 'small',
}

interface ItemsObj {
    value: any;
    component?: JSX.Element;
    label?: string;
    icon?: string;
    iconSize?: string;
    className?: string;
}

interface ToggleGroupProps {
    value: any;
    items: ItemsObj[];
    size: SIZES;
    type: TYPES;
    fillSelected: boolean;
}

const ToggleGroup: React.FC<ToggleGroupProps> = ({
    value,
    items = [],
    size = 'large',
    type = 'single',
    fillSelected = false,
    ...rest
}) => {
    if (!Array.isArray(items) || items.length === 0) return null;

    return (
        <Root
            className='ToggleGroup'
            size={size}
            value={value}
            type={type}
            {...rest}
        >
            {items.map((item) => {
                const {
                    value: val,
                    component,
                    label,
                    className = '',
                    icon,
                    iconSize = '16px',
                    ...rest
                } = item || {};
                const isSelected = val === value;

                return (
                    <Item
                        key={val}
                        className={`ToggleGroupItem ${className}`}
                        value={val}
                        {...rest}
                        fillSelected={fillSelected && isSelected}
                    >
                        {component || (
                            <>
                                {icon ? (
                                    <Icon
                                        mr={1}
                                        icon={icon}
                                        width={iconSize}
                                        height={iconSize}
                                        fill={
                                            isSelected
                                                ? 'text.default.primary'
                                                : 'text.default.secondary'
                                        }
                                    />
                                ) : null}
                                {label ? (
                                    <Text
                                        variant={
                                            size === 'small'
                                                ? isSelected
                                                    ? 'caption-bold'
                                                    : 'caption'
                                                : isSelected
                                                ? 'h5'
                                                : 'body2'
                                        }
                                        color={
                                            isSelected
                                                ? 'text.default.primary'
                                                : 'text.default.secondary'
                                        }
                                    >
                                        {label}
                                    </Text>
                                ) : null}
                            </>
                        )}
                    </Item>
                );
            })}
        </Root>
    );
};

export default ToggleGroup;

const Root = styled(TG.Root)`
    display: inline-flex;
    border-radius: ${({ theme }) => theme.radii.default};
    border: 1px solid ${({ theme }) => theme.colors.border.default1};
    ${({ size, theme }) =>
        size === 'small'
            ? `
            height: 1.75rem;
    `
            : `
            height: ${theme.space.x8};
    `}
`;

const Item = styled(TG.Item)`
    all: unset;
    background-color: ${({ theme }) => theme.colors.fill.default3};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0rem ${({ theme }) => theme.space.x4};
    border-right: 1px solid ${({ theme }) => theme.colors.border.default1};
    &:first-child {
        border-top-left-radius: ${({ theme }) => theme.radii.default};
        border-bottom-left-radius: ${({ theme }) => theme.radii.default};
    }
    &:last-child {
        border-top-right-radius: ${({ theme }) => theme.radii.default};
        border-bottom-right-radius: ${({ theme }) => theme.radii.default};
        border-right: 0;
    }
    &:hover {
        background-color: ${({ theme }) => theme.colors.fill.other3};
    }
    &[data-state='on'] {
        background-color: ${({ theme, fillSelected }) =>
            fillSelected
                ? theme.isLightTheme
                    ? theme.colors.fill.default1
                    : theme.colors.fill.other3
                : theme.colors.fill.default2};
        outline: 0;
    }
`;
