import React from 'react';
import { getAirmeetUtilInstance } from 'utils/airmeetUtilInstance';
import { isSessionHostOrCohost } from 'utils/userAccessControl';
const ProfileButton = React.lazy(() => import('../ProfileButton'));

export default function LiveHeader({ isDesktopMode }) {
    const HEADER_LEFT = [];
    const HEADER_CONTENT = [];

    const HEADER_RIGHT = [
        <div className='d-flex session-action-outer'>
            {isSessionHostOrCohost() && (
                <div id='session-action-buttons' className='d-flex' />
            )}
        </div>,
        getAirmeetUtilInstance() ? (
            !isDesktopMode ? (
                <ProfileButton iconOnly />
            ) : null
        ) : null,
    ];

    const HEADER_TOP_BAR = [];

    return [HEADER_LEFT, HEADER_CONTENT, HEADER_RIGHT, HEADER_TOP_BAR];
}
