import { Input } from '@rocketseat/unform';
import InputField from 'atoms/Input/input';
import TextArea from 'atoms/Input/textarea';
import classnames from 'classnames';
import { StyledSvgSprite } from 'components/general/SVGSprite';
import WithTooltip from 'components/general/WithTooltip';
import {
    Box,
    FlexDiv,
    ScreenReaderText,
} from 'components/molecules/utils/basicComponents';
import Icon from 'foundations/icon';
import { alpha } from 'foundations/theme';
import { Typography } from 'foundations/typography';
import get from 'lodash/get';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

/**
 * A custom field component for the form
 * Note - This is an uncontrolled component, So it needs to be wrapped
 * in a parent Form componet from @unform package which controls the state
 * of the Input Component
 * TODO: Add params description
 */
export default function FormField({
    id,
    label,
    type = 'text',
    name,
    placeholder = '',
    filledValue,
    className = '',
    onChange = () => {},
    autoFocus = false,
    maxLength,
    isError = false,
    errorMsg,
    errorTextVarient = 'caption',
    hideLimit = false,
    multiline,
    min,
    max,
    custom,
    children,
    elementType,
    inputValue = null,
    renderBottomText,
    onFocus = () => {},
    onBlur = () => {},
    onClick = () => {},
    variant = 'default',
    subLabel = '',
    isLightBg = false,
    hasButtons,
    onDecrement = () => {},
    onIncrement = () => {},
    newOnBoardingFlow = false,
    isForcedLight = false,
    labelColor = null,
    subLabelColor = null,
    labelVariant: labelVariantProp,
    subLabelVariant = 'caption',
    charLimitColor = null,
    charLimitVariant = 'caption',
    onKeyUp = () => {},
    onKeyPress = () => {},
    hasIcon = false,
    icon = '',
    iconFill: iconFillProp,
    iconSize = 20,
    labelStyle = {},
    labelTextStyle = {},
    isCustomField = false,
    isBorder = true,
    inputClassName = '',
    tooltip = null,
    toolTipPlace = null,
    minHeight = null,
    required = false,
    inputBg,
    inputV2 = false,
    isThemeV2 = false,
    inputFontFamily,
    ...props
}) {
    const labelVariant = labelVariantProp || (isThemeV2 ? 'body2' : 'subtext1');
    const iconFill =
        iconFillProp || (isThemeV2 ? 'text.default.secondary' : 'ambience.3');

    const [val, setVal] = useState(filledValue);
    const [usedCount, setUsedCount] = useState(0);

    const inputRef = useRef(null);

    const renderCharLimit = (inputVal) => {
        const inputLength =
            inputVal?.length || props?.value?.length || val?.length || 0;
        return (
            <>
                <Typography
                    variant={charLimitVariant}
                    className='count'
                    color={
                        charLimitColor ||
                        (isThemeV2
                            ? 'text.default.secondary'
                            : variant
                            ? isLightBg
                                ? 'ambience.14'
                                : 'ambience.3'
                            : '')
                    }
                    isThemeV2={isThemeV2}
                >
                    {inputLength}/{maxLength}
                </Typography>
                <ScreenReaderText role='status'>
                    {usedCount} of {maxLength}characters entered
                </ScreenReaderText>
            </>
        );
    };
    const renderSizeLimit = () => {
        return (
            <Typography
                variant={'caption'}
                className='count'
                color={
                    isThemeV2
                        ? 'text.default.secondary'
                        : variant
                        ? isLightBg
                            ? 'ambience.14'
                            : 'ambience.3'
                        : ''
                }
                isThemeV2={isThemeV2}
            >
                {props.value || 0}/{max}
            </Typography>
        );
    };

    useEffect(() => {
        const valueLength = val?.length || 0;
        const timer = setTimeout(() => {
            setUsedCount(valueLength);
        }, 1500);

        return () => clearTimeout(timer);
    }, [val]);

    const onValueChange = (e) => {
        setVal(e.target.value);
        onChange(e);
    };
    const isFilled = Array.isArray(filledValue)
        ? filledValue.length > 0
        : filledValue;

    return (
        <>
            {!isThemeV2 && variant === 'default' && (
                <>
                    <Box
                        className={classnames(
                            'form-field',
                            {
                                error: isError,
                                filled:
                                    !props.disabled &&
                                    (isFilled || typeof isFilled === 'number'),
                            },
                            className
                        )}
                    >
                        <Label htmlFor={id}>{label}</Label>
                        {!custom &&
                            (tooltip ? (
                                <WithTooltip
                                    tooltip={tooltip}
                                    place={toolTipPlace || 'left'}
                                >
                                    <Input
                                        type={type}
                                        name={name}
                                        id={id}
                                        placeholder={placeholder}
                                        maxLength={maxLength}
                                        onChange={onValueChange}
                                        multiline={multiline}
                                        min={min}
                                        max={max}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        aria-label={label}
                                        ref={inputRef}
                                        {...props}
                                    />
                                </WithTooltip>
                            ) : (
                                <Input
                                    type={type}
                                    name={name}
                                    id={id}
                                    placeholder={placeholder}
                                    maxLength={maxLength}
                                    onChange={onValueChange}
                                    multiline={multiline}
                                    min={min}
                                    max={max}
                                    onFocus={onFocus}
                                    onBlur={onBlur}
                                    aria-label={label}
                                    ref={inputRef}
                                    {...props}
                                />
                            ))}
                        {custom && <>{children}</>}
                    </Box>
                    <FlexDiv
                        className={classnames({
                            'justify-content-end': !renderBottomText,
                            'justify-content-between': renderBottomText,
                        })}
                    >
                        {errorMsg && (
                            <Paragraph
                                className={classnames('error-msg', {
                                    invisible: !isError,
                                })}
                            >
                                {errorMsg}
                            </Paragraph>
                        )}
                        {renderBottomText && renderBottomText()}

                        {maxLength && !hideLimit && renderCharLimit(inputValue)}
                    </FlexDiv>
                </>
            )}
            {(isThemeV2 || variant === 'v2') && (
                <>
                    <Label htmlFor={id} style={labelStyle || {}} pb='x1'>
                        <Typography
                            mb={isThemeV2 ? '' : '4px'}
                            variant={labelVariant}
                            color={
                                labelColor ||
                                (isThemeV2
                                    ? 'text.default.primary'
                                    : isLightBg
                                    ? 'ambience.23'
                                    : 'ambience.1')
                            }
                            style={labelTextStyle || {}}
                            isThemeV2={isThemeV2}
                        >
                            {label}
                            {required ? '*' : ''}
                        </Typography>
                        {subLabel && (
                            <SubLabel
                                mb={'0px'}
                                variant={subLabelVariant}
                                color={
                                    subLabelColor ||
                                    (isThemeV2
                                        ? 'text.default.secondary'
                                        : 'ambience.6')
                                }
                                lightThemeColor={
                                    subLabelColor || 'accentContentTertiary'
                                }
                                isThemeV2={isThemeV2}
                            >
                                {subLabel}
                            </SubLabel>
                        )}
                    </Label>
                    {isCustomField ? (
                        children
                    ) : (
                        <FormFieldBox
                            className={classnames(
                                'form-field',
                                {
                                    error: isError,
                                    filled:
                                        !props.disabled &&
                                        (isFilled ||
                                            typeof isFilled === 'number'),
                                    disabled: props.disabled,
                                },
                                className
                            )}
                            position={custom || hasButtons ? 'relative' : ''}
                            isLightBg={isLightBg}
                            hasError={isError}
                            newOnBoardingFlow={newOnBoardingFlow || false}
                            isForcedLight={isForcedLight}
                            isBorder={isBorder}
                            hasIcon={hasIcon}
                            isThemeV2={isThemeV2}
                            minHeight={minHeight}
                            inputBg={inputBg}
                            inputFontFamily={inputFontFamily}
                        >
                            {hasIcon && (
                                <SVGIcon
                                    fill={iconFill}
                                    width={iconSize}
                                    height={iconSize}
                                    icon={icon}
                                />
                            )}
                            {!custom &&
                                (tooltip ? (
                                    <WithTooltip
                                        tooltip={tooltip}
                                        place={toolTipPlace || 'left'}
                                    >
                                        <StyledInput
                                            type={type}
                                            name={name}
                                            id={id}
                                            placeholder={placeholder}
                                            maxLength={maxLength}
                                            onChange={onValueChange}
                                            autoFocus={autoFocus}
                                            multiline={multiline}
                                            onKeyUp={onKeyUp}
                                            onKeyPress={onKeyPress}
                                            min={min}
                                            max={max}
                                            onFocus={onFocus}
                                            onBlur={onBlur}
                                            className={inputClassName}
                                            aria-label={label}
                                            required={required}
                                            aria-required={required}
                                            ref={inputRef}
                                            {...props}
                                        />
                                    </WithTooltip>
                                ) : (
                                    <StyledInput
                                        type={type}
                                        name={name}
                                        id={id}
                                        placeholder={placeholder}
                                        maxLength={maxLength}
                                        onChange={onValueChange}
                                        autoFocus={autoFocus}
                                        multiline={multiline}
                                        onKeyUp={onKeyUp}
                                        onKeyPress={onKeyPress}
                                        min={min}
                                        max={max}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        className={inputClassName}
                                        wrapperMargin={0}
                                        errorMsg={errorMsg}
                                        aria-label={label + subLabel}
                                        as={
                                            inputV2 &&
                                            (multiline ? TextArea : InputField)
                                        }
                                        required={required}
                                        aria-required={required}
                                        ref={inputRef}
                                        {...props}
                                    />
                                ))}
                            {hasButtons && (
                                <ButtonFlexDiv>
                                    <StyledButton
                                        type={'button'}
                                        key={'buttonMinus'}
                                        onClick={onDecrement}
                                        disabled={
                                            props.value <= min ? true : false
                                        }
                                    >
                                        <StyledSvgSprite
                                            icon={'minus-icon'}
                                            fill={'ambience.14'}
                                            height={'16px'}
                                            width={'16px'}
                                        />
                                    </StyledButton>
                                    <StyledButton
                                        type={'button'}
                                        key={'buttonPlus'}
                                        onClick={onIncrement}
                                        disabled={
                                            props.value >= max ? true : false
                                        }
                                    >
                                        <StyledSvgSprite
                                            icon={'plus-icon'}
                                            fill={'ambience.14'}
                                            height={'16px'}
                                            width={'16px'}
                                        />
                                    </StyledButton>
                                </ButtonFlexDiv>
                            )}
                            {custom && <>{children}</>}
                        </FormFieldBox>
                    )}
                    <BottomText
                        className={classnames({
                            'justify-content-end': !renderBottomText,
                            'justify-content-between': renderBottomText,
                        })}
                        mt={isThemeV2 ? 'x1' : ''}
                        isThemeV2={isThemeV2}
                    >
                        {errorMsg && (
                            <ParagraphV2
                                className={classnames('error-msg', {
                                    invisible: !isError,
                                })}
                                variant={errorTextVarient}
                                color={isThemeV2 ? 'sem.error' : 'semantic.1'}
                                isThemeV2={isThemeV2}
                                pt={isThemeV2 ? '0px' : ''}
                                pb={isThemeV2 ? '0px' : ''}
                                id={`${id}-error`}
                                role='alert'
                            >
                                {errorMsg}
                            </ParagraphV2>
                        )}
                        {renderBottomText && renderBottomText()}

                        {maxLength &&
                            !hideLimit &&
                            name !== 'tableCount' &&
                            renderCharLimit(inputValue)}
                        {!hideLimit &&
                            name === 'tableCount' &&
                            renderSizeLimit()}
                    </BottomText>
                </>
            )}
        </>
    );
}
const Label = styled.label``;
const Paragraph = styled.p``;
const ParagraphV2 = styled(Typography)`
    &.error-msg {
        padding-left: 0;
    }
`;
const StyledInput = styled(Input)`
    ${({ multiline }) =>
        multiline &&
        `
        min-height: 37px;
    `};
`;
const FormFieldBox = styled(Box)`
    &.form-field {
        padding: 0;
        border: none;
        input,
        textarea {
            font-family: ${({ isThemeV2, theme }) =>
                isThemeV2 ? theme.fonts.regular : ''};
            min-height: ${({ isThemeV2, theme }) =>
                isThemeV2 ? theme.space.x8 : ''};
            padding: ${({ theme, isThemeV2 }) =>
                isThemeV2 ? `${theme.space.x2} ${theme.space.x4}` : '16px'};
            border-radius: ${({ theme, isThemeV2 }) =>
                isThemeV2 ? theme.radii.default : '8px'};
            color: ${({ theme, isForcedLight, isThemeV2 }) =>
                isThemeV2
                    ? theme.colors.text.default.primary
                    : (isForcedLight ||
                          (theme.isLightTheme &&
                              theme.isDashboardBrandingPage)) &&
                      theme.colors.ambience[2]};
            transition: all 300ms;
            border: ${({ theme, isBorder, isThemeV2 }) =>
                isBorder
                    ? isThemeV2
                        ? `1px solid ${theme.colors.border.default1}`
                        : `1px solid ${theme.colors.ambience[14]}`
                    : 'none'};
            background-color: ${({ theme, isThemeV2, inputBg }) =>
                isThemeV2
                    ? theme.colors.fill.default2
                    : get(theme.colors, inputBg, inputBg) ||
                      (theme.isDashboardBrandingPage && theme.isLightTheme
                          ? theme.colors.ambience[24]
                          : 'transparent')};
            ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}
            ${({ inputFontFamily }) =>
                inputFontFamily
                    ? `font-family: ${inputFontFamily};`
                    : ''}

            &:focus,
            &:active {
                border: ${({ theme, isBorder, isThemeV2 }) =>
                    isBorder
                        ? isThemeV2
                            ? `1px solid ${theme.colors.border.other1}`
                            : theme.isLightTheme
                            ? `1px solid ${theme.colors.accentPrimary[1]}`
                            : `1px solid ${theme.colors.accentPrimary[0]}`
                        : 'none'};
                box-shadow: ${({ theme, isBorder, isThemeV2 }) =>
                    isBorder && !isThemeV2
                        ? theme.isLightTheme
                            ? `0px 0px 2px 2px ${alpha(
                                  theme.colors.accentPrimary[1],
                                  0.4
                              )}`
                            : `0px 0px 2px 2px ${alpha(
                                  theme.colors.accentPrimary[0],
                                  0.4
                              )}`
                        : 'none'};

                ${({ isThemeV2, theme }) =>
                    isThemeV2
                        ? `
                    background-color: ${theme.colors.fill.default1};
                    outline: 4px solid ${theme.colors.border.default1};         
                `
                        : ''}
            }

            &:hover {
                border: ${({ theme, isBorder, isThemeV2 }) =>
                    isBorder
                        ? isThemeV2
                            ? `1px solid ${theme.colors.border.other1}`
                            : theme.isLightTheme
                            ? `1px solid ${theme.colors.accentPrimary[1]}`
                            : `1px solid ${theme.colors.accentPrimary[0]}`
                        : 'none'};
                ${({ isThemeV2, theme }) =>
                    isThemeV2
                        ? `background-color: ${theme.colors.fill.default1};`
                        : ''}
            }
        }

        textarea {
            min-height: ${({ isThemeV2, theme }) =>
                isThemeV2 ? `calc(${theme.space.x8} * 2)` : ''};
        }

        ${({ isThemeV2, hasError, theme }) =>
            isThemeV2 && hasError
                ? `
                    &.error {
                        input,
                        textarea {
                            border: ${`1px solid ${theme.colors.sem.error}`} !important;
                            box-shadow: 0 0 0 4px ${theme.colors.fill.default2};
                            &:hover {
                                border-color: ${theme.colors.sem.error};
                            }
                        }
                    }
                `
                : ''}

        &.filled {
            color: ${({ theme, isLightBg, isThemeV2 }) =>
                isThemeV2
                    ? theme.colors.text.default.primary
                    : isLightBg
                    ? theme.colors.ambience[23]
                    : theme.colors.ambience[2]};
            &.error {
                input,
                textarea {
                    border: ${({ theme, hasError, isThemeV2 }) =>
                        hasError
                            ? isThemeV2
                                ? `1px solid ${theme.colors.sem.error}`
                                : `1px solid ${theme.colors.semantic[3]}`
                            : 'none'} !important;
                    ${({ isThemeV2, theme, hasError }) =>
                        isThemeV2 && hasError
                            ? `
                        box-shadow: 0 0 0 4px ${theme.colors.fill.default2};
                        &:hover {
                            border-color: ${theme.colors.sem.error};
                        }
                    `
                            : ''}
                }
            }
            input,
            textarea {
                color: ${({ theme, isForcedLight, isThemeV2 }) =>
                    isThemeV2
                        ? theme.colors.text.default.primary
                        : isForcedLight ||
                          (theme.isLightTheme && theme.isDashboardBrandingPage)
                        ? theme.colors.ambience[2]
                        : ''};
            }
        }
        ${StyledInput} {
            ${({ theme, isLightBg }) =>
                isLightBg &&
                `
                caret-color : ${
                    theme.colors.ambience[theme.isLightTheme ? 1 : 23]
                };
                color: ${theme.colors.ambience[23]};
            `};
            ${({ theme, newOnBoardingFlow }) =>
                newOnBoardingFlow &&
                `
            color: ${theme.colors.ambience[2]};
        `};
            &:-webkit-autofill {
                -webkit-box-shadow: 0 0 0px 1000px
                    ${({ theme, isLightBg }) =>
                        theme.isLightTheme
                            ? theme.colors.white
                            : isLightBg
                            ? theme.colors.ambience[3]
                            : theme.colors.ambience[21]}
                    inset !important;
                -webkit-text-fill-color: ${({ theme, isLightBg }) =>
                    theme?.isLightTheme
                        ? theme.colors.ambience[2]
                        : isLightBg
                        ? theme.colors.ambience[23]
                        : theme.colors.ambience[2]} !important;
                caret-color: ${({ theme, isLightBg }) =>
                    theme.isLightTheme
                        ? theme.colors.black
                        : isLightBg
                        ? theme.colors.ambience[23]
                        : theme.colors.white};
            }
            &:-internal-autofill-selected {
                background-color: transparent !important;
            }
        }
        ${({ hasIcon }) =>
            hasIcon &&
            `
            position: relative;
            input {
                padding-left: 45px;
            }
        `}
        &.form_disabled,
        &.disabled {
            cursor: not-allowed;

            input,
            textarea {
                cursor: not-allowed;
                ${({ theme, isThemeV2 }) =>
                    isThemeV2
                        ? `
                        background-color: ${theme.colors.fill.default2};
                        border-color: ${theme.colors.border.default2};
                        color: ${theme.colors.text.default.disabled};
                        cursor: default;
                        pointer-events: none;
                    `
                        : theme.isLightTheme
                        ? `color: ${theme.colors.ambience[18]};`
                        : ''}

                &:focus,
                &:active {
                    border: ${({ theme, isBorder, isThemeV2 }) =>
                        isBorder
                            ? `1px solid ${
                                  isThemeV2
                                      ? theme.colors.border.default2
                                      : theme.colors.ambience[14]
                              }`
                            : 'none'};
                    background-color: ${({ theme, isThemeV2 }) =>
                        isThemeV2
                            ? theme.colors.fill.default2
                            : theme.isDashboardBrandingPage &&
                              theme.isLightTheme
                            ? theme.colors.ambience[24]
                            : 'transparent'};
                }

                &:hover {
                    border: ${({ theme, isBorder, isThemeV2 }) =>
                        isBorder
                            ? `1px solid ${
                                  isThemeV2
                                      ? theme.colors.border.default2
                                      : theme.colors.ambience[14]
                              }`
                            : 'none'};
                }
            }

            svg {
                fill: ${({ theme, isThemeV2 }) =>
                    isThemeV2 ? theme.colors.text.default.disabled : ''};
            }
        }
    }

    ${({ newOnBoardingFlow }) =>
        newOnBoardingFlow &&
        css`
            background-color: ${({ theme }) =>
                theme.colors.ambience[24]} !important;
        `}
`;
const SubLabel = styled(Typography)`
    font-family: ${({ theme, isThemeV2 }) =>
        isThemeV2 ? '' : theme.fonts.book};
`;
const StyledButton = styled.button`
    transition: all 0.3s ease-in-out;
    &:disabled {
        opacity: 0.5;
    }
`;
const ButtonFlexDiv = styled(FlexDiv)`
    height: 100%;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    button {
        margin-right: 8px;
        border: none;
        outline: 0;
        background-color: transparent;
        padding: 0;
        height: 24px;
        width: 24px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child {
            margin-right: 0;
        }
        &:hover,
        &:focus {
            box-shadow: none !important;
            outline: 0;
        }
    }
`;

const SVGIcon = styled(Icon)`
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
`;

const BottomText = styled(FlexDiv)`
    ${({ theme, isThemeV2 }) =>
        isThemeV2
            ? `
        .count {
            color: ${theme.colors.text.default.secondary};
        }
    `
            : ''}
`;
