import { useMemo } from 'react';
import {
    defaultSelectedCCLanguage,
    default_CLOSED_CAPTIONS_SERVICE_PROVIDERS,
} from 'utils/closed-captions';
import { getCCLanguageOptions } from '../../components/community/editPublicAirmeet/ClosedCaptions/constants';
import { useSelector } from 'react-redux';
import { getClosedCaptionState } from '../../store/selectors';
import { ccModules } from 'utils/constants/common';
import { getClosedCaptionsConfig } from '../../store/selectors';

function useClosedCaptionsSettings() {
    const currentState = useSelector(getClosedCaptionState);
    let ccConfig = useSelector(getClosedCaptionsConfig);

    const serviceProvider =
        ccConfig['ccServiceProviders']?.[ccModules.DEFAULT] ??
        default_CLOSED_CAPTIONS_SERVICE_PROVIDERS.default;

    const {
        ccPrimaryLang,
        ccLangs,
        showCloseCaption,
        allowTranslatedCaption,
        isCCTranslationEnabled,
    } = ccConfig ?? {}; //liveConfig;
    const supportedLanguages = useMemo(() => {
        const defaultLanguage = ccPrimaryLang;
        if (!Object.keys(defaultLanguage ?? {}).length) {
            return [];
        }
        const sortedArray = allowTranslatedCaption ? ccLangs : [];
        if (sortedArray?.length) {
            sortedArray.sort((a, b) => {
                return a.label.localeCompare(b.label);
            });
        }

        const filterSimilarLangs = sortedArray?.filter(({ value }) => {
            return !ccPrimaryLang?.value?.split?.('-')?.[0].includes?.(value);
        });
        return [
            defaultSelectedCCLanguage,
            defaultLanguage,
            ...(filterSimilarLangs ?? []),
        ];
    }, [
        ccPrimaryLang,
        ccLangs,
        defaultSelectedCCLanguage,
        allowTranslatedCaption,
    ]);

    // if showCloseCaption is not defined, closed_captions value is used from backend flag
    const canShowCCOption =
        showCloseCaption !== undefined
            ? showCloseCaption
            : ccConfig?.closed_captions === 'SHOW';

    const { primaryLangs: primaryLanguages } = useMemo(() => {
        return getCCLanguageOptions(serviceProvider);
    }, [serviceProvider]);

    // if ccPrimaryLang is not defined, first value from default languages array is used (en-US)
    const defaultPrimaryLanguage = ccPrimaryLang || primaryLanguages[0];

    return {
        showCloseCaptionOption: canShowCCOption,
        allowTranslatedCaptionOption: allowTranslatedCaption,
        defaultPrimaryLanguage: defaultPrimaryLanguage,
        supportedLanguages: supportedLanguages,
        serviceProvider,
        isCCUsingSDKSelected: ccConfig?.isCCUsingRTCSDK,
        currentCCState: currentState,
        isCCTranslationEnabled,
    };
}

export default useClosedCaptionsSettings;
