import React from 'react';
import styled from 'styled-components';

export default function ProgressBar(props) {
    const { progress = 0, color, width = '580px' } = props;
    return (
        <ProgressBarComponent width={width}>
            <Progress color={color} progress={progress}></Progress>
        </ProgressBarComponent>
    );
}

const ProgressBarComponent = styled.div`
    background-color: ${({ theme }) => theme.colors.fill.other3};
    border: 1px solid ${({ theme }) => theme.colors.border.other1};
    border-radius: 8px;
    height: 8px;
    width: ${(props) => `${props.width}`};

    @media (max-width: 640px) {
        width: 280px;
    }
`;

const Progress = styled.div`
    height: 100%;
    width: ${(props) => `${props.progress}%`};
    border-radius: 8px;
    background-color: ${({ theme, color }) =>
        color || theme.colors.brandDefault};
    transition: width 0.1s ease-in;
`;
