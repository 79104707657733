import { SESSION_STATUS, SESSION_TYPES } from 'components/Schedule/types';
import keys from 'locale/keys';
import moment from 'moment';
import { LITE_MODE_DELAY } from 'utils/constants/live-airmeet';
import { TABLE_ROLE, USER_ROLE } from 'utils/constants/users';
import { getFormattedTime } from 'utils/core';
import { getCurrentTenantData } from 'utils/tenant';
import UserRoles from 'utils/userRoles';
export { SESSION_STATUS, SESSION_TYPES };
const { HOST, ORGANIZER, SPEAKER, ATTENDEE, EXHIBITOR, COHOST, MODERATOR } = {
    ...USER_ROLE,
    ...TABLE_ROLE,
};

export const SESSION_TYPES_WITH_BACKSTAGE = [
    SESSION_TYPES.HOSTING,
    SESSION_TYPES.STREAMING,
    SESSION_TYPES.GROUP_MEETING,
    SESSION_TYPES.PRE_RECORDED,
];

export const SESSION_TYPES_WITH_RECORDING = [
    SESSION_TYPES.HOSTING,
    SESSION_TYPES.STREAMING,
    SESSION_TYPES.GROUP_MEETING,
    SESSION_TYPES.PRE_RECORDED,
];

export const SESSION_TYPES_WITH_MWEB_STICKY_STAGE = [
    SESSION_TYPES.HOSTING,
    SESSION_TYPES.STREAMING,
    SESSION_TYPES.PRE_RECORDED,
];

export const SESSION_TYPES_WITHOUT_SPEAKER_INFO = [
    SESSION_TYPES.BREAK,
    SESSION_TYPES.FLUID_LOUNGE,
    SESSION_TYPES.BOOTH,
    SESSION_TYPES.SPEED_NETWORKING,
];

export const SESSION_TYPES_TO_SHOW_IN_PECA = SESSION_TYPES_WITH_RECORDING;

export const SESSION_TYPE_ICONS = {
    [SESSION_TYPES.BOOTH]: 'icon-subtract',
    [SESSION_TYPES.BREAK]: 'icon-break',
    [SESSION_TYPES.FLUID_LOUNGE]: 'fluid-space-icon',
    [SESSION_TYPES.GROUP_MEETING]: 'icon-group-meeting',
    [SESSION_TYPES.HOSTING]: 'presenter',
    [SESSION_TYPES.PRE_RECORDED]: 'ic-pre-recorded-session-filled',
    [SESSION_TYPES.SPEED_NETWORKING]: 'people',
    [SESSION_TYPES.STREAMING]: 'stream',
    [SESSION_TYPES.SCHEDULE_MEETING]: 'calendar-person',
};

export const SESSION_TYPE_OUTLINE_ICONS = {
    [SESSION_TYPES.BOOTH]: 'ic-booths-o',
    [SESSION_TYPES.BREAK]: 'ic-cup-o',
    [SESSION_TYPES.FLUID_LOUNGE]: 'ic-fluid-o',
    [SESSION_TYPES.GROUP_MEETING]: 'ic-people-audience',
    [SESSION_TYPES.HOSTING]: 'ic-presenter-o',
    [SESSION_TYPES.PRE_RECORDED]: 'ic-pre-recorded-session',
    [SESSION_TYPES.SPEED_NETWORKING]: 'ic-people',
    [SESSION_TYPES.STREAMING]: 'ic-stream-o',
    [SESSION_TYPES.LOUNGE]: 'ic-lounge',
    [SESSION_TYPES.SCHEDULE_MEETING]: 'calendar-person',
};

export const LIVE_SIDE_SESSION_TYPE_ICONS = {
    ...SESSION_TYPE_OUTLINE_ICONS,
    [SESSION_TYPES.GROUP_MEETING]: 'group-meeting-o',
    [SESSION_TYPES.SPEED_NETWORKING]: 'people-o',
    // As we display hosting, pre recorded and streaming in same session category
    [SESSION_TYPES.PRE_RECORDED]:
        SESSION_TYPE_OUTLINE_ICONS[SESSION_TYPES.HOSTING],
    [SESSION_TYPES.STREAMING]:
        SESSION_TYPE_OUTLINE_ICONS[SESSION_TYPES.HOSTING],
};

export const SESSION_TYPE_NAMES = {
    [SESSION_TYPES.BOOTH]: 'Booth',
    [SESSION_TYPES.BREAK]: 'Break',
    [SESSION_TYPES.FLUID_LOUNGE]: 'Fluid Space',
    [SESSION_TYPES.GROUP_MEETING]: 'Session as a Video Call',
    [SESSION_TYPES.HOSTING]: 'Session',
    [SESSION_TYPES.PRE_RECORDED]: 'Pre Recorded Video Session',
    [SESSION_TYPES.SPEED_NETWORKING]: '1-1 Speed networking',
    [SESSION_TYPES.STREAMING]: `Stream into ${getCurrentTenantData('name')}`,
    [SESSION_TYPES.LOUNGE]: 'Lounge',
};

export const SESSION_STATUS_LABEL = {
    CREATED: 'Not started',
    ONGOING: 'Live',
    PAUSED: 'Paused',
    FINISHED: 'Completed',
};
export const SESSION_CARD_TYPE = {
    CARD: 'CARD',
    CALENDER: 'CALENDER',
    LIST: 'LIST',
    GRID: 'GRID',
};

export const SESSION_STATUS_TEXT_KEY = {
    CREATED: 'SESSION_SESSION_CREATED',
    ONGOING: 'GENERAL_SESSION_STATUS_LIVE',
    PAUSED: 'GENERAL_SESSION_STATUS_PAUSED',
    FINISHED: 'SESSION_SESSION_ENDED',
};

export const SESSION_STATUS_LABEL_BG_COLOR = {
    CREATED: 'accentContentTertiary',
    ONGOING: 'accentBrick.1',
    PAUSED: 'accentCarrot.3',
    FINISHED: 'accentSpring.1',
};

export const SESSION_STATUS_LABEL_COLOR = {
    CREATED: 'white',
    ONGOING: 'white',
    PAUSED: 'accentContentPrimary',
    FINISHED: 'accentContentPrimary',
};

export const FILTERS_BY_STATUS = {
    ALL_SESSIONS: 'ALL_SESSIONS',
    LIVE: 'ONGOING',
    UPCOMING: 'CREATED',
    COMPLETED: 'FINISHED',
    MEETINGS: 'MEETINGS',
};

export const StatusOptions = (t, showMeetingOption) => {
    const options = [
        {
            value: FILTERS_BY_STATUS.ALL_SESSIONS,
            label: showMeetingOption
                ? t(keys.GENERAL_ALL)
                : t(keys.ALL_SESSIONS),
        },
        {
            value: FILTERS_BY_STATUS.LIVE,
            label: showMeetingOption
                ? t(keys.SESSION_LIVE_SESSIONS)
                : t(keys.GENERAL_SESSION_STATUS_LIVE),
        },
        {
            value: FILTERS_BY_STATUS.UPCOMING,
            label: showMeetingOption
                ? t(keys.SESSION_UPCOMING_SESSIONS)
                : t(keys.SESSION_UPCOMING),
        },
        {
            value: FILTERS_BY_STATUS.COMPLETED,
            label: showMeetingOption
                ? t(keys.SESSION_COMPLETED_SESSIONS)
                : t(keys.SESSION_COMPLETED),
        },
    ];
    if (showMeetingOption) {
        options.push({
            value: FILTERS_BY_STATUS.MEETINGS,
            label: t(keys.SESSION_MEETINGS),
        });
    }

    return options;
};

export const fetchers = {
    fetchPrimaryLabelFromItem: (item) => {
        return moment(item.date).format('DD MMM');
    },
    fetchSecondaryLabelFromItem: (item) => {
        return moment(item.date).format('ddd');
    },
    fetchItemKey: (item) => {
        return item.date.getTime();
    },
};

export const moveCompletedSessionsToLast = (sessions) => {
    if (!sessions) {
        return [];
    }
    return [...sessions].sort((session1, session2) => {
        if (
            session1.status === SESSION_STATUS.FINISHED &&
            session2.status !== SESSION_STATUS.FINISHED
        ) {
            return 1;
        } else if (
            session1.status !== SESSION_STATUS.FINISHED &&
            session2.status === SESSION_STATUS.FINISHED
        ) {
            return -1;
        } else {
            return 0;
        }
    });
};

export const compareArraysByItemProperty = (arr1, arr2, itemPropertyName) => {
    if (!arr1) {
        return !arr2;
    }
    if (!arr2) {
        return false;
    }
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (let i = 0; i < arr1.length; i++) {
        if (
            !arr2.find(
                (itemFromArr2) =>
                    itemFromArr2[itemPropertyName] === arr1[i][itemPropertyName]
            )
        ) {
            return false;
        }
    }
    return true;
};

export const getSessionEndTime = ({ startTime, duration }) => {
    const durationInMs = duration * 60 * 1000;
    const endTime = Date.parse(startTime) + durationInMs;
    return getFormattedTime({ time: endTime });
};

export const FIFTEEN_MINUTES_IN_MILLISECONDS = 15 * 60 * 1000;
export const THIRTY_MINUTES_IN_MILLISECONDS = 30 * 60 * 1000;
export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
export const getNHoursInMs = (hours) => hours * 60 * 60 * 1000;

export const checkEarlyAccessForSession = ({ startTime }) => {
    const startTimeDiff = Date.parse(startTime) - Date.now();
    // Grant early access before 15 minutes of session start time
    return startTimeDiff <= FIFTEEN_MINUTES_IN_MILLISECONDS;
};

export const getStartTimeDiff = ({ startTime }) =>
    Date.parse(startTime) - Date.now();

export const getStartTimeDiffInMMSS = ({ startTime }) => {
    const startTimeDiff = getStartTimeDiff({ startTime });
    if (startTimeDiff <= 0) return { mins: 0, secs: 0 };
    const durationInMillis = moment.duration(startTimeDiff);
    const mins = durationInMillis.minutes();
    const secs = durationInMillis.seconds();
    return {
        mins,
        secs,
    };
};

export const checkSessionWithInTimeDuration = ({
    startTime,
    hoursDuration,
}) => {
    const startTimeDiff = getStartTimeDiff({ startTime });
    return startTimeDiff < 0
        ? true
        : startTimeDiff <= getNHoursInMs(hoursDuration);
};

export const userLabel = {
    [HOST]: 'host',
    [ORGANIZER]: 'organizer',
    [SPEAKER]: 'speaker',
    [EXHIBITOR]: 'exhibitor',
    [ATTENDEE]: 'attendee',
    [COHOST]: 'host',
    [MODERATOR]: 'moderator',
};

export const getUserRoleLabelText = (userRole, t) => {
    const labelText = {
        [HOST]: t(keys.SESSION_YOUR_ROLE_HOST),
        [ORGANIZER]: t(keys.SESSION_YOUR_ROLE_ORGANIZER),
        [SPEAKER]: t(keys.SESSION_YOUR_ROLE_SPEAKER),
        [EXHIBITOR]: t(keys.SESSION_YOUR_ROLE_EXHIBITOR),
        [ATTENDEE]: t(keys.SESSION_YOUR_ROLE_ATTENDEE),
        [COHOST]: t(keys.SESSION_YOUR_ROLE_COHOST),
    };

    return labelText[userRole];
};

export const getUserInfoForSession = (userId, session) => {
    const userRole = UserRoles.getUserSessionRole(userId, session?.sessionid);

    const allowUserRoles =
        session && session.type && session.type === 'STREAMING'
            ? [HOST, COHOST, ORGANIZER]
            : [HOST, SPEAKER, ORGANIZER, COHOST];

    return {
        userRole,
        backstageAccess: allowUserRoles.includes(userRole),
        startBoothSessionAccess: [ORGANIZER, HOST].includes(userRole),
    };
};

export const getSortedUserList = (userList) => {
    const list = [...userList]
        // sort by name
        .sort((user1, user2) => {
            const name1 = user1.label.toUpperCase();
            const name2 = user2.label.toUpperCase();
            return name1 < name2 ? -1 : name1 > name2 ? 1 : 0;
        })
        // sort by presence in event and guest user
        .sort((user1, user2) => {
            if (user1.isGuest) {
                return user2.isGuest ? 0 : 1;
            }
            if (user1.isNotInEvent) {
                return user2.isGuest ? -1 : user2.isNotInEvent ? 0 : 1;
            }

            return user2.isGuest || user2.isNotInEvent ? -1 : 0;
        });
    return list;
};

export function groupSessionsByDate(sessions, pattern) {
    const formatPattern = pattern || 'D MMM ddd';
    let dates = new Set();
    const sessionsGroupedByDate = sessions.reduce((accumulator, session) => {
        const date = moment(session.start_time).format(formatPattern);
        accumulator[date] = [...(accumulator[date] || []), session];
        dates.add(date);
        return accumulator;
    }, {});

    dates = [...dates];
    dates.sort((date1, date2) => {
        return (
            moment(date1, formatPattern).unix() -
            moment(date2, formatPattern).unix()
        );
    });

    const result = {
        dates,
        sessionsGroupedByDate,
    };

    return result;
}

export function sortSessionsByTimeForDates(sessionGroups) {
    sessionGroups.dates.forEach((date) => {
        sessionGroups.sessionsGroupedByDate[date] &&
            sessionGroups.sessionsGroupedByDate[date].sort((s1, s2) => {
                return (
                    moment(s1.start_time).unix() - moment(s2.start_time).unix()
                );
            });
    });
}

export function sortSessionsByTime(sessions) {
    sessions.sort((s1, s2) => {
        return moment(s1.start_time).unix() - moment(s2.start_time).unix();
    });
    return sessions;
}

export function fetchInitialSelectedDate(dates, datePattern) {
    if (!dates || !dates.length) {
        return '';
    }
    const today = moment().format(datePattern);

    //Get Nearest Session to current date
    for (let i = 0; i < dates.length; i++) {
        if (dates[i] === today) return today;
        else if (
            moment(dates[i], datePattern).unix() >
            moment(today, datePattern).unix()
        )
            return dates[i];
    }

    return dates[dates.length - 1] || null;
}

export function trimDate(date) {
    return date.split(' ').join('');
}

export const SHOW_SESSION_OVERLAY = 'SHOW_SESSION_OVERLAY';
export const CLOSE_SESSION_OVERLAY = 'CLOSE_SESSION_OVERLAY';

export const LAST_SESSION_VISITED_KEY = 'last_session_visited';
export const RECORDING_LABEL_CONTAINER = 'recording-label-container';

export const OPEN_BREAKOUT_DURATION_OVERLAY = 'OPEN_BREAKOUT_DURATION_OVERLAY';
export const CLOSE_BREAKOUT_DURATION_OVERLAY =
    'CLOSE_BREAKOUT_DURATION_OVERLAY';
export const SESSION_VISIT_ID_KEY = 'sessionVisitId';

export const SESSION_REACH_TO_END_TIME = 'SESSION_REACH_TO_END_TIME';
export const OPEN_SPEAKERS_OVERLAY = 'OPEN_SPEAKERS_OVERLAY';
export const CLOSE_SPEAKERS_OVERLAY = 'CLOSE_SPEAKERS_OVERLAY';
export const AV_SETTINGS_OPEN = 'AV_SETTINGS_OPEN';
export const AV_SETTINGS_CLOSE = 'AV_SETTINGS_CLOSE';
export const MUTE_STAGE_USER_AV = 'MUTE_STAGE_USER_AV';
export const REVERT_STAGE_USER_AV = 'REVERT_STAGE_USER_AV';
export const REMOVE_RECORDED_VIDEO = 'REMOVE_RECORDED_VIDEO';
export const FULLSCREEN_MEDIA_CONTENT = 'FULLSCREEN_MEDIA_CONTENT';
export const WINDOWED_SCREEN_MEDIA_CONTENT = 'WINDOWED_SCREEN_MEDIA_CONTENT';

export const SESSION_GRADIENTS = [
    { type: 'STREAMING', color: '255, 168, 0, 0.5' },
    { type: 'HOSTING', color: '255, 168, 0, 0.5' },
    { type: 'BREAK', color: '0, 255, 255, 0.5' },
    { type: 'SPEED_NETWORKING', color: '0, 255, 128, 0.5' },
    { type: 'BOOTH', color: '255, 255, 0, 0.5' },
    { type: 'FLUID_LOUNGE', color: '255, 168, 168, 0.5' },
    { type: 'PRE_RECORDED', color: '255, 168, 0, 0.5' },
    { type: 'GROUP_MEETING', color: '128, 0, 255, 0.5' },
    { type: 'SCHEDULE_MEETING', color: '34, 55, 103, 1' },
];
/**
 * Sorting order
 * sessions with prioritySessionId,
 * sessions with CREATED status
 * all other sessions in reverse start_time
 */
export const sortSessionForNudge = (sessions, prioritySessionId) => {
    return sessions.sort((sessionOne, sessionTwo) => {
        if (sessionOne.sessionid === prioritySessionId) return -1;
        if (sessionTwo.sessionid === prioritySessionId) return 1;
        if (sessionOne.status === SESSION_STATUS.CREATED) {
            return sessionTwo.status === SESSION_STATUS.CREATED ? 0 : -1;
        }
        if (sessionTwo.status === SESSION_STATUS.CREATED) {
            return sessionOne.status === SESSION_STATUS.CREATED ? 0 : 1;
        }

        const sessionOneStartTime = Date.parse(sessionOne.start_time);
        const sessionTwoStartTime = Date.parse(sessionTwo.start_time);

        return sessionTwoStartTime - sessionOneStartTime;
    });
};

export const DEFAULT_PREMIUM_ICON_ROUND =
    '/images/paid-feature-diamond-round.svg';

export const ACTIVITIES = [
    {
        type: SESSION_TYPES.HOSTING,
        title: 'Session',
        content: 'Can be Talks, Workshops, Discussions, Celebrations, etc.',
        id: 'session',
        icon: 'presenter',
        iconType: 'icon',
    },
    {
        type: SESSION_TYPES.SPEED_NETWORKING,
        title: '1-1 Speed networking',
        content: 'Attendees get matched one-on-one for quick conversations',
        id: 'networking',
        icon: 'people',
        iconType: 'icon',
    },
    {
        type: SESSION_TYPES.BREAK,
        title: 'Break',
        content:
            'Tea, Coffee and Lunch breaks for attendees to take some time off.',
        id: 'break',
        icon: 'icon-break',
        iconType: 'svgsprite',
    },
    {
        type: SESSION_TYPES.FLUID_LOUNGE,
        title: 'Fluid Space',
        content:
            'Participants can move around to start conversations with others in their proximity.',
        id: 'fluid-space',
        icon: 'fluid-space-icon',
        iconType: 'svgsprite',
    },
    {
        type: SESSION_TYPES.STREAMING,
        title: 'Session',
        content: `Broadcast from a 3rd party platform into an ${getCurrentTenantData(
            'name'
        )} session using RTMP`,
        id: 'rtmp-streaming',
        icon: 'stream',
        iconType: 'svgsprite',
        premiumIcon: DEFAULT_PREMIUM_ICON_ROUND,
    },
    {
        type: SESSION_TYPES.BOOTH,
        title: 'Explore Booth ',
        content:
            'Drive more attendees to booths and increase value for exhibitors.',
        id: 'booth',
        icon: 'icon-subtract',
        iconType: 'svgsprite',
    },
    {
        type: SESSION_TYPES.GROUP_MEETING,
        title: 'Session as a Video Call',
        content:
            'Unmoderated roundtable conversations, large group discussions',
        id: 'group-meeting',
        icon: 'icon-group-meeting',
        iconType: 'icon',
    },
    {
        type: SESSION_TYPES.PRE_RECORDED,
        title: 'Session',
        content: 'Create quick automated pre-recorded session.',
        id: 'pre-recorded-session',
        icon: 'video-clip-play',
        iconType: 'icon',
        premiumIcon: DEFAULT_PREMIUM_ICON_ROUND,
    },
];

export const SESSION_ACTIVITIES = [
    {
        id: 'sessionTypes',
        title: 'Session Types',
        subTitle:
            'Use these activities to host different type of sessions for your attendees',
        activities: [
            {
                type: SESSION_TYPES.HOSTING,
                title: SESSION_TYPE_NAMES[SESSION_TYPES.HOSTING],
                content:
                    'Can be Talks, Workshops, Discussions, Celebrations, etc.',
                hybridContent:
                    'Host talks, workshops etc with speakers and host attending from different locations',
                id: 'session',
                icon: SESSION_TYPE_ICONS[SESSION_TYPES.HOSTING],
            },
            {
                type: SESSION_TYPES.GROUP_MEETING,
                title: SESSION_TYPE_NAMES[SESSION_TYPES.GROUP_MEETING],
                content:
                    'Unmoderated roundtable conversations, large group discussions',
                id: 'group-meeting',
                icon: SESSION_TYPE_ICONS[SESSION_TYPES.GROUP_MEETING],
            },
            {
                type: SESSION_TYPES.PRE_RECORDED,
                title: SESSION_TYPE_NAMES[SESSION_TYPES.PRE_RECORDED],
                content: 'Create quick automated pre-recorded session.',
                id: 'pre-recorded-session',
                icon: SESSION_TYPE_ICONS[SESSION_TYPES.PRE_RECORDED],
                premiumIcon: DEFAULT_PREMIUM_ICON_ROUND,
            },
        ],
    },
    {
        id: 'broadcastingActivities',
        title: 'Broadcasting activities',
        subTitle: `Stream sessions from other platforms into ${getCurrentTenantData(
            'name'
        )} using a third party streaming software. These sessions will only have one stream and other speakers cannot be invited to stage`,
        activities: [
            {
                type: SESSION_TYPES.STREAMING,
                title: SESSION_TYPE_NAMES[SESSION_TYPES.STREAMING],
                content: `Broadcast from a 3rd party platform into an ${getCurrentTenantData(
                    'name'
                )} session using RTMP`,
                id: 'rtmp-streaming',
                icon: SESSION_TYPE_ICONS[SESSION_TYPES.STREAMING],
                premiumIcon: DEFAULT_PREMIUM_ICON_ROUND,
            },
        ],
    },
    {
        id: 'networkingActivities',
        title: 'Networking activities',
        subTitle:
            'Use these activities to enable networking amongst both online and in-person attendees',
        activities: [
            {
                type: SESSION_TYPES.SPEED_NETWORKING,
                title: SESSION_TYPE_NAMES[SESSION_TYPES.SPEED_NETWORKING],
                content:
                    'Attendees get matched one-on-one for quick conversations',
                id: 'networking',
                icon: SESSION_TYPE_ICONS[SESSION_TYPES.SPEED_NETWORKING],
            },

            {
                type: SESSION_TYPES.FLUID_LOUNGE,
                title: SESSION_TYPE_NAMES[SESSION_TYPES.FLUID_LOUNGE],
                content:
                    'Participants can move around to start conversations with others in their proximity.',
                id: 'fluid-space',
                icon: SESSION_TYPE_ICONS[SESSION_TYPES.FLUID_LOUNGE],
            },
        ],
    },
    {
        id: 'otherActivities',
        title: 'Other activities',
        subTitle: '',
        activities: [
            {
                type: SESSION_TYPES.BREAK,
                title: SESSION_TYPE_NAMES[SESSION_TYPES.BREAK],
                content:
                    'Tea, Coffee and Lunch breaks for attendees to take some time off.',
                id: 'break',
                icon: SESSION_TYPE_ICONS[SESSION_TYPES.BREAK],
            },
            {
                type: SESSION_TYPES.BOOTH,
                title: SESSION_TYPE_NAMES[SESSION_TYPES.BOOTH],
                content:
                    'Drive more attendees to booths and increase value for exhibitors.',
                id: 'booth',
                icon: SESSION_TYPE_ICONS[SESSION_TYPES.BOOTH],
            },
        ],
    },
];

const IN_PERSON_EVENT_SESSION_ACTIVITY_TYPES = [
    SESSION_TYPES.HOSTING,
    SESSION_TYPES.BREAK,
];

export const IN_PERSON_EVENT_SESSION_ACTIVITIES = [
    {
        id: 'sessionTypes',
        title: 'Activity Type',
        subTitle:
            'Use these type of activities to host multiple sessions in your event for your attendees.',
        activities: [],
    },
];
SESSION_ACTIVITIES.forEach((act) =>
    (act.activities || []).forEach((activity) => {
        if (IN_PERSON_EVENT_SESSION_ACTIVITY_TYPES.includes(activity?.type)) {
            IN_PERSON_EVENT_SESSION_ACTIVITIES[0].activities.push(activity);
        }
    })
);

export const SPEED_NETWORKING_TAG = 'Speed Networking';

export const sessionUpdateConfig = {
    delay: {
        default: 10,
        liteMode: LITE_MODE_DELAY, // in ms
    },
    finishCountdown: {
        [SESSION_TYPES.FLUID_LOUNGE]: 20,
    },
};

export const RTMP_STREAMING_PROVIDERS = {
    mux: 'mux',
    ivs: 'ivs',
    airmeet: 'airmeet',
};

export const STREAMING_RESOLUTIONS = {
    '1080': '1080p',
    '720': '720p',
};

export const SESSION_REPLAY_CONFIG = {
    QNA_FLAG: 'isQnaAllowedForReplay',
    SESSION_FEED_FLAG: 'isSessionFeedAllowedForReplay',
    SESSION_POLLS_FLAG: 'isPollsAllowedForReplay',
};

export const PERSISTENT_STAGE_LOCATIONS = {
    LIVESTAGE: 'livestage',
    BACKSTAGE: 'backstage',
};

export const MWEB_UNSUPPORTED_SESSIONS_TYPES = [SESSION_TYPES.FLUID_LOUNGE];
export const getSessionInfo = (session) => {
    const {
        status,
        type,
        start_time,
        session_venue,
        session_meta,
        duration,
        name,
        summary,
        speakerList,
        sessionid,
    } = session;
    const date = moment(start_time).format('Do MMM YYYY');
    const isCreated = status === SESSION_STATUS.CREATED;
    const currentTime = Date.now();
    const sessionStatusFlag = {
        isLive: status === SESSION_STATUS.ONGOING,
        isPaused: status === SESSION_STATUS.PAUSED,
        isComplete: status === SESSION_STATUS.FINISHED,
        isCreated: status === SESSION_STATUS.CREATED,
    };
    if (type === SESSION_TYPES.BREAK && !sessionStatusFlag.isComplete) {
        const startTime = new Date(Date.parse(start_time)).getTime();
        const endTime = new Date(
            Date.parse(start_time) + duration * 60 * 1000
        ).getTime();
        sessionStatusFlag.isPaused = false;
        sessionStatusFlag.isCreated = startTime < currentTime;
        sessionStatusFlag.isLive =
            startTime <= currentTime && currentTime < endTime;
        sessionStatusFlag.isComplete = currentTime >= endTime;
    }

    return {
        ...sessionStatusFlag,
        isNetworkingSession: type === SESSION_TYPES.SPEED_NETWORKING,
        isBoothSession: type === SESSION_TYPES.BOOTH,
        isBreakSession: type === SESSION_TYPES.BREAK,
        isHostingSession: type === SESSION_TYPES.HOSTING,
        isStreamingSession: type === SESSION_TYPES.STREAMING,
        isPreRecordedSession: type === SESSION_TYPES.PRE_RECORDED,
        isFluidSpace: type === SESSION_TYPES.FLUID_LOUNGE,
        isGroupMeetingSession: type === SESSION_TYPES.GROUP_MEETING,
        isSessionWithBackstageStage: SESSION_TYPES_WITH_BACKSTAGE.includes(
            type
        ),
        startTime: getFormattedTime({ time: start_time }),
        endTime: getSessionEndTime({ startTime: start_time, duration }),
        date,
        sessionDate: date,
        title: name,
        desc: summary,
        sessionType: type,
        speakers: speakerList,
        sessionId: sessionid,
        sessionVenue: session_venue,
        sessionMeta: session_meta,
    };
};

export const getSessionTags = (sessionTags, sessionId) =>
    sessionTags
        .filter((tag) => tag.session_ids?.includes(sessionId))
        .map((tag) => tag.name);

export const getSessionTypeName = (sessionType) => {
    switch (sessionType) {
        case SESSION_TYPES.BOOTH:
            return 'Booth';
        case SESSION_TYPES.SCHEDULE_MEETING:
            return 'Schedule meeting';
        default:
            return (
                ACTIVITIES.find((activity) => activity.type === sessionType)
                    ?.title || ''
            );
    }
};
