import { EVENT_TYPES } from './constants/airmeet';
import { ADMIN_USER_ROLES, TABLE_ROLE, USER_ROLE } from './constants/users';
import UserRoles from './userRoles';
import { includesRole } from './users';

const { HOST, COHOST, ORGANIZER, SPEAKER, EXHIBITOR, ATTENDEE } = USER_ROLE;
const { MODERATOR } = TABLE_ROLE;

export const RAISE_HAND = {
    REQUEST: 'REQUEST_RAISE_HAND',
    REQUEST_ON_PERSISTENT_BACKSTAGE: 'REQUEST_RAISE_HAND_PERSISTENT_BACKSTAGE',
    MANAGE: 'MANAGE_RAISE_HAND',
    VIEW: 'VIEW_RAISE_HAND',
    NEW_REQUEST_NOTIFICATION: 'RAISE_HAND_NEW_REQUEST_NOTIFICATION',
};

export const POLLS = {
    MANAGE: 'MANAGE_POLLS',
    VIEW: 'VIEW_POLLS',
};

export const ADVANCE_POLLS = {
    MANAGE: 'MANAGE_ADVANCE_POLLS',
};

export const STAGE_STREAM = {
    PUBLISH: 'PUBLISH_STAGE_STREAM',
};

export const COHOST_FEATURE = {
    MANAGE: 'MANAGE_COHOST_FEATURE',
};
export const STAGE_BACKDROP = {
    MANAGE: 'MANAGE_STAGE_BACKDROP',
};
export const STREAM_ACTION = {
    MANAGE: 'MANAGE_STREAM_ACTION',
};
export const EDIT_VIDEO_HEADLINE = {
    MANAGE: 'MANAGE_EDIT_VIDEO_HEADLINE',
    UPDATE: 'UPDATE_VIDEO_HEADLINE',
};
export const INVITE_TO_STAGE = {
    MANAGE: 'MANAGE_INVITE_TO_STAGE',
};
export const REMOVE_MESSAGE = {
    MANAGE: 'MANAGE_REMOVE_MESSAGE',
};
export const BLOCK_USER = {
    MANAGE: 'MANAGE_BLOCK_USER',
};
export const PIN_COMMENT = {
    MANAGE: 'MANAGE_PIN_COMMENT',
};
export const QUESTION = {
    MANAGE: 'MANAGE_QUESTION',
    BYPASS_MODERATION: 'BYPASS_QUESTION_MODERATION',
    MODERATE: 'MODERATE_QUESTION',
};
export const LOUNGE = {
    MANAGE: 'MANAGE_LOUNGE',
    VIEW_DURING_LIVE_MEETUP_SESSION: 'VIEW_DURING_LIVE_MEETUP_SESSION_LOUNGE',
};

export const ADDITIONAL_CONTROLS = {
    VIEW: 'VIEW_ADDITIONAL_CONTROLS',
};
export const DEVICE_CHOOSER = {
    VIEW: 'VIEW_DEVICE_CHOOSER',
};
export const REMOTE_VIDEO_PLAYER = {
    VIEW: 'VIEW_REMOTE_VIDEO_PLAYER',
};
export const STAGE_BACK_BUTTON = {
    VIEW: 'VIEW_STAGE_BACK_BUTTON',
};
export const STAGE_LAYOUT = {
    MANAGE: 'MANAGE_STAGE_LAYOUT',
};
export const LIVE_STAGE = {
    VIEW: 'VIEW_LIVE_STAGE',
};
export const MANAGE_ABUSE = {
    VIEW: 'VIEW_MANAGE_ABUSE',
};
export const SESSION_VIDEOS = {
    VIEW: 'VIEW_SESSION_VIDEOS',
};
export const USER_BLOCKABLE = {
    VIEW: 'VIEW_USER_BLOCKABLE',
};
export const USER_VIDEO_HEADLINE_ELIGIBLE = {
    VIEW: 'VIEW_USER_VIDEO_HEADLINE_ELIGIBLE',
};
export const GENERAL_ACTIONS_ON_EM = {
    VIEW: 'VIEW_GENERAL_ACTIONS_ON_EM',
};

export const HIDE_UNHIDE = {
    VIEW: 'VIEW_HIDE_UNHIDE',
};

export const USER_CARD_BADGE = {
    SHOW: 'SHOW_USER_CARD_BADGE',
};

export const USER_SYSTEM_DETAILS = {
    SHOW: 'SHOW_USER_SYSTEM_DETAILS',
};

export const STREAMING_SESSION = {
    MANAGE: 'STREAMING_SESSION_MANAGE',
};

export const HIDE_SHOW_USER = {
    VIEW: 'VIEW_HIDDEN_USER',
    MANAGE: 'MANAGE_HIDE_SHOW_USER',
};

export const LIVE_ANNOUNCEMENT = {
    PUBLISH: 'PUBLISH_ANNOUNCEMENT',
};

export const GROUP_MEETING = {
    RECORDING: 'GROUP_MEETING_RECORDING',
    MANAGE: 'GROUP_MEETING_MANAGE',
};

export const CTA_BANNER = {
    MANAGE: 'MANAGE_CTA_BANNER',
};
export const LOWER_THIRDS_BANNER = {
    MANAGE: 'MANAGE_LOWER_THIRDS_BANNER',
};

export const SDK_INTERACTION = {
    ALLOWED_SWITCH_CHANNEL: 'ALLOWED_SWITCH_CHANNEL',
};
export const CLEAR_ALERTS = {
    MANAGE: 'MANAGE_CLEAR_ALERTS',
};

export const PERSISTENT_BACKSTAGE_BAR = {
    VIEW: 'VIEW',
    MANAGE: 'MANAGE',
    JOIN_BACKSTAGE: 'JOIN_BACKSTAGE',
};

const hostCohostFeatures = [
    COHOST_FEATURE.MANAGE,
    STAGE_BACKDROP.MANAGE,
    RAISE_HAND.MANAGE,
    INVITE_TO_STAGE.MANAGE,
    SESSION_VIDEOS.VIEW,
    MANAGE_ABUSE.VIEW,
    REMOTE_VIDEO_PLAYER.VIEW,
    HIDE_UNHIDE.VIEW,
    STREAM_ACTION.MANAGE,
    EDIT_VIDEO_HEADLINE.MANAGE,
];

const hostCohostSpeakerFeatures = [
    STAGE_STREAM.PUBLISH,
    POLLS.MANAGE,
    DEVICE_CHOOSER.VIEW,
    LIVE_STAGE.VIEW,
];

const hostCohostOrganizerFeatures = [
    REMOVE_MESSAGE.MANAGE,
    BLOCK_USER.MANAGE,
    PIN_COMMENT.MANAGE,
    QUESTION.MANAGE,
    USER_SYSTEM_DETAILS.SHOW,
    STREAMING_SESSION.MANAGE,
];

// Feature to Roles access map
const SESSION_FEATURE_ACCESS_ROLES = {
    [POLLS.VIEW]: [ORGANIZER, ATTENDEE],
    [ADVANCE_POLLS.MANAGE]: [HOST, ORGANIZER, COHOST],
    [RAISE_HAND.VIEW]: [HOST, COHOST, ORGANIZER, SPEAKER],
    [RAISE_HAND.NEW_REQUEST_NOTIFICATION]: [HOST, COHOST],
    [USER_BLOCKABLE.VIEW]: [ATTENDEE, EXHIBITOR],
    [USER_VIDEO_HEADLINE_ELIGIBLE.VIEW]: [HOST, COHOST, ORGANIZER, SPEAKER],
    [GENERAL_ACTIONS_ON_EM.VIEW]: [HOST, COHOST, ORGANIZER, SPEAKER],
    [USER_CARD_BADGE.SHOW]: [HOST, COHOST, ORGANIZER, SPEAKER, MODERATOR],
    [STAGE_BACK_BUTTON.VIEW]: [ORGANIZER, ATTENDEE],
    [RAISE_HAND.REQUEST]: [ORGANIZER, ATTENDEE],
    [RAISE_HAND.REQUEST_ON_PERSISTENT_BACKSTAGE]: [ATTENDEE],
    [ADDITIONAL_CONTROLS.VIEW]: [HOST, COHOST],
    [HIDE_SHOW_USER.MANAGE]: [HOST, COHOST],
    [STAGE_LAYOUT.MANAGE]: [HOST, COHOST],
    [LIVE_ANNOUNCEMENT.PUBLISH]: [ORGANIZER],
    // group meeting recording
    [GROUP_MEETING.RECORDING]: [HOST, COHOST],
    [GROUP_MEETING.MANAGE]: [HOST, COHOST],
    [CTA_BANNER.MANAGE]: [HOST, COHOST],
    [LOWER_THIRDS_BANNER.MANAGE]: [HOST, COHOST],
    [SDK_INTERACTION.ALLOWED_SWITCH_CHANNEL]: [HOST, COHOST, SPEAKER],
    [PERSISTENT_BACKSTAGE_BAR.VIEW]: [HOST, COHOST, SPEAKER, ORGANIZER],
    [PERSISTENT_BACKSTAGE_BAR.JOIN_BACKSTAGE]: [
        HOST,
        COHOST,
        SPEAKER,
        ORGANIZER,
    ],
    [PERSISTENT_BACKSTAGE_BAR.MANAGE]: [HOST, COHOST],
    [QUESTION.BYPASS_MODERATION]: [ORGANIZER, HOST, COHOST, SPEAKER],
    [QUESTION.MODERATE]: [ORGANIZER, HOST, COHOST],
    [EDIT_VIDEO_HEADLINE.UPDATE]: [HOST, COHOST, ORGANIZER, SPEAKER],
    // Feature access differ based on event type
};

(function populateSessionFeatureData() {
    // Features accessible to host and cohost
    hostCohostFeatures.forEach((feature) => {
        SESSION_FEATURE_ACCESS_ROLES[feature] = [HOST, COHOST];
    });

    // Features accessible to host, cohost and speaker
    hostCohostSpeakerFeatures.forEach((feature) => {
        SESSION_FEATURE_ACCESS_ROLES[feature] = [HOST, COHOST, SPEAKER];
    });

    // Features accessible to host, cohost and organizer
    hostCohostOrganizerFeatures.forEach((feature) => {
        SESSION_FEATURE_ACCESS_ROLES[feature] = [HOST, COHOST, ORGANIZER];
    });
})();

const EVENT_FEATURE_ACCESS_ROLES = {
    [LOUNGE.MANAGE]: [HOST, ORGANIZER],
    [LOUNGE.VIEW_DURING_LIVE_MEETUP_SESSION]: [HOST, ORGANIZER],
    [ADVANCE_POLLS.MANAGE]: [HOST, ORGANIZER],
    [LIVE_ANNOUNCEMENT.PUBLISH]: [ORGANIZER],
    [CLEAR_ALERTS.MANAGE]: [ORGANIZER],
};

const getRoles = (
    feature,
    isSessionSpecific,
    accessVaryForEventType,
    isConferenceEvent = true
) => {
    const eventType = isConferenceEvent
        ? EVENT_TYPES.CONFERENCE
        : EVENT_TYPES.MEETUP;

    if (isSessionSpecific) {
        if (accessVaryForEventType) {
            return (
                (SESSION_FEATURE_ACCESS_ROLES[feature] &&
                    SESSION_FEATURE_ACCESS_ROLES[feature][eventType]) ||
                []
            );
        } else {
            return SESSION_FEATURE_ACCESS_ROLES[feature] || [];
        }
    } else {
        if (accessVaryForEventType) {
            return (
                (EVENT_FEATURE_ACCESS_ROLES[feature] &&
                    EVENT_FEATURE_ACCESS_ROLES[feature][eventType]) ||
                []
            );
        } else {
            return EVENT_FEATURE_ACCESS_ROLES[feature] || [];
        }
    }
};

/**
 * Util function to check the access of user for particular feature
 * @param {String} feature - Feature to check access for
 * @param {Object} data - Additional data (optional)
 */
export const hasAccess = (feature, data = {}) => {
    const {
        userId = null,
        role = null,
        accessVaryForEventType,
        sessionSpecific = true,
        bypassBasedOnRoles = null,
    } = data || {};

    const isSessionSpecific =
        !!SESSION_FEATURE_ACCESS_ROLES[feature] && sessionSpecific;

    let roles = getRoles(feature, isSessionSpecific, accessVaryForEventType);
    if (bypassBasedOnRoles && Array.isArray(bypassBasedOnRoles)) {
        roles = roles.concat(bypassBasedOnRoles);
    }
    const userRole = role
        ? role
        : isSessionSpecific
        ? UserRoles.getUserSessionRole(userId)
        : UserRoles.getUserEventRole(userId);

    const accessRoles = Array.isArray(roles) ? roles : [];
    return includesRole(accessRoles || [], userRole);
};

export const isSessionAdmin = (userId = null, sessionId = null) => {
    const role = UserRoles.getUserSessionRole(userId, sessionId);
    return includesRole(ADMIN_USER_ROLES, role);
};

export const isSessionHostOrCohost = (userId = null, sessionId = null) => {
    const role = UserRoles.getUserSessionRole(userId, sessionId);
    return includesRole([HOST, COHOST], role);
};

export const isHost = (userId = null, sessionId = null) => {
    const role = UserRoles.getUserSessionRole(userId, sessionId);
    return includesRole([HOST], role);
};

export const isCoHost = (userId = null, sessionId = null) => {
    const role = UserRoles.getUserSessionRole(userId, sessionId);
    return includesRole([COHOST], role);
};

export const isOrganizer = (userId = null) => {
    const role = UserRoles.getUserSessionRole(userId);
    return includesRole([ORGANIZER], role);
};

export const isSpeaker = (userId = null) => {
    const role = UserRoles.getUserSessionRole(userId);
    return includesRole([SPEAKER], role);
};

export const isAttendee = (userId = null, sessionId = null) => {
    const role = UserRoles.getUserSessionRole(userId, sessionId);
    return includesRole([ATTENDEE], role);
};

export const isExhibitor = (userId = null) => {
    const role = UserRoles.getUserSessionRole(userId);
    return includesRole([EXHIBITOR], role);
};

export const isEventAttendee = (userId = null) => {
    const role = UserRoles.getUserEventRole(userId);
    return includesRole([ATTENDEE], role);
};

export const isEventOrganizer = (userId = null) => {
    const role = UserRoles.getUserEventRole(userId);
    return includesRole([ORGANIZER], role);
};
