import _get from 'lodash/get';

const emptyObj = {};
const emptyArray = [];
export const getIsLiteModeActive = (state) => state.liveAirmeet.liteModeActive;
export const getStageId = (state) => state.liveAirmeet.stageId;
export const getModalLayout = (state) => state.layout.modal;
export const getAirmeet = (store) => store.lounge.airmeet;
export const getIsBackStage = (store) => store.lounge.isBackStage;
export const selectUserId = (store) => store.user.id;
export const getParallelTrackEnabled = (store) =>
    store.lounge.airmeet.parallelTrackData.isParallelTrackEvent;
export const getParallelTrackCurrentSessionId = (store) =>
    store.lounge.airmeet.parallelTrackData.currentSessionId;

export const getLiveConfig = (store) => store.lounge.airmeet?.live_config || {};

export const getIsSessionGenralDataLoaded = (state) =>
    state.liveAirmeet.genral.isLoaded;
export const getStageBroadcastChannels = (state) =>
    state.liveAirmeet.genral.broadcastChannels || emptyObj;
export const getStageUsersVisibility = (store) =>
    store.liveAirmeet.genral.userVisibility || emptyObj;
export const getStageVisibleUserCount = (store) => {
    return Object.values(store.liveAirmeet.genral.userVisibility || []).filter(
        (state) => state.visibility === true
    ).length;
};
export const getStageUsersLocation = (store) =>
    store.liveAirmeet.genral.liveStage || {};
export const getStageHiddenUserCount = (store) => {
    return Object.values(store.liveAirmeet.genral.userVisibility || []).filter(
        (state) => state.visibility === false
    ).length;
};
export const getIsWatchReplayClicked = (store) =>
    store.lounge.isWatchReplayClicked;
export const getOnlineUsers = (store) => store.onlineUsers?.users; // check if check required for undefined
export const getOnlineUsersIds = (store) => store.onlineUsers?.ids;
export const getCloudUsersCount = (store) => store.onlineUsers?.cloudUsersCount;
export const getTotalUsersCount = (state) => {
    const attendees = getOnlineUsers(state);
    const cloudUsersCount = getCloudUsersCount(state);
    return Math.max(attendees.length - cloudUsersCount || 0, 1);
};
export const getIsFullProfileVisible = (store) =>
    store.profile.isFullProfileVisible;
export const getIsProfileNudgeVisible = (store) =>
    store.profile.isProfileNudgeVisible;
export const getInterestList = (store) => store.profile.richProfileInterests;
export const getInterestById = (pid, id) => (store) =>
    _get(store, [
        'profile',
        'richProfileInterests',
        pid,
        'level2_interests',
        id,
        'text',
    ]);
export const getSubscriptionData = (state) => state.Community.subscriptionData;
export const getAttendeeAddonData = (state) => state.addons.attendeeAddons;
export const getCurrentCommunity = (state) => state.Community.current;
export const getCurrentCommunityCouponType = (state) =>
    state?.Community?.current?.coupon_type;
export const getMeteredPricing = (state) => state?.meteredPricing;
export const getAuthUserAccessCode = (state) => state.auth.accessCode;
export const getFilterInfo = (state) => state.liveAirmeet.filterInfo;
export const getPermissionWorkflowVersion = (state) =>
    state.liveAirmeet.permissionWorkflowVersion;
export const getMyCommunity = (state) => state.Community.myCommunity;
export const getIsRHSCollapsed = (state) => state.RHS.isRHSCollapsed;
export const getIsStageMemberLimitReached = (state) =>
    state.liveAirmeet.hasStageMemberLimitReached;
export const getBackdrop = (store) => store.liveAirmeet.genral.backdrop;
export const getIsCustomRegistrationAllowed = (store) =>
    store?.lounge?.airmeet?.is_custom_registration_allowed;
export const getActiveBreakoutId = (store) =>
    store.liveAirmeet.genral.activeBreakoutId;
export const getBreakoutRoomJoinStatus = (store) =>
    store.liveAirmeet.isJoinedBreakoutRoom;
export const getSessionRaiseHandRequestListSelector = (sessionId) => {
    return (store) => store.HandRaised.requestedList[sessionId] || emptyArray;
};
export const getHandRaisePendingRequestCountSelector = (sessionId) => {
    return (store) =>
        store.HandRaised.requestStatusCount[sessionId]?.pendingRequest || 0;
};
export const getConnectViaFirebasePrimaryShardsSelector = (store) =>
    store.HandRaised.connectViaFirebasePrimaryShards;
export const getIsCustomInterestsEnabled = (store) =>
    store?.lounge?.airmeet?.is_custom_interests_enabled;
export const getTablesList = (store) => store?.lounge?.tablesList;
export const getAllTables = (store) => store?.lounge?.allTable;
export const getAuthUser = (state) => state.auth.user;
export const getAuthUserEmail = (state) => state.auth.user?.email;
export const getTableCode = (state) => state.liveAirmeet.tableCode;
export const getShowLeaveTableModal = (state) => state.leaveTable.showModal;
export const getLeaveTableModalResponse = (state) =>
    state.leaveTable.modalResponse;
export const getCanAttendeeCreateTable = (state) =>
    state?.lounge?.canAttendeeCreateTable || false;
export const getPipVisitId = (state) => state.pipMode.pipVisitId;
export const getPipSessionId = (state) => state.pipMode.pipSessionId;
export const getSystemCheckModalData = (store) => store.systemCheck;
export const getCmDashboard = (state) => state.cmDashboard;
export const getMusicModeEnabled = (state) =>
    state.liveAirmeet.isMusicModeEnabled;
export const getVolumeControlEnabled = (state) =>
    state.liveAirmeet.isVolumeControlEnabled;
export const getHideAdvancedPeopleSearch = (state) =>
    state.lounge.airmeet?.live_config?.hideAdvancedPeopleSearch;

export const getUserLevelSessionState = (contextType, context = null) => (
    store
) => {
    const updates = store.userLevelSessionUpdates[contextType];
    return context === null ? updates : updates[context];
};

export const getBannerFormData = (store) => store.liveAirmeet.bannerFormData;

export const getBannerData = (store) => store.liveAirmeet.bannerData;
export const getCustomFPSConfig = (state) => state.liveAirmeet.customFPSConfig;
export const getClosedCaptionState = (state) => state.closedCaptions;
export const getClosedCaptionsConfig = (state) => {
    const { config = {} } = state.closedCaptions;
    return config;
};
