import useLiveAirmeetContext from 'hooks/useLiveAirmeetContext';
import useTimeout from 'hooks/useTimeout';
import { useMemo } from 'react';
import {
    SESSION_STATUS_FINISHED,
    SESSION_STATUS_ONGOING,
    SESSION_STATUS_PAUSED,
} from 'store/constants';
import {
    DAY_IN_MILLISECONDS,
    FIFTEEN_MINUTES_IN_MILLISECONDS,
    SESSION_TYPES,
    THIRTY_MINUTES_IN_MILLISECONDS,
} from 'utils/constants/sessions';
import { isAttendee } from 'utils/userAccessControl';

const nonSession = {};
const useEarlyAccessForSession = ({ session }) => {
    const {
        airmeet: { featureFlags: { isConferenceEvent } = {} },
    } = useLiveAirmeetContext();

    const { start_time, status, type } = session ? session : nonSession;
    const timeoutInMilliseconds = useMemo(() => {
        if (!type) {
            return null;
        }
        if (
            [
                SESSION_STATUS_ONGOING,
                SESSION_STATUS_PAUSED,
                SESSION_STATUS_FINISHED,
            ].includes(status)
        ) {
            return 0;
        } else {
            const useTimeInMillisecond = [
                SESSION_TYPES.SPEED_NETWORKING,
                SESSION_TYPES.FLUID_LOUNGE,
            ].includes(type)
                ? FIFTEEN_MINUTES_IN_MILLISECONDS
                : !isConferenceEvent && isAttendee()
                ? THIRTY_MINUTES_IN_MILLISECONDS
                : FIFTEEN_MINUTES_IN_MILLISECONDS;
            const timeLeft =
                Date.parse(start_time) - useTimeInMillisecond - Date.now();

            if (timeLeft < DAY_IN_MILLISECONDS) return timeLeft;
            else return null;
        }
    }, [status, start_time, type, isConferenceEvent]);

    const { isTimerReached } = useTimeout(timeoutInMilliseconds);
    return { timeoutInMilliseconds, earlyAccessForSession: isTimerReached };
};

export default useEarlyAccessForSession;
